import "./NavigationBar.scss";
import LazyLoad from "react-lazyload";
import { Nav, Navbar, Container, NavDropdown, Form } from "react-bootstrap";
import devpartnersLogo from "../../assets/images/pooling_assets/dp_header.png";
import NavigationBarMenu from "../../hoc/NavigationBarMenu/NavigationBarMenu";
import LoggedInMenu from "./LoggedInMenu/LoggedInMenu";
import LoggedOutMenu from "./LoggedOutMenu/LoggedOutMenu";
import { useHistory } from "react-router-dom";
import { NavbarMenuProps } from "../../interface/navbar.interface";
import { useEffect, useState, Fragment } from "react";
import { logoutUser, capitalizeFirstLetter } from "../../services/nav-service";
import ApplicantsService from "../../services/http/applicants.service";
import { AxiosResponse } from "axios";
import { AsyncTypeahead } from "react-bootstrap-typeahead"; // ES2015
import { USER_TYPE } from "../../constants/nav-constants";

const NavbarMenuOptions = NavigationBarMenu(LoggedInMenu, LoggedOutMenu);

const NavigationBar = ({
  isLoggedIn,
  isRegister,
  setIsLoggedIn,
}: NavbarMenuProps) => {
  const firstPath = window.location.pathname.split("/")[1];
  const history = useHistory();
  const [isReferPage, setIsReferPage] = useState(false);
  const [userRole, setUserRole] = useState(USER_TYPE.APPLICANT);
  const [currentPage, setCurrentPage] = useState<string | undefined>(
    capitalizeFirstLetter(firstPath)
  );

  useEffect(() => {
    history.listen((location, action) => {
      const firstUri = window.location.pathname.split("/")[1];

      setTimeout(() => {
        const theUser = localStorage.getItem(`userDetails`)
          ? JSON.parse(localStorage.getItem(`userDetails`) || ``)
          : ``;
        if (theUser) {
          setUserRole(theUser.role);
        }
      }, 0);

      setIsReferPage(firstUri === `refer-a-friend`);
      assignCurrentPageName(firstUri);
    });
  }, [history]);

  useEffect(() => {
    const theUser = localStorage.getItem(`userDetails`)
      ? JSON.parse(localStorage.getItem(`userDetails`) || ``)
      : ``;
    setUserRole(theUser.role);
  }, [isLoggedIn]);

  const assignCurrentPageName = (firstUri = ``) => {
    const RELATED_PAGES = [
      "candidates",
      "companies",
      "hiring-managers",
      "opportunities",
    ];
    const isInRelatedPages = RELATED_PAGES.filter((item) => item === firstUri);
    if (isInRelatedPages) {
      const capitalizedUri = capitalizeFirstLetter(firstUri);
      setCurrentPage(capitalizedUri);
    }
  };

  const logoLinkGoTo = () => {
    if (isReferPage) {
      window.location.href = `https://devpartners.co/`;
    } else {
      history.push("/");
    }
  };

  const userIconMenu = <i className="bi bi-person lead" aria-hidden="true"></i>;
  const createNewIconMenu = (
    <span>
      <i className="bi bi-plus" aria-hidden="true"></i> Create New
    </span>
  );

  // TYPEAHEAD
  const [isLoading, setIsLoading] = useState(false);
  const [stackList, setStackList] = useState<any | null>([]);
  const [selectedStack, setSelectedStack] = useState<any | null>([]);

  const handleSearch = (SearchStr: string) => {
    setIsLoading(true);

    ApplicantsService.getSearch({ SearchStr }).then(
      ({ data }: AxiosResponse) => {
        setIsLoading(false);
        setStackList(data || []);
      }
    );
  };

  const addNewStack = (newStack: any = []) => {
    if (newStack.length) {
      switch (newStack[0].contactTypeName) {
        case `Company`:
          history.push(`/companies/${newStack[0].id}`);
          break;
        case `Hiring Manager`:
          history.push(`/hiring-managers/${newStack[0].id}`);
          break;
        case `Candidate`:
          history.push(`/profile/${newStack[0].id}`);
          break;
        case `Opportunity`:
          history.push(`/opportunities/${newStack[0].id}`);
          break;

        default:
          break;
      }
      setSelectedStack(newStack);
    } else {
      setSelectedStack([]);
    }
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <>
      <LazyLoad>
        <Navbar
          bg="primary"
          variant="dark"
          fixed="top"
          expand="lg"
          className="navigation-bar-container"
        >
          {isLoggedIn && userRole !== USER_TYPE.APPLICANT ? (
            <Container fluid>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <NavDropdown
                    title={currentPage}
                    className="candidates-dropdown"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Header>Select</NavDropdown.Header>

                    <NavDropdown.Item
                      onClick={() => history.push("/candidates")}
                    >
                      <i className="bi bi-person lead fa fa-fw"></i>
                      Candidates
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => history.push("/companies")}
                    >
                      <i className="bi bi-globe lead fa fa-fw"></i>
                      Companies
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => history.push("/hiring-managers")}
                    >
                      <i className="bi bi-award lead fa fa-fw"></i>
                      Hiring Manager
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => history.push("/opportunities")}
                    >
                      <i className="bi bi-briefcase lead fa fa-fw"></i>
                      Opportunities
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                {userRole !== USER_TYPE.TECHNICAL ? (
                  <Nav>
                    <NavDropdown
                      title={createNewIconMenu}
                      className="create-new"
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Header>Create New</NavDropdown.Header>

                      <NavDropdown.Item
                        onClick={() => history.push(`/companies/0`)}
                      >
                        <i className="bi bi-globe lead fa fa-fw"></i>
                        Companies
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => history.push(`/hiring-managers/0`)}
                      >
                        <i className="bi bi-award lead fa fa-fw"></i>
                        Hiring Manager
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => history.push(`/opportunity/0`)}
                      >
                        <i className="bi bi-briefcase lead fa fa-fw"></i>
                        Opportunities
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                ) : null}

                <Form className="d-flex search-form">
                  <AsyncTypeahead
                    filterBy={filterBy}
                    id="async-search"
                    isLoading={isLoading}
                    labelKey="contactName"
                    selected={selectedStack}
                    minLength={1}
                    onSearch={handleSearch}
                    onChange={addNewStack}
                    options={stackList}
                    delay={500}
                    useCache={false}
                    placeholder="Search name containing..."
                    renderMenuItemChildren={(option: any, props) => (
                      <Fragment>
                        <span key={option.contactName}>
                          {option.contactName}
                        </span>
                      </Fragment>
                    )}
                  />
                </Form>
                <Nav>
                  <NavDropdown
                    title={userIconMenu}
                    className="profile-dropdown"
                    align={"end"}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Header>Profile</NavDropdown.Header>

                    {userRole === USER_TYPE.SUPER_ADMIN ? (
                      <>
                        <NavDropdown.Item
                          onClick={() => history.push(`/settings/general`)}
                        >
                          {" "}
                          <i className="bi bi-gear lead fa fa-fw"></i>
                          Settings
                        </NavDropdown.Item>
                      </>
                    ) : null}
                    <NavDropdown.Item
                      onClick={() => history.push(`/change-password`)}
                    >
                      {" "}
                      <i className="bi bi-lock lead fa fa-fw"></i>
                      Change Password
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => logoutUser({ history, setIsLoggedIn })}
                    >
                      {" "}
                      <i className="bi bi-box-arrow-right lead fa fa-fw"></i>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          ) : (
            <Container>
              <Navbar.Brand>
                <img
                  src={devpartnersLogo}
                  alt="DP_LOGO"
                  onClick={logoLinkGoTo}
                />
              </Navbar.Brand>
              <Nav className="mr-auto"></Nav>
              {isReferPage ? null : (
                <NavbarMenuOptions
                  isRegister={isRegister}
                  isLoggedIn={isLoggedIn}
                  setIsLoggedIn={setIsLoggedIn}
                />
              )}
            </Container>
          )}
        </Navbar>
      </LazyLoad>
    </>
  );
};

export default NavigationBar;
