import "./TagsSettingsSearch.scss";
import { Col, Form } from "react-bootstrap";
import COLORS from "../../../../../src/assets/api/css-color-names.json";

const TagsSettingsSearch = ({
  addTag,
  tagName,
  setTagName,
  tagColor,
  handleSelectColorChange,
  skillsCategories,
  setSearchText,
  category,
  handleSelectCategoryChange,
  searchText,
  inputTagNameRef,
  currentTag,
}) => {
  return (
    <>
      <Col md={12}>
        <form className="row g-3 mb-3" onSubmit={addTag} ref={inputTagNameRef}
>
          <div className="col-auto">
            <input
              type="text"
              className="form-control-sm form-control"
              placeholder="Tag name"
              value={tagName}

              onChange={(e) => {
                setTagName(e.target.value);
              }}
            ></input>
          </div>
          <div className="col-auto">
            <Form.Select
              size="sm"
              value={tagColor}
              onChange={handleSelectColorChange}
            >
              {COLORS.map((value, index) => {
                return (
                  <option
                    key={index}
                    style={{ backgroundColor: value.value }}
                    value={value.value}
                  >
                    {value.name}
                  </option>
                );
              })}
            </Form.Select>
          </div>

          <div className="col-auto">
            <Form.Select
              size="sm"
              value={category}
              onChange={handleSelectCategoryChange}
            >
              {skillsCategories.map((value, index) => {
                return (
                  <option key={index} value={value.id}>
                    {value.name}
                  </option>
                );
              })}
            </Form.Select>
          </div>

          <div className="col-auto">
            <button
              type="submit"
              disabled={!tagName}
              className="btn text-white btn-sm btn-primary mb-3"
            >
              {currentTag.id ? `Update` : `Add`} Tag
            </button>
          </div>

          <div className="col-auto ms-auto search-btn">
            <div className="input-group mb-3">
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Search tags"
                aria-label="Search tags"
                aria-describedby="button-addon2"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              ></input>
              <button
                className="btn btn-outline-secondary btn-sm text-primary"
                type="button"
                onClick={() => setSearchText(searchText)}
                id="button-addon2"
              >
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
        </form>
      </Col>
    </>
  );
};

export default TagsSettingsSearch;
