export const DEFAULT_VALUES = {
    EXPERIENCE_SUMMRARY: {
        summary: ''
    },
    PROFESSIONAL_EXPERIENCE: {
        id: 0,
        company: '',
        position: '',
        dateStarted: new Date(),
        dateFinished: new Date(),
        jobDescription: '',
        stackUsed: ''
    },
    CERTIFICATE: {
        id: 0,
        company: '',
        program: '',
        dateIssued: new Date(),
        jobDescription: ''
    },
    EDUCATION: {
        school: '',
        course: '',
        dateStarted: new Date(),
        dateGraduated: new Date(),
    },
    SKILL: {
        skillName: '',
        skillLevel: 1,
        yearsOfExperience: 0,
        dateStarted: new Date(),
        dateGraduated: new Date(),
    },
    ADMIN_PROFILE: {
        id: "",
        username: "",
        email: "",
        roleId: "",
        role: "",
        firstName: "",
        lastName: "",
        formsDone: true,
        password: "",
        captchaResponse: ""
    }
};