import { LogoutUserParams } from "../interface/login.interface";

export const logoutUser = ({ history, setIsLoggedIn }: LogoutUserParams) => {
  if (history) {
    setIsLoggedIn(false);
    localStorage.clear();
    document.body.classList.remove("dark");

    history.push("/");
  }
};

export const capitalizeFirstLetter = (firstUri = '') => {
  const formattedUri = firstUri && firstUri.replace(/-/g, " ");
  const arr = formattedUri.split(" ");

  //loop through each element of the array and capitalize the first letter.
  for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

  }

  //Join all the elements of the array back into a string 
  //using a blankspace as a separator 
  const str2 = arr.join(" ");
  return str2;
}