import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const confirmDelete = (type: string = ``) => {
    return MySwal.fire({
        title: "Confirm!",
        text: `Are you sure you want to delete ${type}?`,
        icon: "question",
        confirmButtonColor: "#D93123",
        confirmButtonText: `Yes, delete ${type}`,
        showCancelButton: true,
      });
};
