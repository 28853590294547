import { Toast, ToastContainer } from "react-bootstrap";

const SuccessToast = ({ showToast, toggleShowToast, toastMessage }) => {
  return (
    <ToastContainer position="bottom-start" className="toast-container p-3">
      <Toast
        show={showToast}
        onClose={() => toggleShowToast(false)}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">Message</strong>
        </Toast.Header>
        <Toast.Body>
          <i
            className="fa fa-check-circle fa-fw text-success"
            aria-hidden="true"
          ></i>{" "}
          {toastMessage}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default SuccessToast;
