import { Badge } from "react-bootstrap";

const PriorityRenderer = (props) => {
  const item = props.data;

  return (
    <>
      <Badge pill className={`${"dot-priority-bg-" + item.priorityId}`}>
        {item.priorityText}
      </Badge>
    </>
  );
};

export default PriorityRenderer;
