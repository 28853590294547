import { AxiosResponse } from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import OpportunitiesService from "../../../services/http/opportunities.service";
// import OpportunitiesService from "../../../services/http/opportunities.service";
import "./OpportunityCandidateModal.scss";

const OpportunityCandidateModal = ({
  id,
  show,
  onHide,
  selectedColumn,
  updateKanban,
}) => {
  const [result, setResult] = useState<any[]>([]);

  const mapData = (data) => {
    return data.map((item) => {
      item.candidatesName = `${item.firstName} ${item.lastName}`;
      return item;
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [selectedStack, setSelectedStack] = useState<any | null>([]);
  const handleSearch = (SearchStr: string) => {
    setIsLoading(true);

    OpportunitiesService.getUnassignedCandidates({ SearchStr, id }).then(
      ({ data }: AxiosResponse) => {
        setIsLoading(false);
        console.log("data :>> ", data);
        if (data) {
          setResult(mapData(data));
        }
      }
    );
  };
  const addSelected = (theSelected: any = []) => {
    if (theSelected.length) {
      setSelectedStack(theSelected);
    } else {
      setSelectedStack([]);
    }
  };

  const saveColumn = () => {
    console.log("selectedStack, selectedStack", selectedStack);

    OpportunitiesService.addCandidateToOpportunityStatus(
      id,
      selectedColumn.id,
      { candidateId: selectedStack[0].id }
    ).then(() => {
      updateKanban(`Candidate successully added to ${selectedColumn.title}.`);
      onHide();
      setSelectedStack([]);
    });
    // OpportunitiesService.postOpportunityStatuses({
    //   StatusName: candidateName,
    //   Order: statusesLength + 1,
    // }).then(() => {
    //   updateKanban();
    //   onHide();
    //   setCandidateName(``);
    // });
  };
  return (
    <>
      <Modal size="sm" show={show} onHide={onHide}>
        <Modal.Body className="log-modal">
          <Form.Group>
            <AsyncTypeahead
              id="basic-typeahead-candidates"
              labelKey="contactName"
              isLoading={isLoading}
              minLength={1}
              delay={500}
              useCache={false}
              onSearch={handleSearch}
              onChange={(e) => {
                addSelected(e);
              }}
              options={result}
              placeholder="Search For Candidates"
              selected={selectedStack}
            />
          </Form.Group>
          <Form.Group className="mt-3 text-end">
            <Button onClick={onHide} variant="light">
              Cancel
            </Button>
            <Button
              onClick={saveColumn}
              disabled={!selectedStack || !selectedStack.length}
              variant="primary"
              className="text-white"
            >
              Save
            </Button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OpportunityCandidateModal;
