import './PageThree.scss';
import { FC, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { PageParams } from '../../../interface/resume-form.interface';
import { Accordion, Button } from 'react-bootstrap';
import { InitPageDetails } from '../../../custom-hooks/ResumeForms/resume.hooks';
import { InitFormThreeDetails, InitSkillTypes } from '../../../custom-hooks/ResumeForms/page-three.hooks';
import { Loading } from '../../../types/general.types';
import { SetSkillSelected, SkillsListSelected, SkillTypesList } from '../../../types/page-three.types';
import { FormThreeExistingSkills, GetSkillsProps, Skills, SkillTypes, SubmitSkillsSelectedProps } from '../../../interface/page-three.interface';
import LoadingScreen from '../../../components/shared/LoadingScreen/LoadingScreen';
import { setItemOnArray } from '../../../utilities/general.utilities';
import ThirdFormService from '../../../services/third-form.service';
import { AxiosResponse } from 'axios';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CheckBox from '../../../components/common/CheckBox/CheckBox';
import { useHistory } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const onCheckChange = ({ event, setSkillSet, skillSet }: GetSkillsProps) => {
    if (event && event.target) {
        if (event.target.value) {
            const value = Number(event.target.value);
            const newSkillSet = setItemOnArray({ item: value, array: skillSet });
            setSkillSet(newSkillSet);
        }
    }
};

const submitSkillsSelected = ({ skillsSelected, currentUserDetails, setIsButtonLoad, isNext, history, skillsList }: SubmitSkillsSelectedProps) => {
    if (skillsSelected.length && currentUserDetails) {
        setIsButtonLoad(true);
        const selectedSkillsFormat = skillsSelected.map((data: number) => {
            const currentId = skillsList.length ? skillsList.filter(dataDetails => dataDetails.skillsId === data) : null;
            return {
                id: currentId ? currentId[0]?.id : null,
                skillLevel: null,
                yearsOfExperience: null,
                userId: currentUserDetails.id,
                skillId: data
            };
        });
        const payload = {
            userSkills: selectedSkillsFormat
        };
        ThirdFormService.createUpdateFormThreeDetails({ userId: currentUserDetails.id, payload }).then((data: AxiosResponse) => {
            setIsButtonLoad(false);
            if (data && data.data.success) {
                if (!isNext) {
                    MySwal.fire({
                        title: "Success!",
                        text: "Your changes has beeen saved.",
                        icon: "success",
                        confirmButtonColor: "#F9A000",
                    });
                    return;
                }
                if (history) {
                    history.push('/client/page-four');
                }
            }
        }, () => {
            setIsButtonLoad(false);
        });
    }
};

const PageThree: FC<PageParams> = ({ setPageNumber, resumeDetails, currentUserDetails }) => {
    const history = useHistory();
    const [skillSet, setSkillSet]: SetSkillSelected = useState<number[]>([]);
    const [isLoading, setIsLoading]: Loading = useState<boolean>(false);
    const [skillTypesDetails, setSkillTypesDetails]: SkillTypesList = useState<SkillTypes[]>([]);
    const [isButtonLoad, setIsButtonLoad]: Loading = useState<boolean>(false);
    const [skillsList, setSkillsList]: SkillsListSelected = useState<FormThreeExistingSkills[]>([]);
    const submitButtonDisableClass = (skillSet.length >= 3) && !isButtonLoad ? "" : "is-disabled";
    InitPageDetails({ setPageNumber, resumeDetails });
    InitSkillTypes({ setIsLoading, setSkillTypeFunc: setSkillTypesDetails });
    InitFormThreeDetails({ skillTypesDetails, currentUserDetails, setIsLoading, setSkillSet, setSkillsList });
    return (
        <>
            <LazyLoad>
                <div className="page-three-container form-wrapper">
                    <LoadingScreen isLoading={isLoading}>
                        <h5 className="category-title">Skills <span>*</span></h5>
                        <Accordion>
                            {
                                skillTypesDetails.length ? (
                                    skillTypesDetails.map((details: SkillTypes) => (
                                        <Accordion.Item eventKey={`${details.skillTypeId}`} key={details.skillTypeId}>
                                            <Accordion.Header>{details.skillTypeName}</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="flex-container">
                                                    {
                                                        details.skills && details.skills.length ? (
                                                            details.skills.map((skillDetails: Skills) => (
                                                                <div className="flex-items" key={skillDetails.id}>
                                                                    <CheckBox 
                                                                        value={skillDetails.id}
                                                                        label={skillDetails.name}
                                                                        isDefaultChecked={skillSet.includes(skillDetails.id)}
                                                                        onChangeFunc={(event) => onCheckChange({ event, setSkillSet, skillSet })}
                                                                    />
                                                                </div>
                                                            ))
                                                        ) : <></>
                                                    }
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                ) : <></>
                            }
                        </Accordion>
                        <div className="buttons-container">
                            <div className="resume-forms-container">
                                <Button
                                    type="button"
                                    className={`save-button ${submitButtonDisableClass}`}
                                    onClick={() =>
                                        submitSkillsSelected({
                                            skillsSelected: skillSet, currentUserDetails, setIsButtonLoad, isNext: false, skillsList
                                        })}
                                >
                                    Save & Continue Later
                                </Button>
                                <Button
                                    type="button"
                                    className={`submit-button ${submitButtonDisableClass}`}
                                    onClick={
                                        () => submitSkillsSelected({
                                            skillsSelected: skillSet, currentUserDetails, setIsButtonLoad, isNext: true, history, skillsList
                                        })
                                    }
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </LoadingScreen>
                </div>
            </LazyLoad>
        </>
    );
};

export default PageThree;
