import { Row, Col } from 'react-bootstrap';
import './EditLogs.scss';


const EditLogs = ({ logs }) => {
    return (
        <>
              <Row>
                <Col className="mx-5 mb-5">
                  <ul className="list-unstyled text-muted-af">
                    {
                      logs && logs.map((item, index) => {
                        return (
                          <li key={index} className="mt-2">
                          <em>
                            {item.description} {item.logDate.substr(0, 10)}
                          </em>
                        </li>
                        )
                      })
                    }
                  </ul>
                </Col>
              </Row>
        </>
    );

};

export default EditLogs;