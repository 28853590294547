import { useHistory } from "react-router-dom";
import "./RequirementsRenderer.scss";

const RequirementsRenderer = (props) => {
  const item = props.data;

  const history = useHistory();

  const openLink = (item) => {
    if (item.documentLink) {
      const w = window.open(item.documentLink, "_blank");
      if (w) w.focus(); // okay now
    }
  };
  return (
    <>
    <div className="req-holder">

      <i
        className={
          item.documentLink
            ? `fa-paperclip fa-fw fa cursor-pointer text-info`
            : `fa fa-unlink text-muted`
        }
        title={
          item.documentLink ? `Click To View Document Link` : `No Document Link`
        }
        onClick={() => openLink(item)}
        aria-hidden="true"
      ></i>
      <span
        className="cursor-pointer"
        title={item.requirements}
        onClick={() => history.push(`/opportunities/${item.id}`)}
      >
        <span className={`${
          "dot-priority-" + item.priorityId
        }`}>{item.requirements}</span>
      </span>
    </div>
    </>
  );
};

export default RequirementsRenderer;
