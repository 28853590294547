import { useState, useEffect } from 'react';
import { Button, Modal, Table } from "react-bootstrap";
import "./LogProfileModal.scss";
import ApplicantsService from '../../../services/http/applicants.service';
import { AxiosResponse } from "axios";
import moment from 'moment';

interface LogProfileModalProps {
  show: boolean, isApplicant: boolean, onHide: any, userId: string
}
const LogProfileModal = ({ show, onHide, userId, isApplicant }: LogProfileModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [applicants, setApplicants] = useState<any[]>([]);


  const getLogs = (params: any = { PageSize: 150 }) => {
    if (!userId) {
      return;
    }

    setIsLoading(true);
    const theRequest = isApplicant ? ApplicantsService.getApplicantLogs(userId, params) : ApplicantsService.getAdminLogs(userId, params);
    theRequest.then(
      (data: AxiosResponse) => {
        setIsLoading(false);
        if (data && data.data && data.data.data) {
          setApplicants(data.data.data);
        }
      }
    );
  };

  useEffect(getLogs, [isApplicant, userId]);

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>
          <Modal.Header>
            <h5>Edit Log</h5>
          </Modal.Header>
          <Modal.Body className='log-modal'>
            <Table responsive hover className="mb-5">

                    <tbody>
                      {!applicants.length ? (
                          <tr>
                            <td
                              colSpan={2}
                              className="text-center text-muted py-5"
                            >
                              {isLoading ? "Loading..." : "No log found."}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}

                      {applicants.map((applicant) => {
                          return (
                            <tr key={applicant.id}>
                              <td>
                              {applicant.description}

                              </td>
                              <td className="text-end">
                                <span className="text-muted small text-end">
                                  {moment(applicant.logDate).format(`MMM DD YYYY HH:mm A`)}
                                </span>

                              </td>

                              </tr>
                        );
                      })}
                    </tbody>
                  </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide} variant="primary" className="text-white">
              OK
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogProfileModal;
