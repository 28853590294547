import { Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./OpportunitySkillsRenderer.scss";

const OpportunitySkillsRenderer = (props) => {
  const data = props.value;

  const history = useHistory();

  return (
    <div className="skill-list" title={data && data.length && data.map((item, index) => {
      return (
        `${item?.skillName}, `
      );
    })}>
      {data?.length
        ? data.map((item, index) => {
            return (
              <Badge
                pill

                onClick={() => history.push(`/opportunities/${props?.data?.id}`)}
                key={index}
                className={`${"dot-priority-bg-" + (index % 4)}`}
              >
                {item?.skillName}
              </Badge>
            );
          })
        : null}
    </div>
  );
};

export default OpportunitySkillsRenderer;
