// import { useState, useEffect } from 'react';
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import OpportunitiesService from "../../../services/http/opportunities.service";
import "./OpportunityColumnNameModal.scss";
// import ApplicantsService from '../../../services/http/applicants.service';
// import { AxiosResponse } from "axios";

const OpportunityColumnNameModal = ({
  show,
  onHide,
  statusesLength,
  updateKanban
}) => {
  const [statusName, setStatusName] = useState(``);
console.log('statusesLength', statusesLength)
  const saveColumn = () => {
    OpportunitiesService.postOpportunityStatuses({
      StatusName: statusName,
      Order: statusesLength + 1,
    }).then(() => {
      updateKanban();
      onHide();
      setStatusName(``);
    });
  };
  return (
    <>
      <Modal size="sm" show={show} onHide={onHide}>
        <Modal.Body className="log-modal">
          <Form.Group>
            <Form.Label>
              Column name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={statusName}
              required
              placeholder="Column name"
              onChange={(e) => {
                setStatusName(e.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant="light">
            Cancel
          </Button>
          <Button onClick={saveColumn} disabled={!statusName} variant="primary" className="text-white">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OpportunityColumnNameModal;
