import "./ResetPassword.scss";
import LazyLoad from "react-lazyload";
import { Container, Col, Row, Card, Button, Form } from "react-bootstrap";
import { useState } from "react";
import ApplicantsService from '../../services/http/applicants.service';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ResetPassword = () => {

  const [oldPassword, setOldPassword] = useState(``);
  const [newPassword, setNewPassword] = useState(``);
  const [confirmPassword, setConfirmPassword] = useState(``);
  const [isLoading, setIsLoading] = useState(false);

  
  const onSubmitForm = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      MySwal.fire({
        title: "Oopss!",
        text: `Confirm password does not match.`,
        icon: "error",
        confirmButtonColor: "#F9A000",
      })
      return;
    }
    setIsLoading(true);
    const form = {oldPassword, newPassword, confirmPassword};

    ApplicantsService.setPassword(form).then((res) => {
      setIsLoading(false);
      MySwal.fire({
        title: "Success!",
        text: `Password successfully updated.`,
        icon: "success",
        confirmButtonColor: "#F9A000",
      })
    }).catch((err) => {
      setIsLoading(false);
      MySwal.fire({
      title: "Oopss!",
      text: `Your current password is incorrect.`,
      icon: "error",
      confirmButtonColor: "#F9A000",
      })
    });
  };

  return (
    <>
      <LazyLoad>
        <div className="landing-container">
          <header>
            <h5>
              Change <span>Password</span>
            </h5>
          </header>
          <section>
            <Container>
                  <Row className="justify-content-md-center my-5">
                    <Col md={6}>
                      <Card>
                        <Card.Body>
                          <Form onSubmit={onSubmitForm}>

                            <Form.Group className="mt-3 mb-3">
                              <Form.Label>
                                Old Password <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control type="password" required value={oldPassword} onChange={(e) => { setOldPassword(e.target.value)}} placeholder="*******" />
                            </Form.Group>
                            <Form.Group className="mt-3">
                              <Form.Label>
                                New Password <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control type="password" required value={newPassword} onChange={(e) => { setNewPassword(e.target.value)}} placeholder="*******" />
                            </Form.Group>
                            <Form.Group className="mt-3">
                              <Form.Label>
                              Confirm Password <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control type="password" required value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value)}} placeholder="*******" />
                            </Form.Group>
                            <Form.Group className="mt-3">
                              <Button type='submit' variant="primary" disabled={isLoading}>Submit</Button>
                            </Form.Group>
                          </Form>

                        </Card.Body>
                      </Card>


                    </Col>


                  </Row>
            </Container>
          </section>

        </div>
      </LazyLoad>
    </>
  );
};

export default ResetPassword;
