import "./ChangePassword.scss";
import { Form } from "react-bootstrap";
import { useState } from "react";
import ApplicantsService from "../../../../services/http/applicants.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState(``);
  const [newPassword, setNewPassword] = useState(``);
  const [confirmPassword, setConfirmPassword] = useState(``);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      MySwal.fire({
        title: "Oopss!",
        text: `Confirm password does not match.`,
        icon: "error",
        confirmButtonColor: "#F9A000",
      });
      return;
    }
    setIsLoading(true);
    const form = { oldPassword, newPassword, confirmPassword };

    ApplicantsService.setPassword(form)
      .then((res) => {
        setIsLoading(false);
        MySwal.fire({
          title: "Success!",
          text: `Password successfully updated.`,
          icon: "success",
          confirmButtonColor: "#F9A000",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        MySwal.fire({
          title: "Oopss!",
          text: `Your current password is incorrect.`,
          icon: "error",
          confirmButtonColor: "#F9A000",
        });
      });
  };
  return (
    <>
      <Form onSubmit={onSubmitForm}>
        <h6 className="row">
          <div className="col">Password</div>
          <div className="col text-end">
            {isEdit ? (
              <span className="text-end">
                <button
                  title="Cancel"
                  className="btn btn-link btn-sm text-danger"
                  onClick={() => setIsEdit(false)}
                >
                  <i className="bi bi-x"></i>
                </button>
                <button
                  type="submit"
                  title="Save"
                  disabled={isLoading}
                  className="btn btn-link text-success btn-sm"
                >
                  <i className="bi bi-check-lg"></i>
                </button>{" "}
              </span>
            ) : null}
          </div>
        </h6>

        {!isEdit ? (
          <div className="mb-3 row">
            <label className="col col-form-label col-form-label-sm">
              Change password
            </label>
            <div className="col text-end">
              <button
                className="btn btn-link btn-sm"
                onClick={() => setIsEdit(true)}
              >
                <i className="bi bi-pencil-square"></i>
              </button>
            </div>
          </div>
        ) : null}

        {isEdit ? (
          <div>
            <div className="mb-3 row">
              <label className="col-md-5 col-form-label col-form-label-sm">
                Current Password
              </label>
              <div className="col-md-7">
                <Form.Control
                  type="password"
                  required
                  value={oldPassword}
                  className="form-control-sm"
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                  placeholder="*******"
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-5 col-form-label col-form-label-sm">
                New Password{" "}
              </label>
              <div className="col-md-7">
                <Form.Control
                  type="password"
                  required
                  className="form-control-sm"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  placeholder="*******"
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-5 col-form-label col-form-label-sm">
                Confirm Password{" "}
              </label>
              <div className="col-md-7">
                <Form.Control
                  type="password"
                  className="form-control-sm"
                  required
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  placeholder="*******"
                />
              </div>
            </div>
          </div>
        ) : null}
      </Form>
    </>
  );
};

export default ChangePassword;
