import http from '../../http-common';
import { AxiosPromise, AxiosResponse } from 'axios';
import { VerificationParams } from '../../interface/verification.interface';

const verifyAccount = (params: VerificationParams): AxiosPromise<AxiosResponse> => {
    return http.get(`/Users/verification?email=${params.email}&token=${params.token}`);
};

const VerificationService = {
    verifyAccount
};

export default VerificationService;