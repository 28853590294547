import http from '../../http-common';
import { AxiosPromise, AxiosResponse } from 'axios';

const getSettings = (): AxiosPromise<AxiosResponse> => {
    return http.get(`/AdminSettings/settings/general`);
};
const getAdmins = (params: any): AxiosPromise<AxiosResponse> => {
    return http.get(`/AdminSettings/settings/admins`, { params });
};
const getAccount = (): AxiosPromise<AxiosResponse> => {
    return http.get(`/AdminSettings/settings/account`);
};
const getSkills = (SearchStr: string): AxiosPromise<AxiosResponse> => {
    return http.get(`/AdminSettings/settings/skill-tag?SearchStr=${SearchStr}`);
};
const getSkillsCategory = (): AxiosPromise<AxiosResponse> => {
    return http.get(`/AdminSettings/settings/skill-tag/categories`);
};
const saveGeneral = (payLoad: any): AxiosPromise<AxiosResponse> => {
    return http.post(`/AdminSettings/settings/general`, payLoad);
};
const saveAccount = (payLoad: any): AxiosPromise<AxiosResponse> => {
    return http.post(`/AdminSettings/settings/account`, payLoad);
};
const updateSkill = (skillId: any, payLoad: any): AxiosPromise<AxiosResponse> => {
    return http.post(`/AdminSettings/settings/skill-tag/${skillId}`, payLoad);
};
const addSkill = (payLoad: any): AxiosPromise<AxiosResponse> => {
    return http.post(`/AdminSettings/settings/skill-tag`, payLoad);
};
const deleteSkill = (skillId: any): AxiosPromise<AxiosResponse> => {
    return http.delete(`/AdminSettings/settings/skill-tag/${skillId}`);
};

const AdminSettingsService = {
    getSettings,
    getAdmins,
    getAccount,
    saveGeneral,
    saveAccount,
    getSkills,
    getSkillsCategory,
    updateSkill,
    addSkill,
    deleteSkill,
};

export default AdminSettingsService;