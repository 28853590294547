import "./CandidateAssessment.scss";
import { Row, Col } from "react-bootstrap";
import AssessmentDropdown from "../AssessmentDropdown/AssessmentDropdown";
import { ASSESSMENT_TYPES } from "../../../constants/select-option-constants";
import ApplicantsService from "../../../services/http/applicants.service";
const CandidateAssessment = ({
  communicationAssesmentId,
  setCommunicationAssesmentId,
  knowledgeAssesmentId,
  setKnowledgeAssesmentId,
  skillAssesmentId,
  setSkillAssesmentId,
  updateUserDetails,
  id,
  showSuccessToast
}) => {
  const handleSelectChange = (title, e) => {
    const value = parseInt(e.target.value, 10);
    let theObsrvable: any = "";

    switch (title) {
      case ASSESSMENT_TYPES.COMMUNICATION:
        setCommunicationAssesmentId(value)
        theObsrvable = ApplicantsService.communicationAssesment(id, {id: value});
        break;
      case ASSESSMENT_TYPES.KNOWLEDGE:
        setKnowledgeAssesmentId(value)
        theObsrvable = ApplicantsService.knowledgeAssesment(id, {id: value});
        break;
      case ASSESSMENT_TYPES.SKILL:
        setSkillAssesmentId(value)
        theObsrvable = ApplicantsService.skillAssesment(id, {id: value});
        break;
      default:
        setCommunicationAssesmentId(value)
        break;
    }

    if (showSuccessToast) {
      theObsrvable.then(() => {
        showSuccessToast();
      });
    }
    // updateUserDetails();
  };

  return (
    <>
      <div className="resume-panel-container pb-4">
        <Row>
          <Col md={12}>
            <h5 className="section-title mb-4">Candidate Assessment</h5>
          </Col>
          <Col className="mb-3" md={3}>
            <AssessmentDropdown
              title={ASSESSMENT_TYPES.COMMUNICATION}
              selectedValue={communicationAssesmentId}
              handleSelectChange={handleSelectChange}
            ></AssessmentDropdown>
          </Col>
          <Col className="mb-3" md={3}>
            <AssessmentDropdown
              title={ASSESSMENT_TYPES.KNOWLEDGE}
              selectedValue={knowledgeAssesmentId}
              handleSelectChange={handleSelectChange}
            ></AssessmentDropdown>
          </Col>
          <Col className="mb-3" md={3}>
            <AssessmentDropdown
              title={ASSESSMENT_TYPES.SKILL}
              selectedValue={skillAssesmentId}
              handleSelectChange={handleSelectChange}
            ></AssessmentDropdown>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CandidateAssessment;
