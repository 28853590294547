import { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import "./CertificateFormModal.scss";
import { Certificate } from "../../../interface/page-two.interface";
import ApplicantsService from '../../../services/http/applicants.service';
interface CertificateFormModalProps {
  show: boolean, onHide: any, onSubmit: any, data: Certificate
}
const CertificateFormModal = ({ show, onHide, data, onSubmit }: CertificateFormModalProps) => {
  const [jobDescription, setJobDescription] = useState(``);
  const [program, setProgram] = useState(``);
  const [dateIssued, setDateIssued] = useState<any | null>(null);
  const [company, setCompany] = useState(``);

  const onSubmitForm = (e) => {
    e.preventDefault();
    const form = {id: data.id || ApplicantsService.getRandomDecimal(), jobDescription, dateIssued, program, company};
    onSubmit(form, `certificate`);
  };

  useEffect(() => {
    const { jobDescription, program, dateIssued, company} = data;
    const dateIssuedFormat = dateIssued?.toString()?.substring(0, 10);

    setJobDescription(jobDescription);
    setProgram(program);
    setDateIssued(dateIssuedFormat);
    setCompany(company);
  }, [data]);

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>

        <Form onSubmit={onSubmitForm}>
          <Modal.Body>
            <h5>{data?.id ? `Update` : `Add`} Certificate</h5>

            <Row>
              <Col lg={6}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Company name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control type="text" required placeholder="Company name" value={company} onChange={(e) => { setCompany(e.target.value)}} />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Date issued <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control type="date" required placeholder="Date issued" value={dateIssued} onChange={(e) => { setDateIssued(e.target.value)}} />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Program <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control type="text" required value={program} onChange={(e) => { setProgram(e.target.value)}} placeholder="Program" />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Job description <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control type="text" as="textarea" minLength={75} required rows={4} value={jobDescription} onChange={(e) => { setJobDescription(e.target.value)}} placeholder="Type your job description" />
                </Form.Group>
              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-primary text-decoration-none"
              onClick={onHide}
            >
              Close
            </Button>
            <Button type='submit' variant="primary" className="text-white">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CertificateFormModal;
