import PageFour from "../pages/ResumeForms/PageFour/PageFour";
import PageOne from "../pages/ResumeForms/PageOne/PageOne";
import PageThree from "../pages/ResumeForms/PageThree/PageThree";
import PageTwo from "../pages/ResumeForms/PageTwo/PageTwo";

export const PRIORITY = {
  HIGH: 3,
  MEDIUM: 2,
  LOW: 1,
  NOT_PRIO: 0,
};

export const PRIORITY_OPTIONS = [
  {
    value: `not priority`,
    id: 0,
  },
  {
    value: `low priority`,
    id: 1,
  },
  {
    value: `medium priority`,
    id: 2,
  },
  {
    value: `high priority`,
    id: 3,
  },
];

export const RESUME_FORMS_ROUTES = [
  {
    ID: 1,
    ROUTE: '/page-one',
    NAME: 'Page One',
    COMPONENT: PageOne
  },
  {
    ID: 2,
    ROUTE: '/page-two',
    NAME: 'Page Two',
    COMPONENT: PageTwo
  },
  {
    ID: 3,
    ROUTE: '/page-three',
    NAME: 'Page Three',
    COMPONENT: PageThree
  },
  {
    ID: 4,
    ROUTE: '/page-four',
    NAME: 'Page Four',
    COMPONENT: PageFour
  },
  {
    ID: 5,
    ROUTE: '/page-five',
    NAME: 'Page Five',
    COMPONENT: PageOne
  }
];


export const SKILL_DISPLAY_MAX = 3;
export const DEFAULT_PAGE_ROWS = 25;
export const MAX_REQUEST = 5000;
export const PAGE_ROWS = [
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 25,
  },
  {
    value: 30,
  },
  {
    value: 50,
  },
  {
    value: 100,
  },
  {
    value: 500,
  },
  {
    value: 1000,
  },
];
export const RELATION_TYPE = {
  CANDIDATE: `candidate`,
  COMPANY: `company`,
  HIRING_MANAGER: `hiring-manager`,
  OPPORTUNITY: `opportunity`,
};
