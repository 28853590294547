import { FC } from "react";
import LazyLoad from "react-lazyload";
import { ButtonProps } from "../../../interface/button.interface";
import { Button } from "react-bootstrap";
import "./Button.scss";
import { DEFAULT_TEXT } from "../../../constants/button-constants";

const SubmitButton: FC<ButtonProps> = ({ isFormReady, buttonText }) => {
  const buttonDisplayText = buttonText || DEFAULT_TEXT;
  return (
    <>
      <LazyLoad>
        <Button type="submit" className={`submit-button ${isFormReady}`}>
          {buttonDisplayText}
        </Button>
      </LazyLoad>
    </>
  );
};

export default SubmitButton;
