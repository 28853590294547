import { AxiosPromise, AxiosResponse } from 'axios';
import http from '../../http-common';
import { RelatedToParams } from '../../interface/form.interface';

const OPPORTUNITY = `/Admin/opportunities`;

const getOpportunities = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.get(OPPORTUNITY, { params });
};

const createOpportunity = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(OPPORTUNITY, params);
};

const createOpportunityCandidate = (id: string, params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`${OPPORTUNITY}/${id}/relatedCandidates`, params);
};

const updateOpportunity = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`${OPPORTUNITY}/${params.id}`, params);
};

const getOpportunity = (id: number): AxiosPromise<AxiosResponse> => {
  return http.get(`${OPPORTUNITY}/${id}`);
};

const deleteOpportunity = (id: number): AxiosPromise<AxiosResponse> => {
  return http.delete(`${OPPORTUNITY}/${id}`);
};

const createSkill = (id: number, params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`${OPPORTUNITY}/${id}/skills`, params);
};

const deleteSkill = (id: number, skillId: number): AxiosPromise<AxiosResponse> => {
  return http.delete(`${OPPORTUNITY}/${id}/skills/${skillId}`);
};

const getOpportunityPriorities = () => {
  return http.get(`${OPPORTUNITY}/priorities`);
};

const getOpportunityStatuses = () => {
  return http.get(`${OPPORTUNITY}/candidates/statuses`);
};

const postOpportunityStatuses = (params: any) => {
  return http.post(`${OPPORTUNITY}/candidates/statuses`, params);
};

const updateOpportunityStatuses = (params: any, id: any) => {
  return http.post(`${OPPORTUNITY}/candidates/statuses/${id}`, params);
};
const deleteOpportunityStatuses = (id: any) => {
  return http.delete(`${OPPORTUNITY}/candidates/statuses/${id}`);
};

const addCandidateToOpportunityStatus = (id: number, statusId: number, params: any) => {
  return http.post(`${OPPORTUNITY}/${id}/statuses/${statusId}/candidates`, params);
};

const undoDeleteOpportunityStatuses = (undoToken: any) => {
  return http.post(`${OPPORTUNITY}/candidates/statuses/undoDelete/${undoToken}`);
};

const getUnassignedCandidates = (params: any) => {
  return http.get(`${OPPORTUNITY}/${params?.id}/unassigned-candidates`, params);
};

const getOpportunityKanban = (id: number) => {
  return http.get(`${OPPORTUNITY}/${id}/candidates`);
};

const postOpportunityKanban = (id: number, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`${OPPORTUNITY}/${id}/candidates`, params);
};

const advancedSearch = (SearchStr: string): AxiosPromise<AxiosResponse> => {
  return http.get(`${OPPORTUNITY}/keywords/search?SearchStr=${SearchStr}`);
};
const advancedSearchResult = (params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`${OPPORTUNITY}/search`, params);
};

const createCompany = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.post(`${OPPORTUNITY}/${id}/relatedCompany`, params);
};

const removeCompany = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.delete(`${OPPORTUNITY}/${id}/relatedCompany`, { data: params });
};

const createCandidate = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.post(`${OPPORTUNITY}/${id}/relatedCandidate`, params);
};

const removeCandidate = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.delete(`${OPPORTUNITY}/${id}/relatedCandidate`, { data: params });
};

const createHiringManager = (userId: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.post(`${OPPORTUNITY}/${userId}/relatedHiringManager`, params);
};

const removeHiringManager = (userId: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.delete(`${OPPORTUNITY}/${userId}/relatedHiringManager`, { data: params });
};

const OpportunitiesService = {
  getOpportunities,
  getOpportunity,
  deleteOpportunity,
  createSkill,
  deleteSkill,
  updateOpportunity,
  createOpportunity,
  createOpportunityCandidate,
  getOpportunityPriorities,
  getOpportunityStatuses,
  postOpportunityStatuses,
  updateOpportunityStatuses,
  deleteOpportunityStatuses,
  addCandidateToOpportunityStatus,
  undoDeleteOpportunityStatuses,
  getUnassignedCandidates,
  getOpportunityKanban,
  postOpportunityKanban,
  advancedSearch,
  advancedSearchResult,
  createCompany,
  removeCompany,
  createCandidate,
  removeCandidate,
  createHiringManager,
  removeHiringManager,

};

export default OpportunitiesService;