import './LoadingScreen.scss';
import { LoadingScreenProps } from '../../../interface/loading.interface';


const LoadingScreen = ({ isLoading, children }: LoadingScreenProps) => {
    if (isLoading) {
        return (
            <>
                <div className="loading-screen-container">
                    <div className="snippet" data-title=".dot-pulse">
                        <div className="stage">
                            <div className="dot-pulse"></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>{children}</>
    );
};

export default LoadingScreen;