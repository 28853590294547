import { AxiosPromise, AxiosResponse } from 'axios';
import http from '../http-common';

const getUserAvailabilityList = (): AxiosPromise<AxiosResponse> => {
    return http.get('/Lookups/user-availability');
};

const getSkillsList = (params: any = {}): AxiosPromise<AxiosResponse> => {
    return http.get('/Lookups/skills', {params});
};

const LookupsService = {
    getUserAvailabilityList,
    getSkillsList
};

export default LookupsService;