import { useState, useEffect } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import "./KeyAchievementModal.scss";
import { KeyAchievement } from "../../../interface/page-two.interface";
import ApplicantsService from '../../../services/http/applicants.service';

interface KeyAchievementFormModalProps {
  show: boolean, onHide: any, onSubmit: any, data: KeyAchievement
}
const KeyAchievementModal = ({ show, onHide, data, onSubmit }: KeyAchievementFormModalProps) => {
  const [summary, setSummary] = useState(``);

  const onSubmitForm = (e) => {
    e.preventDefault();
    const form = {id: data.id || ApplicantsService.getRandomDecimal(), summary};
    onSubmit(form, `keyAchievements`);
  };

  useEffect(() => {
    setSummary(data.summary);
  }, [data]);

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>

        <Form onSubmit={onSubmitForm}>
          <Modal.Body>
            <h5>{data?.id ? `Update` : `Add`} Key Achievement</h5>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>
                Summary <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control type="text" as="textarea" required rows={7} value={summary} onChange={(e) => { setSummary(e.target.value)}} placeholder="Write in a form of one sentence" />
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-primary text-decoration-none"
              onClick={onHide}
            >
              Close
            </Button>
            <Button type='submit' variant="primary" className="text-white">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default KeyAchievementModal;
