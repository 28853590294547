import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { USER_TYPE } from "../../../constants/nav-constants";
import CompaniesService from "../../../services/http/companies.service";
const MySwal = withReactContent(Swal);

const CompanyTableActions = (props) => {
  const item = props.data;
  const theUser = props.theUser;
  const history = useHistory();

  const deleteItem = (id: number) => {
    MySwal.fire({
      title: "Confirm!",
      text: `Are you sure you want to delete selected company?`,
      icon: "question",
      confirmButtonColor: "#D93123",
      confirmButtonText: `Yes, Delete Company`,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        CompaniesService.deleteCompany(id)
          .then((response: any) => {
            if (response.status === 200) {
              MySwal.fire({
                title: "Success!",
                text: `Company successfully deleted.`,
                icon: "success",
                confirmButtonColor: "#F9A000",
              });
            }
          })
          .catch(() => {});
      }
    });
  };

  return (
    <>

      <Dropdown align={`end`}>
        <Dropdown.Toggle
          className="py-0 text-primary borderless"
          size="sm"
          variant="outline-light"
          id={`dropdown-basic${item.id}`}
        >
          <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => history.push(`/companies/${item.id}`)}>
            Edit Company
          </Dropdown.Item>
          {theUser.role === USER_TYPE.SUPER_ADMIN ? (
            <div>
              <Dropdown.Item onClick={() => deleteItem(item.id)}>
                Delete Company
              </Dropdown.Item>
            </div>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CompanyTableActions;
