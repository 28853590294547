import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import SelectOptionsList from '../../../hoc/SelectOptions/SelectOptions';
import { SelectInputProps } from '../../../interface/form.interface';
import { OptionsListDetails } from '../../../types/form.types';
import Options from '../../Select/Options/Options';
import './SelectInput.scss';

const SelectOptions = SelectOptionsList(Options);

const SelectInput = ({
    label,
    name,
    isRequired = false,
    onChangeFunc,
    value,
    isDisabled = false,
    optionsDetails,
    register
}: SelectInputProps) => {
    const [optionList, setOptionList]: OptionsListDetails = useState<any[]>([]);
    useEffect(() => {
        setOptionList(optionsDetails);
    }, [optionsDetails]);
    return (
        <div className="form-wrapper">
            <Form.Group controlId="availability" className="text-box">
                {label && <p className="input-title">{label}{isRequired && <span>*</span>}</p> }
                <Form.Select
                    size="sm"
                    name={name}
                    value={value}
                    onChange={onChangeFunc}
                    disabled={isDisabled}
                    {...register}
                >
                    <SelectOptions optionsList={optionList} />
                </Form.Select>
            </Form.Group>
        </div>
    );
};

export default SelectInput;
