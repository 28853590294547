import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { UseFormTrigger } from "react-hook-form";
import { GetUserResumeDetailsParams } from "../interface/form.interface";
import { FormObject } from "../interface/hook-form.interface";
import { Mounted } from "../types/general.types";
import FourthFormService from "./fourth-form.service";

export const triggerFunc = (trigger: UseFormTrigger<FormObject>) => {
    if (trigger) {
        trigger();
    }
};

export const getUserResumeDetails = ({ setUserResumeDetails, setIsPageLoading, userId }: GetUserResumeDetailsParams) => {
    const userDetailsStorage = localStorage.getItem('userDetails');
    if (userDetailsStorage) {
        const userDetails = JSON.parse(userDetailsStorage);
        setIsPageLoading(true);
        const theUserId = userId || userDetails.userId;
        FourthFormService.getFormUserDetails(theUserId).then((data: AxiosResponse) => {
            setIsPageLoading(false);
            if (data && data.data) {
                setUserResumeDetails(data.data);
            }
        });
    }
};

export const InitResumePageDetails = ({ setUserResumeDetails, setIsPageLoading, userId }: GetUserResumeDetailsParams) => {
    const [isMount, setIsMount]: Mounted = useState<boolean>(false);
    useEffect(() => {
        if (!isMount) {
            setIsMount(true);
        }
        getUserResumeDetails({ setUserResumeDetails, setIsPageLoading, userId });
    }, [isMount, setUserResumeDetails, setIsPageLoading, userId]);
};