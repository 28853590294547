import "./RelatedHiringManagers.scss";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import HiringManagersService from "../../../services/http/hiring-managers.service";
import {
  MAX_REQUEST,
  RELATION_TYPE,
} from "../../../constants/resume-forms-constants";
import ApplicantsService from "../../../services/http/applicants.service";
import CompaniesService from "../../../services/http/companies.service";
import OpportunitiesService from "../../../services/http/opportunities.service";
import { confirmDelete } from "../../../services/confirm-delete.service";
import { USER_TYPE } from "../../../constants/nav-constants";

const RelatedHiringManagers = ({
  relatedHiringManagers,
  setRelatedHiringManagers,
  updateUserDetails,
  id,
  type,
  showSuccessToast,
}) => {
  const history = useHistory();
  const [searchText] = useState("");
  const [singleSelections] = useState([]);
  const [result, setResult] = useState<any[]>([]);

  const saveChanges = (data: any) => {
    let theObsrvable: any = "";
    switch (type) {
      case RELATION_TYPE.CANDIDATE:
        theObsrvable = ApplicantsService.createHiringManager(id, data);
        break;
      case RELATION_TYPE.COMPANY:
        theObsrvable = CompaniesService.createHiringManager(id, data);
        break;
      case RELATION_TYPE.OPPORTUNITY:
        theObsrvable = OpportunitiesService.createHiringManager(id, data);
        break;
      default:
        break;
    }
    if (showSuccessToast) {
      theObsrvable.then(() => {
        showSuccessToast();
      });
    }
  };

  useEffect(() => {
    const getData = (params: any) => {
      HiringManagersService.getHiringManagers(params).then(
        (data: AxiosResponse) => {
          if (data && data.data && data.data.data) {
            setResult(data.data.data);
          }
        }
      );
    };

    getData({
      SearchStr: searchText,
      PageSize: MAX_REQUEST,
      PageNumber: 1,
    });
  }, [searchText]);

  const addSelected = (theSelected: any = []) => {
    if (
      theSelected.length &&
      theSelected[theSelected.length - 1].name &&
      !theSelected[theSelected.length - 1].customOption
    ) {
      const theSelectedName = theSelected.length
        ? theSelected[theSelected.length - 1].name
        : ``;

      const trimStack = theSelectedName.trim() || ``;
      if (theSelected) {
        const isAlreadySelected = relatedHiringManagers.filter(
          (item) => item.contactName === trimStack
        );

        if (!isAlreadySelected.length) {
          const newItem = {
            id: theSelected[0]?.id,
            contactName: theSelected[0]?.name,
          };
          setRelatedHiringManagers([...relatedHiringManagers, newItem]);
          saveChanges(newItem);
          // setTimeout(() => {
          //   if (updateUserDetails) updateUserDetails();
          // }, 500);
        }
      }
    } else {
      newHiringManager(theSelected[0].name);
      // history.push(`/hiring-managers/0?name=${theSelected[0].name}`);
    }
  };

  const newHiringManager = (name: string) => {
    HiringManagersService.createHiringManager({
      name,
    }).then(({ data }: AxiosResponse) => {
      const newItem = {
        id: data?.id,
        contactName: name,
      };

      setRelatedHiringManagers([...relatedHiringManagers, newItem]);
      saveChanges(newItem);
    });
  };

  const removeHiringManager = (params: any = {}) => {
    confirmDelete(`related hiring manager`).then((res) => {
      if (res.isConfirmed) {
        deleteNow(params);
      }
    });
  };

  const deleteNow = (params: any = {}) => {
    let theObsrvable: any = "";
    switch (type) {
      case RELATION_TYPE.CANDIDATE:
        theObsrvable = ApplicantsService.removeHiringManager(id, params);
        break;
      case RELATION_TYPE.COMPANY:
        theObsrvable = CompaniesService.removeHiringManager(id, params);
        break;
      case RELATION_TYPE.OPPORTUNITY:
        theObsrvable = OpportunitiesService.removeHiringManager(id, params);
        break;
      default:
        break;
    }
    const filtered = relatedHiringManagers.filter((item) => {
      return item.id !== params.id;
    });
    setRelatedHiringManagers(filtered);
    if (showSuccessToast) {
      theObsrvable.then(() => {
        showSuccessToast();
      });
    }
  };

  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  return (
    <>
      <Form.Group>
        <Form.Label className="text-strong">
          <i className="bi bi-award lead fa fa-fw"></i>
          Related Hiring Managers{" "}
        </Form.Label>
        <div className="text-info mb-2">
          <ul className="list-unstyled my-0">
            {relatedHiringManagers && relatedHiringManagers.length
              ? relatedHiringManagers.map((data, index) => {
                  return (
                    <li className="mb-1" key={index}>
                      {theUser.role !== USER_TYPE.TECHNICAL ? (
                        <span
                          className="cursor-pointer text-danger"
                          onClick={() => {
                            removeHiringManager(data);
                          }}
                          title={`Remove ${data?.contactName}`}
                        >
                          &times;
                        </span>
                      ) : null}
                      <span
                        className="cursor-pointer"
                        title={`View ${data?.contactName}`}
                        onClick={() =>
                          history.push(`/hiring-managers/${data.id}`)
                        }
                      >
                        {data?.contactName}
                      </span>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
        <Typeahead
          id="basic-typeahead-hiring"
          labelKey="name"
          allowNew
          minLength={1}
          disabled={theUser.role === USER_TYPE.TECHNICAL}
          newSelectionPrefix="Add new hiring manager: "
          onChange={(e) => {
            addSelected(e);
          }}
          className="input-line-typeahead"
          options={result}
          placeholder="Add Related Hiring Managers"
          selected={singleSelections}
        />
      </Form.Group>
    </>
  );
};

export default RelatedHiringManagers;
