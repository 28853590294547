import "@asseinfo/react-kanban/dist/styles.css";
import "./OpportunitiesView.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { Redirect, useHistory, useParams } from "react-router-dom";
import Board, { moveCard } from "@asseinfo/react-kanban";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import OpportunitiesService from "../../services/http/opportunities.service";
import RelatedHiringManagers from "../../components/shared/RelatedHiringManagers/RelatedHiringManagers";
import RelatedCompanies from "../../components/shared/RelatedCompanies/RelatedCompanies";
import { USER_TYPE } from "../../constants/nav-constants";
import { confirmDelete } from "../../services/confirm-delete.service";
import OpportunityColumnNameModal from "../../components/shared/OpportunityColumnNameModal/OpportunityColumnNameModal";
import UndoToast from "../../components/common/UndoToast/UndoToast";
import OpportunityCandidateModal from "../../components/shared/OpportunityCandidateModal/OpportunityCandidateModal";
import { SETTING_INDEX } from "../../constants/select-option-constants";

const OpportunitiesView = () => {
  const theUserSettings = JSON.parse(
    localStorage.getItem("userSettings") || ``
  );

  const history = useHistory();
  const params: any = useParams();
  const [kanban, setKanban] = useState<any>({ columns: [] });
  const [triggerKanbanUpdate, setTriggerKanbanUpdate] = useState(false);
  const [isUndo, setIsUndo] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [undoToken, setUndoToken] = useState(``);
  const [toastMessage, setToastMessage] = useState(``);
  const toggleShowUndoToast = (
    value: boolean = false,
    message: string = ``
  ) => {
    if (value) {
      setToastMessage(message);
    }
    setShowToast(value);
  };

  const [showColumnModal, setShowColumnModal] = useState(false);
  const handleCloseColumnModal = () => setShowColumnModal(false);
  const handleShowColumnModal = () => {
    setShowColumnModal(true);
  };

  const [selectedColumn, setSelectedColumn] = useState<any>({});
  const [showAddCandidateModal, setShowAddCandidateModal] = useState(false);
  const handleCloseAddCandidateModal = () => setShowAddCandidateModal(false);
  const handleShowAddCandidateModal = () => {
    setShowAddCandidateModal(true);
  };

  const [isEdit, setIsEdit] = useState(false);
  // const [priorities, setPriorities] = useState<any>([]);
  const [requirements, setRequirements] = useState(``);
  const [salary, setSalary] = useState(``);
  const [timeZone, setTimeZone] = useState(``);
  const [requiredSkills, setRequiredSkills] = useState(``);
  const [priorityId, setPriorityId] = useState(``);
  const [priorityName, setPriorityName] = useState(``);
  // const [salary, setSalary] = useState(``);
  // const [timeZone, setTimeZone] = useState(``);
  // const [requiredSkills, setRequiredSkills] = useState(``);
  const [relatedCompanies, setRelatedCompanies] = useState<any>([]);
  // const [relatedCandidates, setRelatedCandidates] = useState<any>([]);
  const [relatedHiringManagers, setRelatedHiringManagers] = useState<any>([]);

  const mapKanbanPayload = (updatedBoard) => {
    const value = updatedBoard.columns.map((item) => {
      return {
        id: item.id,
        statusName: item.title,
        order: item?.order,
        candidates: item.cards.map((card, index) => {
          return {
            ...card,
            order: index,
          };
        }),
      };
    });

    return {
      statuses: value,
    };
  };

  const saveKanban = (payLoad) => {
    OpportunitiesService.postOpportunityKanban(params?.id, payLoad);
  };

  const onCardDragEnd = (_card, source, destination) => {
    const updatedBoard = moveCard(kanban, source, destination);
    const kanbanValue = mapKanbanPayload(updatedBoard);
    saveKanban(kanbanValue);
    setKanban(updatedBoard);
  };

  useEffect(() => {
    const mapCandidates = (data) => {
      return data.map((item, index) => {
        return {
          id: item.id,
          title: item.contactName,
        };
      });
    };

    const mapKanban = (data) => {
      return data.map((item, index) => {
        return {
          id: item.id,
          title: item.statusName,
          cards: mapCandidates(item.candidates),
        };
      });
    };
    const getData = () => {
      OpportunitiesService.getOpportunityKanban(params?.id).then(
        ({ data }: AxiosResponse) => {
          if (data) {
            setRequirements(data?.requirements);
            setSalary(data?.salary);
            setRequiredSkills(data?.requiredSkills);
            setTimeZone(data?.timeZone);
            setPriorityId(data?.priorityId);
            setPriorityName(data?.priorityName);
            setRelatedHiringManagers(data?.relatedHiringManagers);
            setRelatedCompanies(data?.relatedCompanies);

            const theKanban = mapKanban(data.statuses);

            setKanban({ columns: theKanban });
          }
        }
      );
    };
    getData();
  }, [params, triggerKanbanUpdate]);

  const undoAction = () => {
    OpportunitiesService.undoDeleteOpportunityStatuses(undoToken).then(
      ({ data }: AxiosResponse) => {
        setIsUndo(false);
        setShowToast(false);
        setTriggerKanbanUpdate(!triggerKanbanUpdate);
        toggleShowUndoToast(true, `Successfully undo column deletion.`);
      }
    );
  };

  const addCandidate = (columnData: any = {}) => {
    handleShowAddCandidateModal();
    setSelectedColumn(columnData);
    console.log("columnData :>> ", columnData);
  };

  const removeColumn = (columnData: any = {}) => {
    confirmDelete(`column ${columnData.title}`).then((res) => {
      if (res.isConfirmed) {
        console.log("columnData", columnData);
        OpportunitiesService.deleteOpportunityStatuses(columnData.id).then(
          ({ data }: AxiosResponse) => {
            setTriggerKanbanUpdate(!triggerKanbanUpdate);
            console.log("data", data);
            setIsUndo(true);
            toggleShowUndoToast(true, `Column Deleted`);
            setUndoToken(data.undoToken);
          }
        );
      }
    });
  };
  const updateKanban = (message = ``) => {
    setTriggerKanbanUpdate(!triggerKanbanUpdate);
    setIsUndo(false);
    toggleShowUndoToast(true, message || `New status successfully added.`);
  };

  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  return (
    <>
      {theUser.role === USER_TYPE.APPLICANT ? <Redirect to={`/profile`} /> : ``}
      <OpportunityColumnNameModal
        show={showColumnModal}
        onHide={handleCloseColumnModal}
        statusesLength={kanban?.columns?.length || 0}
        updateKanban={updateKanban}
      ></OpportunityColumnNameModal>
      <OpportunityCandidateModal
        id={params?.id}
        show={showAddCandidateModal}
        onHide={handleCloseAddCandidateModal}
        selectedColumn={selectedColumn}
        updateKanban={updateKanban}
      ></OpportunityCandidateModal>
      <UndoToast
        showToast={showToast}
        toggleShowToast={toggleShowUndoToast}
        toastMessage={toastMessage}
        undoAction={undoAction}
        isUndo={isUndo}
      ></UndoToast>
      <LazyLoad>
        <Container className="kanban-container" fluid>
          <Row className="mt-5 pt-4 mx-0 px-0">
            <Col lg={3}>
              <Button
                className="text-decoration-none text-primary"
                variant="link"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-angle-left fa-fw" aria-hidden="true"></i>
                Back to opportunities list
              </Button>
            </Col>
          </Row>

          <Row className="kanban-header-row">
            <Col className="px-md-5 align-self-end pb-3">
              <h4>
                <span
                  className="text-light-green cursor-pointer"
                  onClick={() => history.push(`/opportunity/${params?.id}`)}
                >
                  {requirements}
                </span>{" "}
                <small className="font-12 px-3">
                  <i
                    className={`fa fa-circle fa-fw small-dot ${
                      "dot-priority-" + priorityId
                    }`}
                  ></i>{" "}
                  {priorityName}
                </small>
              </h4>
              <p>
                Salary of {salary}. {timeZone}.{" "}
                {requiredSkills ? `Required skills of ${requiredSkills}` : ``}
              </p>
            </Col>

            {theUser.role !== USER_TYPE.TECHNICAL ? (
              <>
                <Col className="offset-mb-4" md={4}>
                  <Row>
                    <Col className="mb-2 mt-2" md={6}>
                      <RelatedCompanies
                        relatedCompanies={relatedCompanies}
                        setRelatedCompanies={setRelatedCompanies}
                        id={params?.id}
                        type={`opportunity`}
                        showSuccessToast={``}
                      ></RelatedCompanies>
                    </Col>
                    <Col className="mb-2 mt-2 align-self-end" md={6}>
                      <RelatedHiringManagers
                        updateUserDetails={``}
                        relatedHiringManagers={relatedHiringManagers}
                        setRelatedHiringManagers={setRelatedHiringManagers}
                        id={params?.id}
                        type={`opportunity`}
                        showSuccessToast={``}
                      ></RelatedHiringManagers>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {theUserSettings[
                        SETTING_INDEX.ENABLE_EDIT_PIPELINE_SETTINGS
                      ].settingValue ? (
                        <div className="text-end">
                          <button
                            onClick={() => handleShowColumnModal()}
                            type="button"
                            className="btn btn-link text-decoration-none"
                          >
                            <i
                              className="fa fa-fw fa-plus-circle"
                              aria-hidden="true"
                            ></i>
                            Add Column
                          </button>

                          {isEdit ? (
                            <span>
                              <button
                                type="button"
                                onClick={() => setIsEdit(false)}
                                className="btn btn-link text-danger text-decoration-none"
                              >
                                <i
                                  className="fa fa-fw fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                Cancel
                              </button>
                              <button
                                type="button"
                                onClick={() => setIsEdit(false)}
                                className="btn btn-success text-white btn-sm text-decoration-none"
                              >
                                Save
                              </button>
                            </span>
                          ) : (
                            <button
                              type="button"
                              onClick={() => setIsEdit(true)}
                              className="btn btn-link text-decoration-none"
                            >
                              <i
                                className="fa fa-fw fa-pencil"
                                aria-hidden="true"
                              ></i>
                              Edit
                            </button>
                          )}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <Col className="mb-4 offset-mb-4" md={2}>
                <div className="py-md-5">
                  <div className="py-md-1"></div>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col className={`px-md-5 ${isEdit ? "edit-kanban" : ""}`}>
              <Board
                renderColumnHeader={(data, actions) => (
                  <div className="row">
                    <div className="col py-2">{data.title}</div>
                    <div className="col text-end">
                      <button
                        onClick={() => removeColumn(data)}
                        className="btn btn-link btn-sm text-danger text-decoration-none kanban-edit-action"
                        type="button"
                      >
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                      <button
                        onClick={() => addCandidate(data)}
                        className="btn btn-link btn-sm text-primary text-decoration-none kanban-edit-action"
                        type="button"
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                )}
                renderCard={({ title, id }, { removeCard, dragging }) => (
                  <div className="react-kanban-card">
                    <div
                      className="react-kanban-card__title"
                      onClick={() => history.push(`/profile/${id}`)}
                    >
                      {title}
                    </div>
                  </div>
                )}
                onCardDragEnd={onCardDragEnd}
                disableColumnDrag={true}
              >
                {kanban}
              </Board>
            </Col>
          </Row>
        </Container>
      </LazyLoad>
    </>
  );
};

export default OpportunitiesView;
