import "moment-timezone";
import TIME_ZONES from "../../../../src/assets/api/timezones.json";

import { Col, Card, Form } from "react-bootstrap";
import "./GeneralSettingsTab.scss";
import {
  DATE_FORMATS,
  TIME_FORMATS,
  GENERAL_SETTINGS,
  SETTING_INDEX,
} from "../../../constants/select-option-constants";
import SocialSettingsCard from "../SocialSettingsCard/SocialSettingsCard";
import AdminSettingsService from "../../../services/http/admin-settings.service";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";

const GeneralSettingsTab = () => {
  const [settings, setSettings] = useState<any>(GENERAL_SETTINGS);
  useEffect(() => {
    AdminSettingsService.getSettings().then((data: AxiosResponse) => {
      setSettings(data.data);
      console.log("data", data.data);
    });
  }, []);

  const updateValue = (e, settingIndex, isCheckbox = true) => {
    const updatedValue = settings.map((item, index) => {
      if (index === settingIndex) {
        item.settingValue = isCheckbox ? e.target.checked : e.target.value;
      }
      return item;
    });
    setSettings(updatedValue);

    localStorage.setItem('userSettings',JSON.stringify(updatedValue) );


    if (settingIndex === SETTING_INDEX.ENABLE_DARK_MODE) {
      if (document.body.classList.contains("dark") && !e.target.checked) {
        document.body.classList.remove("dark");
      } else {
        document.body.classList.add("dark");
      }
    }

    const copyUpdatedValue = JSON.parse(JSON.stringify(updatedValue));
    const stringifyValue = copyUpdatedValue.map((item) => {
      item.settingValue = item.settingValue.toString();
      return item;
    });

    AdminSettingsService.saveGeneral(stringifyValue).then(
      (data: AxiosResponse) => {
        console.log("data", data.data);
      }
    );
  };

  return (
    <>
      <Col lg={4}>
        <Card className="mb-3">
          <Card.Body className="m-1">
            <h6 className="mb-3">Appearance</h6>

            <div className="mb-3 row">
              <label className="col-8 col-form-label col-form-label-sm">
                Enable dark mode
              </label>
              <div className="col-4 mt-1 text-end">
                <Form.Check
                  type="switch"
                  checked={
                    settings[SETTING_INDEX.ENABLE_DARK_MODE]?.settingValue
                  }
                  onChange={(e) =>
                    updateValue(e, SETTING_INDEX.ENABLE_DARK_MODE)
                  }
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-6 col-form-label col-form-label-sm">
                Timezone
              </label>
              <div className="col-md-6">
                <Form.Select
                  size="sm"
                  value={settings[SETTING_INDEX.TIMEZONE]?.settingValue}
                  onChange={(e) =>
                    updateValue(e, SETTING_INDEX.TIMEZONE, false)
                  }
                >
                  {TIME_ZONES.map((value, index) => {
                    return (
                      <option key={index} value={value.offset}>
                        {value.text}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-6 col-form-label col-form-label-sm">
                Date format
              </label>
              <div className="col-md-6">
                <Form.Select
                  size="sm"
                  value={settings[SETTING_INDEX.DATE_FORMAT]?.settingValue}
                  onChange={(e) =>
                    updateValue(e, SETTING_INDEX.DATE_FORMAT, false)
                  }
                >
                  {DATE_FORMATS.map((value, index) => {
                    return (
                      <option key={index} value={value.value}>
                        {value.preview}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-6 col-form-label col-form-label-sm">
                Time format
              </label>
              <div className="col-md-6">
                <Form.Select
                  size="sm"
                  value={settings[SETTING_INDEX.TIME_FORMAT]?.settingValue}
                  onChange={(e) =>
                    updateValue(e, SETTING_INDEX.TIME_FORMAT, false)
                  }
                >
                  {TIME_FORMATS.map((value, index) => {
                    return (
                      <option key={index} value={value.value}>
                        {value.preview}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Body className="m-1">
            <h6 className="mb-3">Pipeline</h6>

            <div className="mb-3 row">
              <label className="col-8 col-form-label col-form-label-sm">
                Enable editing of pipeline settings
              </label>
              <div className="col-4 mt-1 text-end">
                <Form.Check
                  type="switch"
                  checked={
                    settings[SETTING_INDEX.ENABLE_EDIT_PIPELINE_SETTINGS]
                      ?.settingValue
                  }
                  onChange={(e) =>
                    updateValue(e, SETTING_INDEX.ENABLE_EDIT_PIPELINE_SETTINGS)
                  }
                />
              </div>
            </div>

            <div className="mb-3 row">
              <label className="col-8 col-form-label col-form-label-sm">
                Prevent adding new users{" "}
                <em className="text-danger">**Warning**</em>
              </label>
              <div className="col-4 mt-1 text-end">
                <Form.Check
                  type="switch"
                  checked={
                    settings[SETTING_INDEX.PREVENT_ADD_NEW_USER]?.settingValue
                  }
                  onChange={(e) =>
                    updateValue(e, SETTING_INDEX.PREVENT_ADD_NEW_USER)
                  }
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={5}>
        <Card className="mb-3">
          <Card.Body className="m-1">
            <h6 className="mb-3">Notifications</h6>

            <div className="mb-3 row">
              <label className="col-8 col-form-label col-form-label-sm">
                Enable all email notifications
              </label>
              <div className="col-4 mt-1 text-end">
                <Form.Check
                  type="switch"
                  checked={
                    settings[SETTING_INDEX.ENABLE_ALL_EMAIL_NOTIF]?.settingValue
                  }
                  onChange={(e) =>
                    updateValue(e, SETTING_INDEX.ENABLE_ALL_EMAIL_NOTIF)
                  }
                />
              </div>
            </div>

            <div className="mb-3 row">
              <label className="col-8 col-form-label col-form-label-sm">
                News and updates
              </label>
              <div className="col-4 mt-1 text-end">
                <Form.Check
                  type="switch"
                  checked={settings[SETTING_INDEX.NEWS_UPDATES]?.settingValue}
                  onChange={(e) => updateValue(e, SETTING_INDEX.NEWS_UPDATES)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-8 col-form-label col-form-label-sm">
                Tips and tutorials
              </label>
              <div className="col-4 mt-1 text-end">
                <Form.Check
                  type="switch"
                  checked={settings[SETTING_INDEX.TIPS_TUTORIALS]?.settingValue}
                  onChange={(e) => updateValue(e, SETTING_INDEX.TIPS_TUTORIALS)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-8 col-form-label col-form-label-sm">
                Profile updates
              </label>
              <div className="col-4 mt-1 text-end">
                <Form.Check
                  type="switch"
                  checked={
                    settings[SETTING_INDEX.PROFILE_UPDATES]?.settingValue
                  }
                  onChange={(e) =>
                    updateValue(e, SETTING_INDEX.PROFILE_UPDATES)
                  }
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-8 col-form-label col-form-label-sm">
                Alerts and reminders
              </label>
              <div className="col-4 mt-1 text-end">
                <Form.Check
                  type="switch"
                  checked={
                    settings[SETTING_INDEX.ALERTS_REMINDERS]?.settingValue
                  }
                  onChange={(e) =>
                    updateValue(e, SETTING_INDEX.ALERTS_REMINDERS)
                  }
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-8 col-form-label col-form-label-sm">
                Opt in user research for future updates
              </label>
              <div className="col-4 mt-1 text-end">
                <Form.Check
                  type="switch"
                  checked={
                    settings[
                      SETTING_INDEX.OPT_IN_USER_RESEARCH_FOR_FUTURE_UPDATES
                    ]?.settingValue
                  }
                  onChange={(e) =>
                    updateValue(
                      e,
                      SETTING_INDEX.OPT_IN_USER_RESEARCH_FOR_FUTURE_UPDATES
                    )
                  }
                />
              </div>
            </div>
          </Card.Body>{" "}
        </Card>
      </Col>
      <Col lg={3}>
        <SocialSettingsCard></SocialSettingsCard>
      </Col>
    </>
  );
};

export default GeneralSettingsTab;
