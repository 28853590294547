import { AxiosPromise, AxiosResponse } from 'axios';
import http from '../../http-common';
import { RelatedToParams } from '../../interface/form.interface';


const getCompanies = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.get('/Admin/companies', { params });
};

const createCompany = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post('/Admin/companies', params);
};
const updateCompany = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`/Admin/companies/${params.id}`, params);
};

const getCompany = (id: number): AxiosPromise<AxiosResponse> => {
  return http.get(`/Admin/companies/${id}`);
};

const deleteCompany = (id: number): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Admin/companies/${id}`);
};

const createOpportunity = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.post(`/Admin/companies/${id}/relatedOpportunity`, params);
};

const removeOpportunity = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Admin/companies/${id}/relatedOpportunity`, { data: params });
};

const createHiringManager = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.post(`/Admin/companies/${id}/relatedHiringManager`, params);
};

const removeHiringManager = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Admin/companies/${id}/relatedHiringManager`, { data: params });
};

const CompaniesService = {
  getCompanies,
  createCompany,
  updateCompany,
  getCompany,
  deleteCompany,
  createOpportunity,
  removeOpportunity,
  createHiringManager,
  removeHiringManager
};

export default CompaniesService;