import "./RelatedOpportunities.scss";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import OpportunitiesService from "../../../services/http/opportunities.service";
import { AxiosResponse } from "axios";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import {
  MAX_REQUEST,
  RELATION_TYPE,
} from "../../../constants/resume-forms-constants";
import ApplicantsService from "../../../services/http/applicants.service";
import CompaniesService from "../../../services/http/companies.service";
import HiringManagersService from "../../../services/http/hiring-managers.service";
import { confirmDelete } from "../../../services/confirm-delete.service";
import CandidateOpportunityStatus from "../CandidateOpportunityStatus/CandidateOpportunityStatus";
import { USER_TYPE } from "../../../constants/nav-constants";

const RelatedOpportunities = ({
  relatedOpportunities,
  setRelatedOpportunities,
  updateUserDetails,
  id,
  type,
  showSuccessToast,
}) => {
  const history = useHistory();
  const [searchText] = useState("");
  const [singleSelections] = useState([]);
  const [result, setResult] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<any[]>([]);

  const saveChanges = (data: any) => {
    let theObsrvable: any = "";
    switch (type) {
      case RELATION_TYPE.CANDIDATE:
        theObsrvable = ApplicantsService.createOpportunity(id, data);
        break;
      case RELATION_TYPE.COMPANY:
        theObsrvable = CompaniesService.createOpportunity(id, data);
        break;
      case RELATION_TYPE.HIRING_MANAGER:
        theObsrvable = HiringManagersService.createOpportunity(id, data);
        break;
      default:
        break;
    }
    if (showSuccessToast) {
      theObsrvable.then(() => {
        showSuccessToast();
      });
    }
  };

  useEffect(() => {
    const getApplicants = (params: any) => {
      OpportunitiesService.getOpportunities(params).then(
        (data: AxiosResponse) => {
          if (data && data.data && data.data.data) {
            setResult(data.data.data);
          }
        }
      );
    };

    getApplicants({
      SearchStr: searchText,
      PageSize: MAX_REQUEST,
      PageNumber: 1,
    });
  }, [searchText]);

  useEffect(() => {
    const getStatuses = () => {
      OpportunitiesService.getOpportunityStatuses().then(
        (data: AxiosResponse) => {
          setStatuses(data.data);
        }
      );
    };
    getStatuses();
  }, []);

  const addSelected = (theSelected: any = []) => {
    if (
      theSelected.length &&
      theSelected[theSelected.length - 1].requirements &&
      !theSelected[theSelected.length - 1].customOption
    ) {
      const theSelectedName = theSelected.length
        ? theSelected[theSelected.length - 1].requirements
        : ``;

      const trimStack = theSelectedName.trim() || ``;
      if (theSelected) {
        const isAlreadySelected = relatedOpportunities.filter(
          (item) => item.contactName === trimStack
        );

        if (!isAlreadySelected.length) {
          const newItem = {
            id: theSelected[0]?.id,
            contactName: theSelected[0]?.requirements,
            statusId: 1,
            statusName: "Potential Reserves",
          };

          setRelatedOpportunities([...relatedOpportunities, newItem]);
          saveChanges(newItem);
          // setTimeout(() => {
          //   if (updateUserDetails) updateUserDetails();
          // }, 500);
        }
      }
    } else {
      newOpportunity(theSelected[0].requirements);
      // history.push(
      //   `/opportunity/0?requirements=${theSelected[0].requirements}`
      // );
    }
  };

  const newOpportunity = (req: string) => {
    OpportunitiesService.createOpportunity({
      requirements: req,
    }).then(({ data }: AxiosResponse) => {
      const newItem = {
        id: data?.id,
        contactName: data?.requirements,
        statusId: 1,
        statusName: "Potential Reserves",
      };

      setRelatedOpportunities([...relatedOpportunities, newItem]);
      saveChanges(newItem);
    });
  };

  const removeOpportunity = (params: any = {}) => {
    confirmDelete(`related opportunity`).then((res) => {
      if (res.isConfirmed) {
        deleteNow(params);
      }
    });
  };

  const deleteNow = (params: any = {}) => {
    let theObsrvable: any = "";
    switch (type) {
      case RELATION_TYPE.CANDIDATE:
        theObsrvable = ApplicantsService.removeOpportunity(id, params);
        break;
      case RELATION_TYPE.COMPANY:
        theObsrvable = CompaniesService.removeOpportunity(id, params);
        break;
      case RELATION_TYPE.HIRING_MANAGER:
        theObsrvable = HiringManagersService.removeOpportunity(id, params);
        break;
      default:
        break;
    }

    const filtered = relatedOpportunities.filter((item) => {
      return item.id !== params.id;
    });
    setRelatedOpportunities(filtered);
    if (showSuccessToast) {
      theObsrvable.then(() => {
        showSuccessToast();
      });
    }
  };

  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  return (
    <>
      <Form autoComplete="false">
        <Form.Group>
          <Form.Label className="text-strong">
            <i className="bi bi-briefcase lead fa fa-fw"></i>
            Related Opportunities{" "}
          </Form.Label>
          <div className="section-scrollablex">
            <div className="text-info mb-2">
              <ul className="list-unstyled my-0">
                {relatedOpportunities && relatedOpportunities.length
                  ? relatedOpportunities.map((data, index) => {
                      return (
                        <li className="list-flex mb-1" key={index}>
                          {theUser.role !== USER_TYPE.TECHNICAL || type === RELATION_TYPE.CANDIDATE ? (
                            <div
                              className="cursor-pointer text-danger"
                              onClick={() => {
                                removeOpportunity(data);
                              }}
                              title={`Remove ${data?.contactName}`}
                            >
                              &times;
                            </div>
                          ) : null}
                          <div className="cursor-pointer">
                            <div
                              title={`View ${data?.contactName}`}
                              onClick={() =>
                                history.push(`/opportunities/${data.id}`)
                              }
                            >
                              {data?.contactName}
                            </div>
                          </div>
                          {data?.statusName ? (
                            <div className="mx-3">
                              <CandidateOpportunityStatus
                                showSuccessToast={showSuccessToast}
                                statuses={statuses}
                                relatedOpportunities={relatedOpportunities}
                                setRelatedOpportunities={
                                  setRelatedOpportunities
                                }
                                data={data}
                                userId={id}
                              ></CandidateOpportunityStatus>
                            </div>
                          ) : (
                            ``
                          )}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>

          <Typeahead
            id="basic-typeahead-opportunity"
            labelKey="requirements"
            allowNew
            minLength={1}
            disabled={theUser.role === USER_TYPE.TECHNICAL && type !== RELATION_TYPE.CANDIDATE}
            newSelectionPrefix="Add new opportunity: "
            onChange={(e) => {
              addSelected(e);
            }}
            className="input-line-typeahead"
            options={result}
            placeholder="Add Related Opportunities"
            selected={singleSelections}
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default RelatedOpportunities;
