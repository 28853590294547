export const LOGIN_ROUTE = '/login';
export const USER_TYPE = {
  TECHNICAL: `Technical`,
  ADMIN: `Admin`,
  SUPER_ADMIN: `Super-Admin`,
  APPLICANT: `Applicant`,
  CRM: `CRM`,
};

export const DEFAULT_GRID_OPTION = {
  headerHeight: 30,
  rowHeight: 30,
};

export const DEFAULT_COL_DEF = {
  sortable: true,
  resizable: true,
  filter: true,
  width: 170,
  wrapText: false,
}

export const SETTINGS_TAB_NAV = {
  GENERAL: `general`,
  ACCOUNT: `account`,
  ADMIN_MANAGEMENT: `admin-management`,
  TAGS: `tags`,
}