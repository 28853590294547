import "./ProfileRelatedTo.scss";
import { Col, Row } from "react-bootstrap";
import RelatedOpportunities from "../shared/RelatedOpportunities/RelatedOpportunities";
import ProfileAssignedTo from "../shared/ProfileAssignedTo/ProfileAssignedTo";
import { USER_TYPE } from "../../constants/nav-constants";

const ProfileRelatedTo = ({
  relatedOpportunities,
  setRelatedOpportunities,
  assignedTo,
  setAssignedTo,
  updateUserDetails,
  id,
  currentUserDetails,
  type,
  showSuccessToast,
}) => {
  return (
    <>
      <div className="resume-panel-container pb-4">
        <Row>
          <Col className="mb-4" lg={6}>
            <RelatedOpportunities
              relatedOpportunities={relatedOpportunities}
              updateUserDetails={updateUserDetails}
              setRelatedOpportunities={setRelatedOpportunities}
              id={id}
              type={type}
              showSuccessToast={showSuccessToast}
            ></RelatedOpportunities>
          </Col>
          {currentUserDetails.role === USER_TYPE.ADMIN ||
          currentUserDetails.role === USER_TYPE.SUPER_ADMIN ? (
            <Col className="mb-4" md={6} lg={3}>
              <ProfileAssignedTo
                assignedTo={assignedTo}
                setAssignedTo={setAssignedTo}
                updateUserDetails={updateUserDetails}
                id={id}
                type={type}
                showSuccessToast={showSuccessToast}
              ></ProfileAssignedTo>
            </Col>
          ) : null}
        </Row>
      </div>
    </>
  );
};

export default ProfileRelatedTo;
