import { AxiosPromise, AxiosResponse } from 'axios';
import http from '../http-common';
import { AddUpdatePageFourDetailsParams } from '../interface/page-four.interface';


const getFormFourDetails = (userId: string): AxiosPromise<AxiosResponse> => {
    return http.get(`/Forms/four/${userId}`);
};

const AddUpdateFormFourDetails = ({ userId, payload }: AddUpdatePageFourDetailsParams) => {
    return http.post(`/Forms/four/${userId}`, payload);
};

const getFormUserDetails = (userId: string) => {
    return http.get(`Forms/user/${userId}`);
};

const FourthFormService = {
    getFormFourDetails,
    AddUpdateFormFourDetails,
    getFormUserDetails
};

export default FourthFormService;