import { Card, Col, Container, Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "./SettingsView.scss";

import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import SuccessToast from "../../components/common/SuccessToast/SuccessToast";
import { SETTINGS_TAB_NAV, USER_TYPE } from "../../constants/nav-constants";
import SettingsTab from "./SettingsTab/SettingsTab";
import GeneralSettingsTab from "./GeneralSettingsTab/GeneralSettingsTab";
import AccountSettingsTab from "./AccountSettingsTab/AccountSettingsTab";
import AdminSettingsTab from "./AdminSettingsTab/AdminSettingsTab";
import TagsSettingsTab from "./TagsSettingsTab/TagsSettingsTab";

const SettingsView = () => {
  const params: any = useParams();
  const [tabKey, setTabKey] = useState<any>(params.tab || SETTINGS_TAB_NAV.GENERAL);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(``);
  const toggleShowToast = (value: boolean = false, message: string = ``) => {
    if (value) {
      setToastMessage(message);
    }
    setShowToast(value);
  };

  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  return (
    <>
      {theUser.role === USER_TYPE.APPLICANT ? <Redirect to={`/profile`} /> : ``}

      <SuccessToast
        showToast={showToast}
        toggleShowToast={toggleShowToast}
        toastMessage={toastMessage}
      ></SuccessToast>
      <LazyLoad>
        <Container fluid className="main-container settings-container">
          <Row className="mt-5 pt-4 mx-0 px-0">
            <Col sm={3} className="align-self-center">
              <h5 className="my-1">Settings</h5>
            </Col>

            <Col xs={12}>
              <Card className="mb-3 main-card">
                <Card.Body>
                  <SettingsTab tabKey={tabKey} setTabKey={setTabKey}></SettingsTab>
                </Card.Body>
              </Card>
            </Col>
            {
              tabKey === SETTINGS_TAB_NAV.GENERAL ?
              <GeneralSettingsTab></GeneralSettingsTab> : null
            }
            {
              tabKey === SETTINGS_TAB_NAV.ACCOUNT ?
              <AccountSettingsTab></AccountSettingsTab> : null
            }
            {
              tabKey === SETTINGS_TAB_NAV.ADMIN_MANAGEMENT ?
              <AdminSettingsTab></AdminSettingsTab> : null
            }
            {
              tabKey === SETTINGS_TAB_NAV.TAGS ?
              <TagsSettingsTab></TagsSettingsTab> : null
            }
          </Row>
        </Container>
      </LazyLoad>
    </>
  );
};

export default SettingsView;
