import "./SettingsTab.scss";
import {Tabs, Tab} from "react-bootstrap";

import { SETTINGS_TAB_NAV } from "../../../constants/nav-constants";

const SettingsTab = ({tabKey, setTabKey}) => {

  return (
    <>
      <Tabs
        variant="pills"
        id="controlled-settings-tab"
        activeKey={tabKey}
        onSelect={(selectedTab) => setTabKey(selectedTab)}
        className="mb-2 settings-tab"
      >
        <Tab eventKey={SETTINGS_TAB_NAV.GENERAL} title="General">
          <span className="mx-1 small">
            View and update your basic details and configurations of the portal
          </span>
        </Tab>
        <Tab eventKey={SETTINGS_TAB_NAV.ACCOUNT} title="Account">
          <span className="mx-1 small">
            View and update your details and public information{" "}
          </span>
        </Tab>
        <Tab eventKey={SETTINGS_TAB_NAV.ADMIN_MANAGEMENT} title="Admin Management">
          <span className="mx-1 small">
            Check and update users roles and permissions{" "}
          </span>{" "}
        </Tab>
        <Tab eventKey={SETTINGS_TAB_NAV.TAGS} title="Tags">
          <span className="mx-1 small">
            Edit system-wide tagging entries used for organizing data{" "}
          </span>{" "}
        </Tab>
      </Tabs>
    </>
  );
};

export default SettingsTab;
