import { SKILLS_RATING_LIST } from "../constants/page-four-constants";
import { GetPageDetailsProps } from "../interface/resume-form.interface";

export const getPageDetails = ({ setPageNumber, resumeDetails }: GetPageDetailsProps): void => {
    setPageNumber(resumeDetails.ID);
};

export const getSkillRatingName = (id: Number): string => {
    if (id === null || id === undefined) {
        return '';
    }
    const stringName = SKILLS_RATING_LIST.filter(data => data.id === id);
    if (stringName.length) {
        return stringName[0].name;
    }
    return '';
};