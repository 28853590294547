import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { baseUrlLive } from '../../constants/login-constants';
import http from '../../http-common';
import { FormObject } from '../../interface/hook-form.interface';

const uninterceptedAxiosInstance = axios.create({
    baseURL: baseUrlLive,
    headers: {
        "Content-type": "application/json",
    },
});
const loginUser = (payload: FormObject): AxiosPromise<AxiosResponse> => {
    return http.post('/Users/sign-in', payload);
};

const getCurrentUserDetails = (): AxiosPromise<AxiosResponse> => {
    return http.get('/Users/current-user');
};


const forgotPassword = (payload: FormObject): AxiosPromise<AxiosResponse> => {
    return uninterceptedAxiosInstance.post('/Users/forgot-password', payload);
};

const resetPassword = (payload: FormObject): AxiosPromise<AxiosResponse> => {
    return uninterceptedAxiosInstance.post('/Users/reset-password', payload);
};

const LoginService = {
    loginUser,
    getCurrentUserDetails,
    forgotPassword,
    resetPassword,
};

export default LoginService;