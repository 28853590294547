import { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import LazyLoad from 'react-lazyload';
import { InitPageDetails } from '../../../custom-hooks/ResumeForms/resume.hooks';
import { PageParams } from '../../../interface/resume-form.interface';
import SelectOptionsList from '../../../hoc/SelectOptions/SelectOptions';
import Options from '../../../components/Select/Options/Options';
import './PageOne.scss';
import { UserAvailabilityListDetails } from '../../../types/user-availability.types';
import { GetUserAvailabilityParams, UserAvailability } from '../../../interface/user-availability.interface';
import LookupsService from '../../../services/lookups-service';
import { AxiosResponse } from 'axios';
import { Loading } from '../../../types/general.types';
import * as EmailValidator from 'email-validator';
import { numberOnlyKeypress } from '../../../utilities/general.utilities';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PageOneService from '../../../services/page-one.service';
import { InitFormOneDetails, SetEmail } from '../../../custom-hooks/ResumeForms/page-one.hooks';
import { IsFormOneDetails } from '../../../types/page-one.types';
import { useHistory } from 'react-router-dom';
import FormButtons from '../../../components/common/FormButtons/FormButtons';
import { SaveCallBackParams } from '../../../interface/form.interface';
import { saveFormFunc } from '../../../utilities/form.utilities';
import LoadingScreen from '../../../components/shared/LoadingScreen/LoadingScreen';

const SelectOptions = SelectOptionsList(Options);
const MySwal = withReactContent(Swal);

const getUserAvailabilityList = ({ setUserAvailabilityList }: GetUserAvailabilityParams) => {
    LookupsService.getUserAvailabilityList().then((data: AxiosResponse) => {
        if (data && data.data && data.data.length) {
            const userAvailabilityListDetails = data.data.map((userAvailabilityDetails: UserAvailability) => {
                return {
                    id: userAvailabilityDetails.id,
                    name: userAvailabilityDetails.name
                };
            });
            setUserAvailabilityList(userAvailabilityListDetails);
        }
    });
};

const saveFirstForm = ({ formValues, currentUserDetails, setIsLoading, isNext, history, isNew }: SaveCallBackParams): void => {
    const {
        firstName,
        lastName,
        contactNumber,
        email,
        address,
        availability,
        desiredPosition
    } = formValues;
    const userId = currentUserDetails?.id;
    const payload = {
        userId,
        firstName,
        lastName,
        phoneNumber: contactNumber,
        email,
        address,
        userAvailabilityId: Number(availability),
        desiredPosition
    };
    setIsLoading(true);
    if (isNew) {
        return;
    }
    PageOneService.saveFormOneDetails({ userId, payload }).then((data: AxiosResponse) => {
        if (data && data.data.success) {
            setIsLoading(false);
            if (!isNext) {
                MySwal.fire({
                    title: "Success!",
                    text: "Your changes has beeen saved.",
                    icon: "success",
                    confirmButtonColor: "#F9A000",
                });
                return;
            }
            if (history) {
                history.push('/client/page-two');
            }
        }
    }, (error: any) => {
        if (error) {
            console.error(error);
        }
        setIsLoading(false);
    });
};

const PageOne: FC<PageParams> = ({ setPageNumber, resumeDetails, currentUserDetails }) => {
    const history = useHistory();
    const [userAvailabilityList, setUserAvailabilityList]: UserAvailabilityListDetails = useState<UserAvailability[]>([]);
    const [isLoading, setIsLoading]: Loading = useState<boolean>(false);
    const [isPageLoading, setIsPageLoading]: Loading = useState<boolean>(false);
    const [isDetails, setIsDetails]: IsFormOneDetails = useState<boolean>(false);
    const { register, formState, handleSubmit, getValues, setValue } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        shouldFocusError: true
    });
    const isFormReady = isDetails || (formState.isValid && !isLoading);
    const submitButtonDisableClass = isFormReady ? "" : "is-disabled";
    InitPageDetails({ setPageNumber, resumeDetails });
    SetEmail({ currentUserDetails, setValue, setIsLoading });
    InitFormOneDetails({ setValue, currentUserDetails, setIsLoading, setIsDetails, setIsPageLoading });
    useEffect(() => {
        getUserAvailabilityList({ setUserAvailabilityList });
        return () => {
            setUserAvailabilityList([]);
        };
    }, []);
    return (
        <>
            <LazyLoad>
                <LoadingScreen isLoading={isPageLoading}>
                    <div className="first-page-form">
                        <form onSubmit={handleSubmit(data => saveFormFunc({
                            formValues: data,
                            currentUserDetails,
                            setIsLoading,
                            isNext: true,
                            saveCallback: saveFirstForm,
                            history
                        }))}>
                            <div className="flex-two-section">
                                <div className="flex-container">
                                    <div className="form-wrapper">
                                        <Form.Group controlId="firstName" className="text-box">
                                            <p className="input-title">First name <span>*</span></p>
                                            <Form.Control
                                                autoComplete="off"
                                                type="text"
                                                className="is-valid"
                                                {...register("firstName", {
                                                    required: true,
                                                })}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-container">
                                    <div className="form-wrapper">
                                        <Form.Group controlId="lastName" className="text-box">
                                            <p className="input-title">Last name <span>*</span></p>
                                            <Form.Control
                                                autoComplete="off"
                                                type="text"
                                                className="is-valid"
                                                {...register("lastName", {
                                                    required: true,
                                                })}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-two-section">
                                <div className="flex-container">
                                    <div className="form-wrapper">
                                        <Form.Group controlId="contactNumber" className="text-box">
                                            <p className="input-title">Contact number <span>*</span></p>
                                            <Form.Control
                                                autoComplete="off"
                                                type="text"
                                                className="is-valid"
                                                onKeyPress={(event) => {
                                                    numberOnlyKeypress(event);
                                                }}
                                                {...register("contactNumber", {
                                                    required: true,
                                                })}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-container">
                                    <div className="form-wrapper">
                                        <Form.Group controlId="email" className="text-box">
                                            <p className="input-title">Email <span>*</span></p>
                                            <Form.Control
                                                autoComplete="off"
                                                type="email"
                                                className="is-valid"
                                                {...register("email", {
                                                    required: true,
                                                    validate: (value: string) => EmailValidator.validate(value)
                                                })}
                                                readOnly={true}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="form-wrapper">
                                <Form.Group controlId="address" className="text-box">
                                    <p className="input-title">Address <span>*</span></p>
                                    <Form.Control
                                        autoComplete="off"
                                        type="text"
                                        className="is-valid"
                                        {...register("address", {
                                            required: true,
                                        })}
                                    />
                                </Form.Group>
                            </div>
                            <div className="flex-two-section">
                                <div className="flex-container">
                                    <div className="form-wrapper">
                                        <Form.Group controlId="availability" className="text-box">
                                            <p className="input-title">Availability <span>*</span></p>
                                            <Form.Select
                                                size="sm"
                                                defaultValue={1}
                                                {...register("availability", {
                                                    required: true,
                                                    validate: (value) => Number(value) !== 0
                                                })}
                                            >
                                                <SelectOptions optionsList={userAvailabilityList} />
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="flex-container">
                                    <div className="form-wrapper">
                                        <Form.Group controlId="desiredPosition" className="text-box">
                                            <p className="input-title">Desired Position <span>*</span></p>
                                            <Form.Control
                                                autoComplete="off"
                                                type="text"
                                                className="is-valid"
                                                {...register("desiredPosition", {
                                                    required: true,
                                                })}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <FormButtons
                                submitButtonDisableClass={submitButtonDisableClass}
                                saveFormCallback={saveFirstForm}
                                currentUserDetails={currentUserDetails}
                                setIsLoading={setIsLoading}
                                getValues={getValues}
                                history={history}
                            />
                        </form>
                    </div>
                </LoadingScreen>
            </LazyLoad>
        </>
    );
};

export default PageOne;