import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SaveFormParams } from "../interface/form.interface";
import { FormObject } from "../interface/hook-form.interface";
import { EditFormsParams } from "../interface/resume-form.interface";

const MySwal = withReactContent(Swal);

export const extractValueToArray = (values: FormObject): Array<any> => {
    if (values) {
        let newValues: Array<any> = [];
        for (const data in values) {
            newValues = [...newValues, values[data]]
        }
        return newValues;
    }
    return []
}

export const saveFormFunc = ({ formValues, currentUserDetails, setIsLoading, isNext = false, history, saveCallback, isNew }: SaveFormParams) => {
    if (!currentUserDetails || !formValues) {
        MySwal.fire({
            title: "Oops!",
            text: "Failed to load. Please contact support.",
            icon: "warning",
            confirmButtonColor: "#F9A000",
        });
        return;
    }
    const valuesArray = extractValueToArray(formValues);
    if (valuesArray.includes(null || undefined)) {
        MySwal.fire({
            title: "Oops!",
            text: "Please fill in at least one of the fields first.",
            icon: "warning",
            confirmButtonColor: "#F9A000",
        });
        return;
    }
    saveCallback({ formValues, currentUserDetails, setIsLoading, isNext, history, isNew });
};

export const editForm = ({ setIsEdit, history, routeName, isEdit = false }: EditFormsParams) => {
    setIsEdit(isEdit);
    history.push(`/client/${routeName}`);
};