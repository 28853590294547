import { FC, useState, useCallback, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import EasyEdit, { Types } from "react-easy-edit";
import withReactContent from "sweetalert2-react-content";
import { useDropzone } from "react-dropzone";

import { useHistory } from "react-router-dom";
import LoadingScreen from "../../components/shared/LoadingScreen/LoadingScreen";
import ProfileRelatedTo from "../../components/ProfileRelatedTo/ProfileRelatedTo";
import { ResumeFormsProps } from "../../interface/resume-form.interface";
import { InitResumePageDetails } from "../../services/form-hook-service";
import { UserResumeDetailsForms } from "../../types/form.types";
import { Loading } from "../../types/general.types";
import moment from "moment";
import "./Profile.scss";
import { getSkillRatingName } from "../../utilities/resume-page.utilities";
import EducationFormModal from "./EducationFormModal/EducationFormModal";
import ExpSummaryFormModal from "./ExpSummaryFormModal/ExpSummaryFormModal";
import SkillsFormModal from "./SkillsFormModal/SkillsFormModal";
import CertificateFormModal from "./CertificateFormModal/CertificateFormModal";
import ProExperienceFormModal from "./ProExperienceFormModal/ProExperienceFormModal";
import HoverActions from "./HoverActions/HoverActions";
import {
  Education,
  Certificate,
  ProfessionalExperience,
  ExperienceSummary,
  Skills,
  KeyAchievement,
} from "../../interface/page-two.interface";
import { DEFAULT_VALUES } from "../../constants/page-two-constants";
import ApplicantsService from "../../services/http/applicants.service";
import Swal from "sweetalert2";
import CandidateAssessment from "../../components/shared/CandidateAssessment/CandidateAssessment";
import PreferredShift from "../../components/shared/PreferredShift/PreferredShift";
import DesiredSalary from "../../components/shared/DesiredSalary/DesiredSalary";
import SuccessToast from "../../components/common/SuccessToast/SuccessToast";
import EditLogs from "../../components/shared/EditLogs/EditLogs";
import { USER_TYPE } from "../../constants/nav-constants";
import { AxiosResponse } from "axios";
import KeyAchievementModal from "./KeyAchievementModal/KeyAchievementModal";
import KeyAchievementSection from "./KeyAchievementSection/KeyAchievementSection";

const MySwal = withReactContent(Swal);

const Profile: FC<ResumeFormsProps> = ({ setIsEdit }) => {
  const history = useHistory();

  const userDetailsString = localStorage.getItem("userDetails") || ``;
  const userDetails = JSON.parse(userDetailsString);
  const userIdFromUrl =
    window.location.pathname.split("/").pop() !== `profile`
      ? window.location.pathname.split("/").pop()
      : ``;

  //FILE UPLOAD
  const [myFiles, setMyFiles] = useState<string[]>([]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        setMyFiles([...myFiles, ...acceptedFiles]);

        ApplicantsService.uploadCV({
          userId: userIdFromUrl || userDetails.userId,
          payload: acceptedFiles[0],
        }).then((res: any) => {
          MySwal.fire({
            title: "Success!",
            text: `CV has been successfully uploaded`,
            icon: "success",
            confirmButtonColor: "#F9A000",
          });
        });
      } else {
        MySwal.fire({
          title: "Oops!",
          text: `File is invalid`,
          icon: "error",
          confirmButtonColor: "#F9A000",
        });
      }
    },
    [myFiles, userDetails.userId, userIdFromUrl]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf,application/vnd.ms-excel",
    // maxFiles: 1,
    onDrop,
  });
  const files = acceptedFiles.map((file: any) => (
    <span key={file.path}>{file.path}</span>
  ));

  /** EDUCATION */
  const [showEducationModal, setShowEducatonModal] = useState(false);
  const [currentEducation, setCurrentEducation] = useState<Education>(
    DEFAULT_VALUES.EDUCATION
  );

  const handleCloseEducationModal = () => setShowEducatonModal(false);
  const handleShowEducationModal = (details: Education) => {
    setShowEducatonModal(true);
    setCurrentEducation(details);
  };

  /** SUMMARY */
  const [showExpSummaryModal, setShowExpSummaryModal] = useState(false);
  const [currentExpSummary, setCurrentExpSummary] = useState<ExperienceSummary>(
    DEFAULT_VALUES.EXPERIENCE_SUMMRARY
  );

  const handleCloseExpSummaryModal = () => setShowExpSummaryModal(false);
  const handleShowExpSummaryModal = (details: ExperienceSummary) => {
    setShowExpSummaryModal(true);
    setCurrentExpSummary(details);
  };

  /** KEY ACHIEVEMENT */
  const [showKeyAchievementModal, setShowKeyAchievementModal] = useState(false);
  const [currentKeyAchievement, setCurrentKeyAchievement] =
    useState<KeyAchievement>(DEFAULT_VALUES.EXPERIENCE_SUMMRARY);
  const handleCloseKeyAchievementModal = () =>
    setShowKeyAchievementModal(false);
  const handleShowKeyAchievementModal = (details: KeyAchievement) => {
    setShowKeyAchievementModal(true);
    setCurrentKeyAchievement(details);
  };

  /** SKILLS */
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [currentSkill, setCurrentSkill] = useState<Skills>(
    DEFAULT_VALUES.SKILL
  );

  const handleCloseSkillModal = () => setShowSkillModal(false);
  const handleShowSkillModal = (details: Skills) => {
    setShowSkillModal(true);
    setCurrentSkill(details);
  };

  /** CERTIFICATE */
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [currentCertificate, setCurrentCertificate] = useState<Certificate>(
    DEFAULT_VALUES.CERTIFICATE
  );

  const handleCloseCertificateModal = () => setShowCertificateModal(false);
  const handleShowCertificateModal = (details: Certificate) => {
    setShowCertificateModal(true);
    setCurrentCertificate(details);
  };

  /** PROFESSIONAL EXPERIENCE */
  const [showProExperienceModal, setShowProExperienceModal] = useState(false);
  const [currentProExperience, setCurrentProExperience] =
    useState<ProfessionalExperience>(DEFAULT_VALUES.PROFESSIONAL_EXPERIENCE);

  const handleCloseProExperienceModal = () => setShowProExperienceModal(false);
  const handleShowProExperienceModal = (details: ProfessionalExperience) => {
    setShowProExperienceModal(true);
    setCurrentProExperience(details);
  };

  const onSubmitCommonField = (entry, fieldType) => {
    if (fieldType === "userSkills") {
      const isInArray = userResumeDetails?.[fieldType].find((item: any) => {
        return (
          item.skillName.toLowerCase() === entry.skillName.toLowerCase() &&
          item.id !== entry.id
        );
      });
      if (isInArray) {
        MySwal.fire({
          title: "Oops!",
          text: "You already added this skill name.",
          icon: "error",
          confirmButtonColor: "#F9A000",
        });
        return false;
      }
    }

    const isInArray = userResumeDetails?.[fieldType].find((item: any) => {
      return item.id === entry.id;
    });
    const newDetails = isInArray
      ? userResumeDetails?.[fieldType].map((item: any) => {
          return item.id === entry.id ? entry : item;
        })
      : [...userResumeDetails?.[fieldType], entry];

    const newResume = userResumeDetails;
    newResume[fieldType] = newDetails;
    setUserResumeDetails({ ...userResumeDetails, [fieldType]: newDetails });
    handleCloseExpSummaryModal();
    handleCloseKeyAchievementModal();
    handleCloseEducationModal();
    handleCloseSkillModal();
    handleCloseCertificateModal();
    handleCloseProExperienceModal();

    // auto save
    updateUserDetails();
  };

  const handleDeleteField = (field: any, fieldType: string) => {
    const newFieldValue = userResumeDetails[fieldType].filter((item: any) => {
      return item.id !== field.id;
    });

    const newResume = userResumeDetails;
    newResume[fieldType] = newFieldValue;
    setUserResumeDetails({ ...userResumeDetails, [fieldType]: newFieldValue });
    updateUserDetails();
  };

  const downloadTemplateCV = (userId: string) => {
    ApplicantsService.downloadTemplateCVLink(userId);
  };

  const [userResumeDetails, setUserResumeDetails]: UserResumeDetailsForms =
    useState<any>(null);
  const [isPageLoading, setIsPageLoading]: Loading = useState<boolean>(false);
  InitResumePageDetails({
    setUserResumeDetails,
    setIsPageLoading,
    userId: userIdFromUrl,
  });

  const [logs, setLogs] = useState<any | null>([]);
  const [relatedOpportunities, setRelatedOpportunities] = useState<any | null>(
    []
  );
  const [assignedTo, setAssignedTo] = useState<any | null>([]);
  const [relatedHiringManagers, setRelatedHiringManagers] = useState<
    any | null
  >([]);
  const [communicationAssesmentId, setCommunicationAssesmentId] = useState<
    number | null
  >(0);
  const [knowledgeAssesmentId, setKnowledgeAssesmentId] = useState<
    number | null
  >(0);
  const [skillAssesmentId, setSkillAssesmentId] = useState<number | null>(0);
  const [desiredSalaryMin, setDesiredSalaryMin] = useState<number | null>(0);
  const [desiredSalaryMax, setDesiredSalaryMax] = useState<number | null>(0);
  const [preferredShiftId, setPreferredShiftId] = useState<number | null>(0);

  useEffect(() => {
    if (userResumeDetails?.adminAssignedId) {
      setAssignedTo([
        {
          id: userResumeDetails?.adminAssignedId,
          contactName: userResumeDetails?.adminAssignedToName,
        },
      ]);
    }

    setLogs(userResumeDetails?.logs);
    setRelatedOpportunities(userResumeDetails?.relatedOpportunities);
    setRelatedHiringManagers(userResumeDetails?.relatedHiringManagers);

    setCommunicationAssesmentId(
      userResumeDetails?.communicationAssesmentId || 0
    );
    setKnowledgeAssesmentId(userResumeDetails?.knowledgeAssesmentId || 0);
    setSkillAssesmentId(userResumeDetails?.skillAssesmentId || 0);

    if (userResumeDetails?.desiredSalaryMin)
      setDesiredSalaryMin(parseInt(userResumeDetails?.desiredSalaryMin, 10));
    if (userResumeDetails?.desiredSalaryMax)
      setDesiredSalaryMax(parseInt(userResumeDetails?.desiredSalaryMax, 10));
    setPreferredShiftId(userResumeDetails?.preferredShiftId || 0);
  }, [userResumeDetails]);

  const fullName =
    userResumeDetails?.userDetails?.firstName === `initial`
      ? `Input your name here`
      : `${userResumeDetails?.userDetails?.firstName} ${userResumeDetails?.userDetails?.lastName}`;
  const save = (value: String, type: String) => {
    const userDetails = userResumeDetails?.userDetails;

    if (type === `fullName`) {
      const theNames = value.split(" ");
      const lastName = theNames[theNames.length - 1];

      const lastIndex = value.lastIndexOf(" ");
      const firstName = value.substring(0, lastIndex);

      userDetails[`firstName`] = firstName;
      userDetails[`lastName`] = lastName;
    } else {
      userDetails[`${type}`] = value;
    }
    setUserResumeDetails({ ...userResumeDetails, userDetails });

    //auto save
    updateUserDetails();
  };

  const [showToast, setShowToast] = useState(false);
  // const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState(``);
  const toggleShowToast = (value: boolean = false, message: string = ``) => {
    setShowToast(value);
    if (value) {
      setToastMessage(message);
    }
  };

  const setDecIdToZero = () => {
    const userResumeDetailsCopy = userResumeDetails;
    const theKeys = [
      "experienceSummary",
      "keyAchievements",
      "professionalExperience",
      "certificate",
      "education",
      "userSkills",
    ];

    theKeys.forEach((theKey) => {
      userResumeDetailsCopy[theKey] = userResumeDetailsCopy[theKey].length
        ? userResumeDetailsCopy[theKey].map((item) => {
            if (!Number.isInteger(item.id)) {
              item.id = 0;
            }
            return item;
          })
        : [];
    });

    const newProps = {
      ...userResumeDetailsCopy,
      communicationAssesmentId,
      desiredSalaryMax,
      desiredSalaryMin,
      knowledgeAssesmentId,
      preferredShiftId,
      relatedHiringManagers,
      relatedOpportunities,
      skillAssesmentId,
      // adminAssignedId: assignedTo.length ? assignedTo[0].id : 0,
      // candidateStatusId: status.length ? status[0].id : 0,
    };
    return newProps;
  };

  const autoSaveProfile = () => {
    updateUserDetails();
  };

  const updateUserDetails = () => {
    ApplicantsService.updateUserDetails({
      userId: userResumeDetails.userDetails.userId,
      payload: setDecIdToZero(),
    }).then(() => {
      ApplicantsService.getUserDetails(
        userResumeDetails.userDetails.userId
      ).then((data: AxiosResponse) => {
        setUserResumeDetails(data.data);
      });
      showSuccessToast();
    });
  };

  const showSuccessToast = () => {
    toggleShowToast(true, "Profile successfully saved.");
  };

  const cancel = () => {
    console.log("Cancelled");
  };
  return (
    <>
      <SuccessToast
        showToast={showToast}
        toggleShowToast={toggleShowToast}
        toastMessage={toastMessage}
      ></SuccessToast>

      <EducationFormModal
        data={currentEducation}
        show={showEducationModal}
        onHide={handleCloseEducationModal}
        onSubmit={onSubmitCommonField}
      ></EducationFormModal>
      <ExpSummaryFormModal
        data={currentExpSummary}
        show={showExpSummaryModal}
        onHide={handleCloseExpSummaryModal}
        onSubmit={onSubmitCommonField}
      ></ExpSummaryFormModal>
      <KeyAchievementModal
        data={currentKeyAchievement}
        show={showKeyAchievementModal}
        onHide={handleCloseKeyAchievementModal}
        onSubmit={onSubmitCommonField}
      ></KeyAchievementModal>
      <SkillsFormModal
        data={currentSkill}
        show={showSkillModal}
        onHide={handleCloseSkillModal}
        onSubmit={onSubmitCommonField}
      ></SkillsFormModal>
      <CertificateFormModal
        data={currentCertificate}
        show={showCertificateModal}
        onHide={handleCloseCertificateModal}
        onSubmit={onSubmitCommonField}
      ></CertificateFormModal>
      <ProExperienceFormModal
        data={currentProExperience}
        show={showProExperienceModal}
        onHide={handleCloseProExperienceModal}
        onSubmit={onSubmitCommonField}
      ></ProExperienceFormModal>

      <LazyLoad>
        <Container>
          <div className="resume-details-container">
            <LoadingScreen isLoading={isPageLoading}>
              <Row className="mt-4 mx-0 px-0">
                <Col sm={3}>
                  {userDetails.role !== USER_TYPE.APPLICANT ? (
                    <Button
                      className="text-decoration-none text-primary"
                      variant="link"
                      onClick={() => history.goBack()}
                    >
                      <i
                        className="fa fa-angle-left fa-fw"
                        aria-hidden="true"
                      ></i>
                      Back to candidate list
                    </Button>
                  ) : (
                    <div className="header mt-3 mx-0">Profile</div>
                  )}
                </Col>
                <Col sm={9}>
                  <span className="float-end">
                    {acceptedFiles.length ? (
                      <Button
                        className="text-decoration-none text-danger file-cv"
                        variant="link"
                      >
                        <i
                          className="fa fa-fw fa-file-pdf-o"
                          aria-hidden="true"
                        ></i>{" "}
                        {files}
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      {...getRootProps({ className: "dropzone" })}
                      className="text-decoration-none text-success"
                      variant="link"
                    >
                      <input {...getInputProps()} />
                      Upload your CV here*
                    </Button>
                    {userDetails.role !== USER_TYPE.APPLICANT ? (
                      <Button
                        className="text-white"
                        variant="primary"
                        onClick={() =>
                          downloadTemplateCV(
                            userIdFromUrl || userDetails.userId
                          )
                        }
                      >
                        <i
                          className="fa fa-fw fa-download"
                          aria-hidden="true"
                        ></i>
                        Download Template CV
                      </Button>
                    ) : null}
                  </span>
                </Col>
              </Row>

              {userDetails.role === USER_TYPE.ADMIN ||
              userDetails.role === USER_TYPE.TECHNICAL ||
              userDetails.role === USER_TYPE.CRM ||
              userDetails.role === USER_TYPE.SUPER_ADMIN ? (
                <ProfileRelatedTo
                  relatedOpportunities={relatedOpportunities}
                  setRelatedOpportunities={setRelatedOpportunities}
                  updateUserDetails={autoSaveProfile}
                  setAssignedTo={setAssignedTo}
                  assignedTo={assignedTo}
                  id={userIdFromUrl || userDetails.userId}
                  currentUserDetails={userDetails}
                  type={`candidate`}
                  showSuccessToast={showSuccessToast}
                ></ProfileRelatedTo>
              ) : null}

              <div className="resume-panel-container">
                <Row>
                  <Col md={6}>
                    <div className="user-name-header">
                      <EasyEdit
                        type={Types.TEXT}
                        onSave={(value) => {
                          save(value, "fullName");
                        }}
                        onValidate={(value) => {
                          return value !== ``;
                        }}
                        saveOnBlur
                        value={fullName}
                        onCancel={cancel}
                        saveButtonLabel="✓"
                        cancelButtonLabel="✕"
                        hideSaveButton={true}
                        hideCancelButton={true}
                        placeholder="Input your name here"
                        attributes={{ name: "fullName" }}
                      />{" "}
                      &nbsp;
                    </div>

                    <div className="user-position mb-2">
                      <EasyEdit
                        type={Types.TEXT}
                        onSave={(value) => {
                          save(value, "desiredPosition");
                        }}
                        onValidate={(value) => {
                          return value !== ``;
                        }}
                        saveOnBlur
                        value={userResumeDetails?.userDetails?.desiredPosition}
                        onCancel={cancel}
                        saveButtonLabel="✓"
                        cancelButtonLabel="✕"
                        hideSaveButton={true}
                        hideCancelButton={true}
                        placeholder="Your Job Position"
                        attributes={{ name: "desiredPosition" }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="row justify-content-end">
                      <div className="col-lg-4">
                        <DesiredSalary
                          desiredSalaryMin={desiredSalaryMin}
                          setDesiredSalaryMin={setDesiredSalaryMin}
                          desiredSalaryMax={desiredSalaryMax}
                          setDesiredSalaryMax={setDesiredSalaryMax}
                          id={userIdFromUrl || userDetails.userId}
                          showSuccessToast={showSuccessToast}
                        ></DesiredSalary>
                      </div>
                      <div className="col-lg-4">
                        <PreferredShift
                          preferredShiftId={preferredShiftId}
                          setPreferredShiftId={setPreferredShiftId}
                          id={userIdFromUrl || userDetails.userId}
                          showSuccessToast={showSuccessToast}
                        ></PreferredShift>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="top-46 details-education">
                  <Col md={6}>
                    <div className="user-details-container">
                      <div className="user-details">
                        <EasyEdit
                          type={Types.TEXT}
                          onSave={(value) => {
                            save(value, "phoneNumber");
                          }}
                          onValidate={(value) => {
                            return value !== ``;
                          }}
                          saveOnBlur
                          value={userResumeDetails?.userDetails?.phoneNumber}
                          onCancel={cancel}
                          saveButtonLabel="✓"
                          cancelButtonLabel="✕"
                          hideSaveButton={true}
                          hideCancelButton={true}
                          placeholder="Contact Number"
                          attributes={{ name: "phoneNumber" }}
                        />
                      </div>
                      <div className="user-details">
                        <EasyEdit
                          type={Types.EMAIL}
                          onSave={(value) => {
                            save(value, "email");
                          }}
                          disabled
                          value={userResumeDetails?.userDetails?.email}
                          onCancel={cancel}
                          saveButtonLabel="✓"
                          cancelButtonLabel="✕"
                          hideSaveButton={true}
                          hideCancelButton={true}
                          allowEdit={false}
                          placeholder="Email"
                          attributes={{ name: "email" }}
                        />
                      </div>
                      <div className="user-details">
                        <EasyEdit
                          type={Types.TEXT}
                          onSave={(value) => {
                            save(value, "address");
                          }}
                          onValidate={(value) => {
                            return value !== ``;
                          }}
                          saveOnBlur
                          value={userResumeDetails?.userDetails?.address}
                          onCancel={cancel}
                          saveButtonLabel="✓"
                          cancelButtonLabel="✕"
                          hideSaveButton={true}
                          hideCancelButton={true}
                          placeholder="Address"
                          attributes={{ name: "address" }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className="px-0" md={6}>
                    <h5 className="section-title">Education</h5>
                    {userResumeDetails?.education.length ? (
                      userResumeDetails?.education.map(
                        (details: any, index: number) => (
                          <div
                            className="group-detail-hover-holder"
                            key={index}
                          >
                            <p className="details-title">
                              {details?.school}
                              <span className="float-end">
                                {moment(details?.dateStarted).format(
                                  "MMM YYYY"
                                )}{" "}
                                -{" "}
                                {moment(details?.dateGraduated).format(
                                  "MMM YYYY"
                                )}
                              </span>
                            </p>
                            <p className="details-subtitle">{details.course}</p>

                            <div className="detail-actions">
                              <HoverActions
                                dataList={userResumeDetails.education}
                                fieldType={"education"}
                                onDelete={handleDeleteField}
                                onEdit={handleShowEducationModal}
                                data={details}
                                onAdd={handleShowEducationModal}
                                defaultValue={DEFAULT_VALUES.EDUCATION}
                              ></HoverActions>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="d-grid gap-2">
                        <Button
                          variant="outline-primary"
                          onClick={() =>
                            handleShowEducationModal(DEFAULT_VALUES.EDUCATION)
                          }
                          className="text-decoration-none"
                        >
                          <i
                            className="fa fa-plus fa-fw mx-1"
                            aria-hidden="true"
                          ></i>
                          Add education
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="top-46 experience-summary">
                  <Col className="px-0">
                    <h5 className="section-title">Experience Summary</h5>
                    {userResumeDetails?.experienceSummary.length ? (
                      userResumeDetails?.experienceSummary.map(
                        (details: any, index: number) => (
                          <div
                            className="group-detail-hover-holder"
                            key={index}
                          >
                            <p className="details-description mt-0">
                              {details.summary}
                            </p>

                            <div className="detail-actions">
                              <HoverActions
                                dataList={userResumeDetails.experienceSummary}
                                fieldType={"experienceSummary"}
                                onDelete={handleDeleteField}
                                onEdit={handleShowExpSummaryModal}
                                data={details}
                                onAdd={handleShowExpSummaryModal}
                                defaultValue={
                                  DEFAULT_VALUES.EXPERIENCE_SUMMRARY
                                }
                              ></HoverActions>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="d-grid gap-2">
                        <Button
                          variant="outline-primary"
                          onClick={() =>
                            handleShowExpSummaryModal(
                              DEFAULT_VALUES.EXPERIENCE_SUMMRARY
                            )
                          }
                          className="text-decoration-none btn-block"
                        >
                          <i
                            className="fa fa-plus fa-fw mx-1"
                            aria-hidden="true"
                          ></i>
                          Add experience summary
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>

                <KeyAchievementSection
                  userResumeDetails={userResumeDetails}
                  handleDeleteField={handleDeleteField}
                  handleShowKeyAchievementModal={handleShowKeyAchievementModal}
                ></KeyAchievementSection>

                <Row className="top-46 professional-experience">
                  <Col xs={12} className="px-0">
                    <h5 className="section-title">Professional Experience</h5>
                  </Col>
                  {userResumeDetails?.professionalExperience.length ? (
                    userResumeDetails?.professionalExperience.map(
                      (details: any, index: number) => (
                        <Col md={6} className="px-0" key={index}>
                          <div className="group-detail-hover-holder">
                            <p className="details-title">
                              {details?.position}
                              <span className="float-end">
                                {moment(details?.dateStarted).format(
                                  "MMM YYYY"
                                )}{" "}
                                -{" "}
                                {details?.dateFinished
                                  ? moment(details?.dateFinished).format(
                                      "MMM YYYY"
                                    )
                                  : `Present`}
                              </span>
                            </p>
                            <p className="details-subtitle">
                              {details.company}
                            </p>
                            <p className="details-description">
                              {details?.jobDescription}
                            </p>
                            {details.stackUsed ? (
                              <p className="details-subtitle details-stack">
                                {" "}
                                <span className="text-muted">
                                  Technology Used: &nbsp;
                                </span>
                                {details.stackUsed
                                  .split(",")
                                  .map((item, index) => {
                                    return (
                                      <span
                                        className="badge rounded-pill bg-primary"
                                        key={index}
                                      >
                                        {item.trim()}
                                      </span>
                                    );
                                  })}
                              </p>
                            ) : (
                              ``
                            )}
                            <div className="detail-actions">
                              <HoverActions
                                dataList={
                                  userResumeDetails.professionalExperience
                                }
                                fieldType={"professionalExperience"}
                                onDelete={handleDeleteField}
                                onEdit={handleShowProExperienceModal}
                                data={details}
                                onAdd={handleShowProExperienceModal}
                                defaultValue={
                                  DEFAULT_VALUES.PROFESSIONAL_EXPERIENCE
                                }
                              ></HoverActions>
                            </div>
                          </div>
                        </Col>
                      )
                    )
                  ) : (
                    <Button
                      variant="outline-primary"
                      onClick={() =>
                        handleShowProExperienceModal(
                          DEFAULT_VALUES.PROFESSIONAL_EXPERIENCE
                        )
                      }
                      className="text-decoration-none"
                    >
                      <i
                        className="fa fa-plus fa-fw mx-1"
                        aria-hidden="true"
                      ></i>
                      Add professional experience
                    </Button>
                  )}
                </Row>
                <Row className="top-46 row-certificates">
                  <Col xs={12} className="px-0">
                    <h5 className="section-title">Certificates</h5>
                  </Col>
                  {userResumeDetails?.certificate.length ? (
                    userResumeDetails?.certificate.map(
                      (details: any, index: number) => (
                        <Col md={6} className="px-0" key={index}>
                          <div className="group-detail-hover-holder">
                            <p className="details-title">
                              {details?.company}
                              <span className="float-end">
                                {moment(details?.dateIssued).format("MMM YYYY")}
                              </span>
                            </p>
                            <p className="details-subtitle">
                              {details?.program}{" "}
                            </p>
                            {details?.jobDescription ? (
                              <p className="details-description">
                                {details?.jobDescription}
                              </p>
                            ) : (
                              ""
                            )}

                            <div className="detail-actions">
                              <HoverActions
                                dataList={userResumeDetails.certificate}
                                fieldType={"certificate"}
                                onDelete={handleDeleteField}
                                onEdit={handleShowCertificateModal}
                                data={details}
                                onAdd={handleShowCertificateModal}
                                defaultValue={DEFAULT_VALUES.CERTIFICATE}
                              ></HoverActions>
                            </div>
                          </div>
                        </Col>
                      )
                    )
                  ) : (
                    <Button
                      variant="outline-primary"
                      onClick={() =>
                        handleShowCertificateModal(DEFAULT_VALUES.CERTIFICATE)
                      }
                      className="text-decoration-none"
                    >
                      <i
                        className="fa fa-plus fa-fw mx-1"
                        aria-hidden="true"
                      ></i>
                      Add certificate
                    </Button>
                  )}
                </Row>
                <Row className="top-46 row-skills">
                  <Col xs={12} className="px-0">
                    <h5 className="section-title">Skills</h5>
                  </Col>
                  {userResumeDetails?.userSkills.length ? (
                    userResumeDetails?.userSkills.map(
                      (details: any, index: number) => (
                        <Col sm={6} md={3} className="px-0" key={index}>
                          <div className="group-detail-hover-holder">
                            <p className="details-title">
                              {details?.skillName}{" "}
                            </p>
                            <p className="details-subtitle">
                              {getSkillRatingName(details.skillLevel)}
                            </p>
                            <p className="details-description mt-0">
                              {details?.yearsOfExperience} year
                              {details?.yearsOfExperience !== 1 ? "s" : ""} of
                              experience
                            </p>

                            <div className="detail-actions">
                              <HoverActions
                                dataList={userResumeDetails.userSkills}
                                fieldType={"userSkills"}
                                onDelete={handleDeleteField}
                                onEdit={handleShowSkillModal}
                                data={details}
                                onAdd={handleShowSkillModal}
                                defaultValue={DEFAULT_VALUES.SKILL}
                              ></HoverActions>
                            </div>
                          </div>
                        </Col>
                      )
                    )
                  ) : (
                    <Button
                      variant="outline-primary"
                      onClick={() => handleShowSkillModal(DEFAULT_VALUES.SKILL)}
                      className="text-decoration-none"
                    >
                      <i
                        className="fa fa-plus fa-fw mx-1"
                        aria-hidden="true"
                      ></i>
                      Add skills
                    </Button>
                  )}
                </Row>
              </div>

              {userDetails.role !== USER_TYPE.APPLICANT ? (
                <div>
                  <CandidateAssessment
                    communicationAssesmentId={communicationAssesmentId}
                    setCommunicationAssesmentId={setCommunicationAssesmentId}
                    knowledgeAssesmentId={knowledgeAssesmentId}
                    setKnowledgeAssesmentId={setKnowledgeAssesmentId}
                    skillAssesmentId={skillAssesmentId}
                    setSkillAssesmentId={setSkillAssesmentId}
                    updateUserDetails={updateUserDetails}
                    id={userIdFromUrl || userDetails.userId}
                    showSuccessToast={showSuccessToast}
                  ></CandidateAssessment>
                  <EditLogs logs={logs}></EditLogs>
                </div>
              ) : null}
            </LoadingScreen>
          </div>
        </Container>
      </LazyLoad>
    </>
  );
};

export default Profile;
