import { useState, useEffect, useMemo } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "./Applicants.scss";
import { Redirect } from "react-router-dom";
import ApplicantsService from "../../services/http/applicants.service";
import { AxiosResponse } from "axios";
import Pagination from "../../components/Pagination/Pagination";
import { DEFAULT_PAGE_ROWS } from "../../constants/resume-forms-constants";
import TableToolTip2 from "../../components/shared/TableToolTip2/TableToolTip2";
import {
  USER_TYPE,
  DEFAULT_GRID_OPTION,
  DEFAULT_COL_DEF,
} from "../../constants/nav-constants";
import { AgGridReact } from "ag-grid-react";
import ApplicantTableActions from "../../components/shared/ApplicantTableActions/ApplicantTableActions";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import TableLinkRenderer from "../../components/shared/TableLinkRenderer/TableLinkRenderer";
import TableSearchBar from "../../components/shared/TableSearchBar/TableSearchBar";

const Applicants = () => {
  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);
  const [searchTerm, setSearchTerm] = useState("");
  const gridOptions = DEFAULT_GRID_OPTION;

  const defaultColDef = useMemo(() => {
    return DEFAULT_COL_DEF;
  }, []);

  const [columnDefs] = useState([
    { field: "fullName", headerName: `Name`, cellRenderer: TableLinkRenderer },
    { field: "phoneNumber" },
    { field: "email" },
    { field: "communication", width: 150 },
    { field: "knowledge", width: 125 },
    { field: "position" },
    {
      field: "salaryFormatted",
      headerName: `Salary`,
      width: 130,
    },
    { field: "engagement", width: 130 },
    { field: "preferredHours" },
    {
      field: "statuses",
      headerName: "Status",
      cellRenderer: TableToolTip2,
    },
    {
      field: "relatedOpportunities",
      cellRenderer: TableToolTip2,
      width: 240,
    },
    {
      field: "id",
      headerName: "",
      cellClass: "actions-button-cell",
      resizable: false,
      filter: false,
      width: 50,
      sortable: false,
      cellRenderer: ApplicantTableActions,
      cellRendererParams: { theUser },
    },
  ]);

  const [applicants, setApplicants] = useState<any[]>([]);
  const [SortingMode] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_ROWS);
  const [tableMeta, setTableMeta] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const formatSalary = (salary: string) => {
    if (!salary) return null;

    const minMax = salary.split("-");
    const min = minMax[0] && parseInt(minMax[0], 10).toLocaleString();
    const max = minMax[1] && parseInt(minMax[1], 10).toLocaleString();
    return `₱${min}-${max}`;
  };

  useEffect(() => {
    const mapApplicants = (applicants: any = []) => {
      return applicants.map((item) => {
        item.salaryFormatted = formatSalary(item.salary);
        item.fullName = `${item.firstName} ${item.lastName}`;
        return item;
      });
    };

    const getApplicants = (params: any = { PageSize: DEFAULT_PAGE_ROWS }) => {
      setIsLoading(true);
      ApplicantsService.getApplicantsDetails(params).then(
        (data: AxiosResponse) => {
          setIsLoading(false);
          if (data && data.data && data.data.data) {
            setTableMeta(data.data.meta);
            setApplicants(mapApplicants(data.data.data));
          }
        }
      );
    };

    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        console.log(searchTerm);

        getApplicants({
          SearchStr: searchTerm,
          PageSize: rowsPerPage,
          PageNumber: currentPage,
          SortingMode,
        });
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    } else {
      getApplicants({
        SearchStr: searchTerm,
        PageSize: rowsPerPage,
        PageNumber: currentPage,
        SortingMode,
      });
    }
  }, [currentPage, rowsPerPage, searchTerm, SortingMode]);

  return (
    <>
      {theUser.role === USER_TYPE.APPLICANT ? <Redirect to={`/profile`} /> : ``}

      <LazyLoad>
        <Container className="main-container" fluid>
          <Row>
            <Col>
              <TableSearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              ></TableSearchBar>
              <Card className="main-card main-card-with-search">
                <Card.Body className="p-0">
                  <div
                    className="ag-theme-alpine responsive table-bordered table-sm table-dp-small"
                    style={{ height: "80vh", width: "100%" }}
                  >
                    <AgGridReact
                      rowData={applicants}
                      gridOptions={gridOptions}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                    ></AgGridReact>
                  </div>

                  <Pagination
                    tableMeta={tableMeta}
                    isLoading={isLoading}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setRowsPerPage={setRowsPerPage}
                  ></Pagination>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </LazyLoad>
    </>
  );
};

export default Applicants;
