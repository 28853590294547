import "./VerificationMessage.scss";
import { FC } from 'react';
import envelopeImg from '../../assets/images/pooling_assets/envelope.png';
import { VerificationMessageProps } from "../../interface/verification.interface";

const VerificationMessage: FC<VerificationMessageProps> = ({ verificationMessage, verificationDescription }) => {
  if (verificationMessage && verificationDescription) {
    return (
      <div className="verification-message-container">
        <div className="verification-message-panel">
          <h5 className="panel-title">{verificationMessage}</h5>
          <img src={envelopeImg} alt="DP_ENVELOPE" />
        <p>{verificationDescription} {}</p>
        </div>
      </div>
    );
  }
  return <></>;
};

export default VerificationMessage;
