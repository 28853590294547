import "./SendForgotPassword.scss";
import LazyLoad from "react-lazyload";
import { Form, Button } from "react-bootstrap";
import DPLogo from "../../assets/images/pooling_assets/talentpool-logo.png";
import LoginService from "../../services/http/login.service";
import { AxiosError, AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";
import { Loading } from "../../types/general.types";
import { useState } from "react";
import { useQuery } from "../../services/router-service";
import PasswordStrengthBar from "react-password-strength-bar";

const SendForgotPassword = () => {
  const [isLoading, setIsLoading]: Loading = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [email, setEmail] = useState(``);
  const [password, setPassword] = useState(``);
  const [score, setScore] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState(``);
  const [errorMessage, setErrorMessage] = useState(``);
  const [successMessage, setSuccessMessage] = useState(``);
  const history = useHistory();

  const queryParams = useQuery();
  const theEmail = queryParams.get("email");
  const theToken = queryParams.get("token");
  const notMatchMessage = `Password and confirm password did not match.`;

  const submitLoginCreds = (e) => {
    e.preventDefault();
    if (email || password) {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setIsWarning(false);

      const params = {
        email: email || theEmail,
        newPassword: password,
        confirmPassword,
        token: theToken,
      };

      let theRequest;
      if (theToken) {
        if (password !== confirmPassword) {
          setIsError(true);
          setIsLoading(false);
          setErrorMessage(notMatchMessage);
          return;
        }
        if (score < 4) {
          setIsWarning(true);
          setIsLoading(false);
          return;
        }
        theRequest = LoginService.resetPassword(params);
      } else {
        theRequest = LoginService.forgotPassword(params);
      }
      theRequest
        .then((res: AxiosResponse) => {
          setIsLoading(false);
          setIsSuccess(true);
          if (theToken) {
            setSuccessMessage(
              `Password reset is successful. Use your new password to login to portal.`
            );
          } else {
            setSuccessMessage(
              `Please check your email. We have sent you a reset instruction.`
            );
          }
        })
        .catch((err: AxiosError) => {
          setIsLoading(false);
          setIsError(true);
          if (theToken) {
            setErrorMessage(`Token is invalid. Please try to request forgot password again.`);
          } else {
            setErrorMessage(`We couldn't find an account associated with ${email}. Please try
            with an alternate email.`);
          }
        });
    }
  };

  const emailChange = (e) => {
    setEmail(e.target.value);
    setIsError(false);
  };

  const scoreFeedback = (score, feedback) => {
    setScore(score);
  };

  return (
    <>
      <LazyLoad>
        <div className="login-container">
          <div className="content-container">
            {isError ? (
              <div className="alert alert-danger">{errorMessage}</div>
            ) : null}
            {isWarning ? (
              <div className="alert alert-warning">
                Please set a strong password
                <ul>
                  <li>At least one Upper case letter</li>
                  <li>At least one Lower case letter</li>
                  <li>At least one Number</li>
                  <li>Minimum of 9 characters</li>
                </ul>
              </div>
            ) : null}
            {isSuccess ? (
              <div className="alert alert-success">{successMessage}</div>
            ) : null}
            <div className="login-panel">
              <p className="text-center">
                <img src={DPLogo} alt="DP_LOGO" />
              </p>
              <h5 className="form-title">
                {theToken
                  ? `Set new password for ${theEmail}`
                  : `Forgot password?`}
              </h5>
              <form onSubmit={(e) => submitLoginCreds(e)}>
                <div className="form-wrapper">
                  {theToken ? (
                    <div>
                      <Form.Group className="mb-3">
                        <Form.Control
                          autoComplete="off"
                          type="password"
                          placeholder="Password"
                          value={password}
                          min={9}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <PasswordStrengthBar
                          onChangeScore={(score, feedback) =>
                            scoreFeedback(score, feedback)
                          }
                          password={password}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control
                          autoComplete="off"
                          type="password"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          min={9}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  ) : (
                    <Form.Group controlId="email" className="mb-3">
                      <Form.Label>Please enter your email.</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        value={email}
                        disabled={!!theToken}
                        onChange={(e) => emailChange(e)}
                      />
                    </Form.Group>
                  )}
                  <Form.Group className="d-grid gap-2">
                    <Button
                      type="submit"
                      disabled={isLoading}
                      className="text-white"
                    >
                      Reset Password
                    </Button>
                    {((theToken && isSuccess) || !theToken) ? 
                    
                    <Button
                      variant="link"
                      onClick={() => history.push("/login")}
                      className="text-decoration-none"
                    >
                      {theToken ? `Login` : `Back`}
                    </Button> : null
                  }
                  </Form.Group>
                </div>

                <div className="buttons-container"></div>
              </form>
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  );
};

export default SendForgotPassword;
