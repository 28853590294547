import { Card, Col, Container, Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "./Companies.scss";
import CompaniesService from "../../services/http/companies.service";

import { DEFAULT_PAGE_ROWS } from "../../constants/resume-forms-constants";
import { useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { Redirect } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import {
  USER_TYPE,
  DEFAULT_GRID_OPTION,
  DEFAULT_COL_DEF,
} from "../../constants/nav-constants";
import TableToolTip2 from "../../components/shared/TableToolTip2/TableToolTip2";
import CompanyTableActions from "../../components/shared/CompanyTableActions/CompanyTableActions";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import TableLinkRenderer from "../../components/shared/TableLinkRenderer/TableLinkRenderer";
import moment from "moment";
import { SETTING_INDEX } from "../../constants/select-option-constants";

const Companies = () => {
  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);
  const gridOptions = DEFAULT_GRID_OPTION;

  const defaultColDef = useMemo(() => {
    return DEFAULT_COL_DEF;
  }, []);

  const [columnDefs] = useState([
    { field: "companyName", width: 250, cellRenderer: TableLinkRenderer },
    { field: "phoneNumber", headerName: `Company Phone` },
    { field: "website" },
    { field: "address", width: 350 },
    {
      field: "relatedHiringManagers",
      cellRenderer: TableToolTip2,
    },
    {
      field: "relatedOpportunities",
      cellRenderer: TableToolTip2,
      width: 340,
    },
    { field: "dateCreatedFormatted", headerName: `Date Created` },
    { field: "dateModifiedFormatted", headerName: `Date Modified` },
    {
      field: "id",
      headerName: "",
      cellClass: "actions-button-cell",
      resizable: false,
      filter: false,
      width: 50,
      sortable: false,
      cellRenderer: CompanyTableActions,
      cellRendererParams: { theUser },
    },
  ]);

  const [tableData, setTableData] = useState<any[]>([]);
  const [searchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_ROWS);
  const [tableMeta, setTableMeta] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const theUserSettings = JSON.parse(
      localStorage.getItem("userSettings") || ``
    );
    const mapTableData = (tableData: any = []) => {
      return tableData.map((item) => {
        item.dateCreatedFormatted =
          item.dateCreated &&
          moment(item.dateCreated).format(
            theUserSettings[SETTING_INDEX.DATE_FORMAT].settingValue
          );
        item.dateModifiedFormatted =
          item.dateModified &&
          moment(item.dateModified).format(
            theUserSettings[SETTING_INDEX.DATE_FORMAT].settingValue
          );
        return item;
      });
    };
    const getApplicants = (params: any = { PageSize: DEFAULT_PAGE_ROWS }) => {
      setIsLoading(true);
      CompaniesService.getCompanies(params).then((data: AxiosResponse) => {
        setIsLoading(false);
        if (data && data.data && data.data.data) {
          setTableMeta(data.data.meta);
          setTableData(mapTableData(data.data.data));
        }
      });
    };

    getApplicants({
      SearchStr: searchText,
      PageSize: rowsPerPage,
      PageNumber: currentPage,
    });
  }, [currentPage, rowsPerPage, searchText]);

  return (
    <>
      {theUser.role === USER_TYPE.APPLICANT ? <Redirect to={`/profile`} /> : ``}

      <LazyLoad>
        <Container className="main-container" fluid>
          <Row>
            <Col>
              <Card className="main-card">
                <Card.Body className="p-0">
                  <div
                    className="ag-theme-alpine responsive table-bordered table-sm table-dp-small"
                    style={{ height: "80vh", width: "100%" }}
                  >
                    <AgGridReact
                      rowData={tableData}
                      gridOptions={gridOptions}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                    ></AgGridReact>
                  </div>

                  <Pagination
                    tableMeta={tableMeta}
                    isLoading={isLoading}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setRowsPerPage={setRowsPerPage}
                  ></Pagination>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </LazyLoad>
    </>
  );
};

export default Companies;
