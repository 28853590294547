import { AxiosResponse } from 'axios';
import { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import LazyLoad from 'react-lazyload';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Input from '../../../components/common/Input/Input';
import SelectInput from '../../../components/common/SelectInput/SelectInput';
import LoadingScreen from '../../../components/shared/LoadingScreen/LoadingScreen';
import { DEFAULT_USER_SKILLS_LIST, SKILLS_RATING_LIST } from '../../../constants/page-four-constants';
import { InitFormFourDetails } from '../../../custom-hooks/ResumeForms/page-four.hooks';
import { InitPageDetails } from '../../../custom-hooks/ResumeForms/resume.hooks';
import { ForthFormFormat, SubmitPageFourDetailsParams } from '../../../interface/page-four.interface';
import { SelectedSkillsFormat } from '../../../interface/page-three.interface';
import { PageParams } from '../../../interface/resume-form.interface';
import FourthFormService from '../../../services/fourth-form.service';
import { Loading } from '../../../types/general.types';
import { UserSkillsList } from '../../../types/page-three.types';
import { numberOnlyKeypress } from '../../../utilities/general.utilities';
import './PageFour.scss';

const MySwal = withReactContent(Swal);

const submitSkillsSelected = ({ data, currentUserDetails, setIsSubmitLoading, isNext, history, userSkills }: SubmitPageFourDetailsParams) => {
    if (data.userSkills.length && currentUserDetails && userSkills.length) {
        setIsSubmitLoading(true);
        const selectedSkillsFormat = data.userSkills.map((skillsDetails: ForthFormFormat) => {
            const otherDetails = userSkills.filter(otherDetail => Number(otherDetail.skillId) === Number(skillsDetails.skillId));
            return {
                id: otherDetails.length ? otherDetails[0].id : null,
                skillLevel: Number(skillsDetails.skillRate),
                yearsOfExperience: Number(skillsDetails.yearsOfExperience),
                userId: currentUserDetails.id,
                skillId: otherDetails.length ? otherDetails[0].skillId : null
            };
        });
        const payload = {
            userSkills: selectedSkillsFormat
        };
        FourthFormService.AddUpdateFormFourDetails({ userId: currentUserDetails.id, payload }).then((data: AxiosResponse) => {
            setIsSubmitLoading(false);
            if (data && data.data.success) {
                if (!isNext) {
                    MySwal.fire({
                        title: "Success!",
                        text: "Your changes has beeen saved.",
                        icon: "success",
                        confirmButtonColor: "#F9A000",
                    });
                    return;
                }
                if (history) {
                    history.push('/client/resume-details');
                }
            }
        }, () => {
            setIsSubmitLoading(false);
        });
    }
};

const PageFour: FC<PageParams> = ({ setPageNumber, resumeDetails, currentUserDetails }) => {
    const history = useHistory();
    InitPageDetails({ setPageNumber, resumeDetails });
    const [isLoading, setIsLoading]: Loading = useState<boolean>(false);
    const [isSubmitLoading, setIsSubmitLoading]: Loading = useState<boolean>(false);
    const [userSkills, setUserSkills]: UserSkillsList = useState<SelectedSkillsFormat[]>([]);
    const { formState, handleSubmit, control, getValues } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        shouldFocusError: true,
        defaultValues: {
            userSkills: [DEFAULT_USER_SKILLS_LIST]
        }
    });
    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'userSkills'
    });
    const isFormReady = (formState.isValid && !isSubmitLoading);
    const submitButtonDisableClass = isFormReady ? "" : "is-disabled";
    InitFormFourDetails({
        append,
        remove, 
        currentUserDetails, 
        setIsLoading, 
        setUserSkills
    });
    return (
        <>
            <LazyLoad>
                <LoadingScreen isLoading={isLoading}>
                    <div className="page-four-container">
                        <h5 className="category-title">Rate Skills</h5>
                        <form onSubmit={handleSubmit((details) => submitSkillsSelected({
                            data: details,
                            currentUserDetails,
                            setIsSubmitLoading,
                            history,
                            userSkills,
                            isNext: true
                        }))}>
                            {
                                fields.map((item, index) => (
                                    <div key={index} className="skills-container">
                                        <Controller
                                            control={control}
                                            name={`userSkills.${index}.skillId`}
                                            rules={{
                                                required: true
                                            }}
                                            render={({
                                                field
                                            }) => (
                                                <Input
                                                    name={field.name}
                                                    label={'Skill name'}
                                                    isDisabled={true}
                                                    placeholder={'Skill name'}
                                                    register={field}
                                                    isHidden={true}
                                                />
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name={`userSkills.${index}.skillName`}
                                            rules={{
                                                required: true
                                            }}
                                            render={({
                                                field
                                            }) => (
                                                <Input
                                                    name={field.name}
                                                    label={'Skill name'}
                                                    isDisabled={true}
                                                    placeholder={'Skill name'}
                                                    register={field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name={`userSkills.${index}.skillRate`}
                                            rules={{
                                                required: true,
                                                validate: (value) => Number(value) !== 0
                                            }}
                                            render={({
                                                field
                                            }) => (
                                                <SelectInput
                                                    name={field.name}
                                                    isRequired={true}
                                                    label={'Rate Skill'}
                                                    isDisabled={false}
                                                    optionsDetails={SKILLS_RATING_LIST}
                                                    register={field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name={`userSkills.${index}.yearsOfExperience`}
                                            rules={{
                                                required: true
                                            }}
                                            render={({
                                                field

                                            }) => (
                                                <Input
                                                    name={field.name}
                                                    label={'Years of experience'}
                                                    isRequired={true}
                                                    placeholder={'Years of experience'}
                                                    onKeyPressFunc={(event) => {
                                                        numberOnlyKeypress(event);
                                                    }}
                                                    register={field}
                                                />
                                            )}
                                        />
                                    </div>
                                ))
                            }
                            <div className="buttons-container">
                                <div className="resume-forms-container">
                                    <Button
                                        type="button"
                                        className={`save-button ${submitButtonDisableClass}`}
                                        onClick={() => submitSkillsSelected({
                                            data: getValues(),
                                            currentUserDetails,
                                            setIsSubmitLoading,
                                            history,
                                            userSkills,
                                            isNext: false
                                        })}
                                    >
                                        Save & Continue Later
                                    </Button>
                                    <Button
                                        type="submit"
                                        className={`submit-button ${submitButtonDisableClass}`}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </LoadingScreen>
            </LazyLoad>
        </>
    );
};

export default PageFour;
