import { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import VerificationMessage from '../../components/VerificationMessage/VerificationMessage';
import { VERIFIED, VERIFY } from '../../constants/verification-constants';
import { useQuery } from '../../services/router-service';
import './Verification.scss';
import { IsEmailAndTokenParams, VerificationDetails, VerificationParams } from '../../interface/verification.interface';
import { ValidationDetailsState } from '../../types/verification.types';
import VerificationService from '../../services/http/verification.service';
import { AxiosResponse } from 'axios';

const isEmailAndTokenParams = ({ queryParams, setValidationDetails }: IsEmailAndTokenParams): void => {
    const email = queryParams.get('email');
    const token = queryParams.get('token');
    if (email && token) {
        const params: VerificationParams = {
            email,
            token
        };
        VerificationService.verifyAccount(params).then((data: AxiosResponse) => {
            if (data && data.data) {
                setValidationDetails(VERIFIED);
            }
        }, error => {
            console.error(error);
        });
        return;
    }
    setValidationDetails(VERIFY);
};

const Verification = () => {
    const [mounted, setMounted] = useState(false);
    const queryParams = useQuery();
    const [validationDetails, setValidationDetails]: ValidationDetailsState = useState<VerificationDetails>(VERIFY);
    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            isEmailAndTokenParams({ queryParams, setValidationDetails });
        }
    }, [mounted, queryParams]);
    return (
        <>
            <LazyLoad>
                <div className="verification-container">
                    <h5 className="verification-title">JOIN OUR <span>CANDIDATES PORTAL</span></h5>
                    <div className="verification-body">
                        <VerificationMessage
                            verificationMessage={validationDetails.TITLE}
                            verificationDescription={validationDetails.DESCRIPTION}
                        />
                    </div>
                </div>
            </LazyLoad>
        </>
    );
};

export default Verification;