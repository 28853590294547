import { useHistory } from "react-router-dom";

const TableLinkRenderer = (props) => {
  const item = props.data;

  const history = useHistory();

  const goToLink = () => {
    if (item.engagement) {
      history.push(`/profile/${item.id}`);
    }
    if (item.companyName) {
      history.push(`/companies/${item.id}`);
    }
    if (item.hasOwnProperty("businessEmail")) {
      history.push(`/hiring-managers/${item.id}`);
    }
  };
  return (
    <>
      <span className="cursor-pointer" onClick={() => goToLink()}>
        {props.value}
      </span>
    </>
  );
};

export default TableLinkRenderer;
