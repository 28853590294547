import { Button, Card, Col, Container, Row, Form } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "./HiringManagersView.scss";
import HiringManagersService from "../../services/http/hiring-managers.service";
import SuccessToast from "../../components/common/SuccessToast/SuccessToast";

// import { DEFAULT_PAGE_ROWS } from "../../constants/resume-forms-constants";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Redirect, useHistory, useParams } from "react-router-dom";
import RelatedOpportunities from "../../components/shared/RelatedOpportunities/RelatedOpportunities";
import RelatedCompanies from "../../components/shared/RelatedCompanies/RelatedCompanies";
import RelatedCandidates from "../../components/shared/RelatedCandidates/RelatedCandidates";
import { USER_TYPE } from "../../constants/nav-constants";

const HiringManagersView = () => {
  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(``);
  const toggleShowToast = (value: boolean = false, message: string = ``) => {
    if (value) {
      setToastMessage(message);
    }
    setShowToast(value);
  };

  const params: any = useParams();
  // const [isLoading, setIsLoading] = useState(false);
  const [theData, setTheData] = useState<any>({});
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [name, setName] = useState(``);
  const [position, setPosition] = useState(``);
  const [team, setTeam] = useState(``);
  const [address, setAddress] = useState(``);
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState(``);
  const [businessEmail, setBusinessEmail] = useState(``);
  const [personalEmail, setPersonalEmail] = useState(``);
  const [relatedCompanies, setRelatedCompanies] = useState<any>([]);
  const [relatedOpportunities, setRelatedOpportunities] = useState<any>([]);
  const [relatedCandidates, setRelatedCandidates] = useState<any>([]);

  const resetButton = () => {
    setIsSaveLoading(false);
  };
  const updateDetails = (stringValue: any = ``) => {
    const theId = parseInt(params?.id, 10);
    const payload = {
      ...theData,
      id: theId || 0,
      name,
      position,
      team,
      businessEmail,
      personalEmail,
      businessPhoneNumber,
      address,
      relatedCompanies,
      relatedCandidates,
      relatedOpportunities,
      dateModified: new Date()
    };

    if (!stringValue || JSON.stringify(theData) === JSON.stringify(payload)) {
      return;
    }

    setTheData(payload);

    setIsSaveLoading(true);
    if (theId) {
      HiringManagersService.updateHiringManager(payload).then(
        ({ data }: AxiosResponse) => {
          showSuccessToast();
          setIsSaveLoading(false);
        },
        resetButton
      );
    } else {
      HiringManagersService.createHiringManager(payload).then(
        ({ data }: AxiosResponse) => {
          showSuccessToast();
          setIsSaveLoading(false);
          setTimeout(() => {
            history.push(`/hiring-managers/${data?.id}`);
          }, 0);
        },
        resetButton
      );
    }
  };

  useEffect(() => {
    const getData = () => {
      HiringManagersService.getHiringManager(params?.id).then(
        ({ data }: AxiosResponse) => {
          if (data) {
            setTheData(data);
            setName(data?.name);
            setPosition(data?.position);
            setTeam(data?.team);
            setPersonalEmail(data?.personalEmail);
            setAddress(data?.address);
            setBusinessPhoneNumber(data?.businessPhoneNumber);
            setBusinessEmail(data?.businessEmail);
            setRelatedCompanies(data?.relatedCompanies);
            setRelatedOpportunities(data?.relatedOpportunities);
            setRelatedCandidates(data?.relatedCandidates);
          }
        }
      );
    };
    if (parseInt(params?.id, 10)) {
      getData();
    } else {
      const query = new URLSearchParams(window.location.search);
      const theName = query.get("name") || ``;
      setName(theName);
    }
  }, [params?.id]);

  const showSuccessToast = () => {
    toggleShowToast(true, "Hiring manager successfully saved.");
  };

  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  return (
    <>
      {theUser.role === USER_TYPE.APPLICANT ? (
        <Redirect to={`/profile`} />
      ) : (
        ``
      )}

      <SuccessToast
        showToast={showToast}
        toggleShowToast={toggleShowToast}
        toastMessage={toastMessage}
      ></SuccessToast>
      <LazyLoad>
        <Container className="main-container">
          <Row className="mt-5 pt-4 mx-0 px-0">
            <Col sm={3} className="align-self-center">
              {parseInt(params.id, 10) === 0 ? (
                <h5 className="my-1">Add Hiring Manager</h5>
              ) : (
                <Button
                  className="text-decoration-none text-primary"
                  variant="link"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-angle-left fa-fw" aria-hidden="true"></i>
                  Back to hiring managers list
                </Button>
              )}
            </Col>
            {isSaveLoading ? (
              <Col sm={9}>
                <span className="float-end py-1">
                  <i className="fa fa-fw fa-spinner fa-spin" aria-hidden="true"></i>
                  Saving Changes...
                </span>
              </Col>
            ) : (
              ``
            )}
          </Row>

          <Row>
            <Col>
              <Card className="main-card px-lg-5 mb-4">
                <Card.Body className="px-md-5 py-md-5">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">Name</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Name"
                          value={name}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => {
                            updateDetails(e.target.value);
                          }}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">Team</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Team"
                          value={team}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => {
                            updateDetails(e.target.value);
                          }}
                          onChange={(e) => {
                            setTeam(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">Position</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Position"
                          value={position}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => {
                            updateDetails(e.target.value);
                          }}
                          onChange={(e) => {
                            setPosition(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">
                          Business Email
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Business Email"
                          value={businessEmail}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => {
                            updateDetails(e.target.value);
                          }}
                          onChange={(e) => {
                            setBusinessEmail(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">
                          Personal Email
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Personal Email"
                          value={personalEmail}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => {
                            updateDetails(e.target.value);
                          }}
                          onChange={(e) => {
                            setPersonalEmail(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">
                          Business Phone
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Business Phone"
                          value={businessPhoneNumber}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => {
                            updateDetails(e.target.value);
                          }}
                          onChange={(e) => {
                            setBusinessPhoneNumber(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">Address</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Address"
                          value={address}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => {
                            updateDetails(e.target.value);
                          }}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={3} className="offset-lg-3">
                      <Form.Group className="mt-3 mb-5">
                        <RelatedCompanies
                          relatedCompanies={relatedCompanies}
                          setRelatedCompanies={setRelatedCompanies}
                          id={params?.id}
                          type={`hiring-manager`}
                          showSuccessToast={showSuccessToast}
                        ></RelatedCompanies>
                      </Form.Group>
                      <Form.Group className="mt-3 mb-5">
                        <RelatedOpportunities
                          updateUserDetails={``}
                          relatedOpportunities={relatedOpportunities}
                          setRelatedOpportunities={setRelatedOpportunities}
                          id={params?.id}
                          type={`hiring-manager`}
                          showSuccessToast={showSuccessToast}
                        ></RelatedOpportunities>
                      </Form.Group>
                      <Form.Group className="mt-3 mb-5">
                        <RelatedCandidates
                          relatedCandidates={relatedCandidates}
                          setRelatedCandidates={setRelatedCandidates}
                          id={params?.id}
                          type={`hiring-manager`}
                          showSuccessToast={showSuccessToast}
                        ></RelatedCandidates>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

        </Container>
      </LazyLoad>
    </>
  );
};

export default HiringManagersView;
