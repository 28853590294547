import { useRef, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import "./TableSearchBar.scss";

const TableSearchBar = ({ searchTerm, setSearchTerm }) => {
  const elementVariable = useRef(null);
  const [showSearch, setShowSearch] = useState(false);

  const changeFocusSearch = () => {
    setShowSearch(true);
  };

  return (
    <div>
      <Row>
        <Col className="col-md-8 offset-md-2">
          <Card className="mt-3">
            <Card.Body
              ref={elementVariable}
              tabIndex={0}
              onBlur={() => setShowSearch(false)}
              onFocus={() => changeFocusSearch()}
              className={`p-1 card-search ${
                searchTerm && showSearch ? "search-on" : ""
              }`}
            >
              <Form autoComplete={"off"}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control
                    type="search"
                    size="lg"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Need help? Find what you’re looking for."
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TableSearchBar;
