import { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import "./SkillsFormModal.scss";
import { Skills } from "../../../interface/page-two.interface";
import { SKILLS_RATING_LIST } from '../../../constants/page-four-constants';
import ApplicantsService from '../../../services/http/applicants.service';
interface SkillsFormModalProps {
  show: boolean, onHide: any, onSubmit: any, data: Skills
}
const SkillsFormModal = ({ show, onHide, data, onSubmit }: SkillsFormModalProps) => {
  const [yearsOfExperience, setYearsOfExperience] = useState<number>(1);
  const [skillLevel, setSkillLevel] = useState(0);
  const [skillName, setSkillName] = useState(``);

  const onSubmitForm = (e) => {
    e.preventDefault();
    const form = {id: data.id || ApplicantsService.getRandomDecimal(), yearsOfExperience, skillLevel, skillName};
    onSubmit(form, `userSkills`);
  };

  useEffect(() => {
    const { yearsOfExperience, skillLevel, skillName} = data;
    setYearsOfExperience(yearsOfExperience);
    setSkillLevel(skillLevel);
    setSkillName(skillName);
  }, [data]);

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>

        <Form onSubmit={onSubmitForm}>
          <Modal.Body>
            <h5>{data?.id ? `Update` : `Add`} Skill</h5>

            <Row>
              <Col lg={4}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Skill name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control type="text" required placeholder="Skill name" value={skillName} onChange={(e) => { setSkillName(e.target.value)}} />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Rate skill <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control as="select" required value={skillLevel} onChange={(e) => { setSkillLevel(parseInt(e.target.value))}}>
                    {SKILLS_RATING_LIST.map(opt => (
                      <option value={opt.id} key={opt.id}>{opt.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Years of experience <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control type="number" required value={yearsOfExperience} onChange={(e) => { setYearsOfExperience(parseInt(e.target.value))}} placeholder="Years of experience" />
                </Form.Group>
              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-primary text-decoration-none"
              onClick={onHide}
            >
              Close
            </Button>
            <Button type='submit' variant="primary" className="text-white">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default SkillsFormModal;
