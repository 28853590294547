import { AxiosPromise, AxiosResponse } from 'axios';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import http from '../../http-common';
import { DesiredSalaryParams, PreferredShiftParams, RelatedToParams } from "../../interface/form.interface";
const MySwal = withReactContent(Swal);

const getApplicantsDetails = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.get('/Admin/applicants', { params });
};

const getApplicantsStatuses = (): AxiosPromise<AxiosResponse> => {
  return http.get('/Forms/candidate-status');
};

const deleteApplicant = (userId: string): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Admin/applicants/${userId}`);
};

const getUserRoles = (): AxiosPromise<AxiosResponse> => {
  return http.get('/Admin/roles');
};

const getAdminsDetails = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.get('/Admin/admins', { params });
};

const setAdminsDetails = (payload: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post('/Admin/admins', payload);
}

const updateAdminsDetails = (id: string, payload: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`/Admin/admins/${id}`, payload);
};

const getAdminLogs = (userId: string, params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.get(`/Admin/admins/${userId}/logs`, { params });
};

const getApplicantLogs = (userId: string, params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.get(`/Admin/applicants/${userId}/logs`, { params });
};

const getSearch = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.get(`/Contact/search`, { params });
};

const deleteAdmin = (userId: string): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Admin/admins/${userId}`);
};

const resetAdminPassword = (userId: string): AxiosPromise<AxiosResponse> => {
  return http.post(`/Admin/admins/${userId}/reset-password`);
};

const resetApplicantPassword = (userId: string): AxiosPromise<AxiosResponse> => {
  return http.post(`/Admin/applicants/${userId}/reset-password`);
};

const setPassword = (payload: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post('/Users/changePassword', payload);
};

const downloadTemplateCV = (id: string): AxiosPromise<AxiosResponse> => {
  return http.get(`/Admin/applicants/${id}/download-template-cv`, { responseType: 'blob' });
};

const downloadUploadedCV = (id: string): AxiosPromise<AxiosResponse> => {
  return http.get(`/Admin/applicants/${id}/download-uploaded-cv`, { responseType: 'blob' });
};

const getUserDetails = (userId: string) => {
  return http.get(`Forms/user/${userId}`);
};

const updateUserDetails = ({ userId, payload }): AxiosPromise<AxiosResponse> => {
  return http.post(`Forms/user/${userId}`, payload);
};

const updateOpportunityStatus = ({ userId, opportunityId, payload }): AxiosPromise<AxiosResponse> => {
  return http.post(`Forms/user/${userId}/relatedOpportunity/${opportunityId}/status`, payload);
};

const uploadCV = ({ userId, payload }): AxiosPromise<AxiosResponse> => {
  const formdata = new FormData();
  formdata.append("File", payload);

  return http.post(`Forms/user/${userId}/cv-upload`, formdata, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
};

const getPreferredShift = (): AxiosPromise<AxiosResponse> => {
  return http.get(`/Forms/preferred-shifts`);
};

const createOpportunity = (userId: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/relatedOpportunity`, params);
};

const removeOpportunity = (userId: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Forms/user/${userId}/relatedOpportunity`, { data: params });
};

const createHiringManager = (userId: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/relatedHiringManager`, params);
};

const removeHiringManager = (userId: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Forms/user/${userId}/relatedHiringManager`, { data: params });
};

const candidateStatus = (userId: string, params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/candidateStatus`, params);
};

const adminAssignedTo = (userId: string, params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/adminAssignedTo`, params);
};

const communicationAssesment = (userId: string, params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/communicationAssesment`, params);
};

const knowledgeAssesment = (userId: string, params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/knowledgeAssesment`, params);
};

const skillAssesment = (userId: string, params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/skillAssesment`, params);
};

const desiredSalary = (userId: string, params: DesiredSalaryParams): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/desiredSalary`, params);
};

const preferredShift = (userId: string, params: PreferredShiftParams): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/preferredShift`, params);
};

const addEducation = (userId: string, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/education`, params);
};
const updateEducation = (userId: string, id: any, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/education/${id}`, params);
};
const deleteEducation = (userId: string, id: any): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Forms/user/${userId}/education/${id}`);
};

const addExperienceSummary = (userId: string, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/experience-summary`, params);
};
const updateExperienceSummary = (userId: string, id: any, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/experience-summary/${id}`, params);
};
const deleteExperienceSummary = (userId: string, id: any): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Forms/user/${userId}/experience-summary/${id}`);
};

const addProfessionalExperience = (userId: string, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/professional-experience`, params);
};
const updateProfessionalExperience = (userId: string, id: any, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/professional-experience/${id}`, params);
};
const deleteProfessionalExperience = (userId: string, id: any): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Forms/user/${userId}/professional-experience/${id}`);
};

const addCertificate = (userId: string, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/certificates`, params);
};
const updateCertificate = (userId: string, id: any, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/certificates/${id}`, params);
};
const deleteCertificate = (userId: string, id: any): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Forms/user/${userId}/certificates/${id}`);
};

const addSkill = (userId: string, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/skills`, params);
};
const updateSkill = (userId: string, id: any, params: any): AxiosPromise<AxiosResponse> => {
  return http.post(`/Forms/user/${userId}/skills/${id}`, params);
};
const deleteSkill = (userId: string, id: any): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Forms/user/${userId}/skills/${id}`);
};

const getRandomDecimal = () => {
  return Math.floor(Math.random() * (1000 - 100) + 100) / 100;
  // min = Math.ceil(min);
  // max = Math.floor(max);
  // return Math.floor(Math.random() * (max - min + 1)) + min;
}

const downloadTemplateCVLink = (userId: string) => {
  downloadTemplateCV(userId)
    .then((response: any) => {
      if (response.status === 200) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = `_blank`;
        // link.download = `${userId}template-cv.pdf`;
        link.click();
      }
    })
    .catch(() => {
      MySwal.fire({
        title: "Yikes!",
        text: "Something wrong.",
        icon: "error",
        confirmButtonColor: "#F9A000",
      });
    });
};

const downloadUploadedCVLink = (userId: string) => {
  downloadUploadedCV(userId)
    .then((response: any) => {
      if (response.status === 200) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = `_blank`;
        // link.download = `${userId}uploaded-cv.pdf`;
        link.click();
      }
    })
    .catch(() => {
      MySwal.fire({
        title: "Yikes!",
        text: "No uploaded CV for this user yet.",
        icon: "error",
        confirmButtonColor: "#F9A000",
      });
    });
};



const ApplicantsService = {
  getApplicantsDetails,
  getAdminsDetails,
  getApplicantsStatuses,
  deleteApplicant,
  getUserRoles,
  deleteAdmin,
  setAdminsDetails,
  updateAdminsDetails,
  getAdminLogs,
  getApplicantLogs,
  getSearch,
  resetAdminPassword,
  resetApplicantPassword,
  setPassword,
  downloadTemplateCV,
  downloadUploadedCV,
  getUserDetails,
  updateUserDetails,
  updateOpportunityStatus,
  getRandomDecimal,
  downloadTemplateCVLink,
  downloadUploadedCVLink,
  uploadCV,
  getPreferredShift,
  addEducation,
  updateEducation,
  deleteEducation,
  addExperienceSummary,
  updateExperienceSummary,
  deleteExperienceSummary,
  addProfessionalExperience,
  updateProfessionalExperience,
  deleteProfessionalExperience,
  addCertificate,
  updateCertificate,
  deleteCertificate,
  addSkill,
  updateSkill,
  deleteSkill,
  createOpportunity,
  removeOpportunity,
  createHiringManager,
  removeHiringManager,
  candidateStatus,
  adminAssignedTo,
  communicationAssesment,
  knowledgeAssesment,
  skillAssesment,
  desiredSalary,
  preferredShift,
};

export default ApplicantsService;