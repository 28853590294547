import { Card, Col, Container, Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "./ReferFriend.scss";

const ReferFriend = () => {
  return (
    <>
      <LazyLoad>
        <div className="verification-container">
          <h5 className="verification-title">
            REFER-A-FRIEND <span>PROGRAM</span>
          </h5>
          <Container>
            <div className="verification-body">
              <Row className="justify-content-evenly">
                <Col className="col-lg-8">
                  <Card>
                    <Card.Body>
                      <h5>Refer-A-Friend Program</h5>
                      <p className="text-justified">
                        The Program is designed to expand the Dev Partners
                        network in acquiring new qualified candidates through
                        the help of internal and external partners.
                      </p>
                      <p className="text-justified">
                        Refer-A-Friend Program is open for all, may it be from
                        the Dev Partners Community or from other channels who
                        have a vast network of developers and programmers,
                        designers, and other technical skills.
                      </p>

                      <p className="text-justified">
                        All endorsements/submissions can be uploaded to the ATS
                        (BreezyHR) via the portal using the Submit Referral
                        option corresponding to each active position. All
                        referral will undergo the Dev Partners recruitment
                        process and will be subject to review by our internal
                        recruitment team for duplicates and quality. An email
                        will be sent to the referee regarding the status of
                        his/her referral.
                      </p>
                      <p className="text-justified">
                        In case the candidate has been identified as duplicate
                        in our ATS and was uploaded first prior to the
                        endorsement made by the referee, the candidate will be
                        tagged as sourced by the internal team and will not be
                        tagged under the referee.
                      </p>

                      <h5>Payment Terms</h5>
                      <p className="text-justified">
                        The referral bonus for every successfully hired
                        candidate is Twenty Thousand Pesos (P20,000.00). This
                        will be granted to the referee who initially endorsed
                        the candidate. The successful applicant must be a first
                        time hire of Dev Partners to be eligible for the
                        mentioned commission amount.
                      </p>

                      <p className="text-justified">
                        Referral bonus or cash reward will be credited to the
                        referee through bank transfer or ewallet (Gcash or
                        Paymaya). Referee will be contacted by Dev Partners once
                        a referral has been hired.
                      </p>

                      <h5>Timeline and Payout</h5>
                      <p className="text-justified">
                        The first half of the amount mentioned above (P10,000)
                        shall be credited after the 1st month and the other half
                        (P10,000) will be released after the 3rd month of the
                        new hire. It will be credited to the nearest payout
                        schedule that falls every 5th and 20th of the month.
                      </p>
                      <p className="text-justified">
                        In any case, if the new hire resigns within 1 month from
                        date of deployment, the referral incentive will then be
                        forfeited.
                      </p>

                      <h5>Eligibility</h5>
                      <p className="text-justified">
                        Refer a friend program is available for everyone, may it
                        be from the hired/deployed staff of Dev Partners or from
                        other external groups and networks.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </LazyLoad>
    </>
  );
};

export default ReferFriend;
