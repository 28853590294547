import "./PreferredShift.scss";
import { Dropdown } from "react-bootstrap";
import ApplicantsService from "../../../services/http/applicants.service";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

const PreferredShift = ({
  preferredShiftId,
  setPreferredShiftId,
  id,
  showSuccessToast,
}) => {
  const [result, setResult] = useState<any[]>([]);
  const [preferredShiftName, setPreferredShiftName] = useState(``);

  useEffect(() => {
    const theName = result.find(
      (item) => item.id === preferredShiftId
    )?.shiftName;
    setPreferredShiftName(theName);
  }, [preferredShiftId, result, id, showSuccessToast]);

  const saveChanges = (shiftId: number) => {
    setPreferredShiftId(shiftId);
    ApplicantsService.preferredShift(id, {preferredShiftId: shiftId}).then(() => {
      if(showSuccessToast) showSuccessToast();
    });
  } 

  useEffect(() => {
    const getPreferredShift = () => {
      ApplicantsService.getPreferredShift().then((data: AxiosResponse) => {
        if (data) {
          setResult(data.data);
        }
      });
    };

    getPreferredShift();
  }, []);

  return (
    <>
      <span className="small text-muted-af">Preferred Shift</span> <br />
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          className="badge badge-wide rounded-pill bg-primary"
        >
          {preferredShiftName || `Select Preferred Shift`}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {result &&
            result.map((item, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  active={item.id === preferredShiftId}
                  onClick={() => {
                    saveChanges(item.id)
                  }}
                >
                  {item.shiftName}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default PreferredShift;
