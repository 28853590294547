import { ComponentType, FC } from "react";
import { SelectOptions } from "../../interface/select-options.interface";

const SelectOptionsList =
  <P extends object>(
    SelectOptionsComp: ComponentType<SelectOptions>,
  ): FC<P & SelectOptions> =>
    ({ optionsList }) => {
      if (optionsList && optionsList.length) {
        return <SelectOptionsComp optionsList={optionsList} />;
      } 
      return <></>;
    };

export default SelectOptionsList;
