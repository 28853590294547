import { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import "./NewAdminFormModal.scss";
import { AdminProfile } from "../../../interface/page-two.interface";
import ApplicantsService from "../../../services/http/applicants.service";

interface NewAdminFormModalProps {
  show: boolean;
  onHide: any;
  onSubmit: any;
  data: AdminProfile;
  userRoles: any;
}
const NewAdminFormModal = ({
  show,
  onHide,
  data,
  onSubmit,
  userRoles,
}: NewAdminFormModalProps) => {
  const [firstName, setFirstName] = useState(``);
  const [lastName, setLastName] = useState(``);
  const [email, setEmail] = useState(``);
  const [roleId, setRoleId] = useState(``);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = {
      ...data,
      firstName,
      lastName,
      email,
      roleId: roleId,
      password: randomPassword(),
    };

    if (data.id) {
      ApplicantsService.updateAdminsDetails(data.id, form).then((res) => {
        setIsLoading(false);
        onSubmit(res);
      });
    } else {
      ApplicantsService.setAdminsDetails(form).then((res) => {
        setIsLoading(false);
        onSubmit(res);
      });
    }
  };

  const randomPassword = () => {
    return Math.random().toString(36).slice(-10);
  };

  const handleSelectChange = (event) => {
    setRoleId(event.target.value);
  };

  useEffect(() => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);

    const roleId = userRoles.find((item) => item.roleName === data?.role);
    setRoleId(roleId?.id || ``);
  }, [data, userRoles]);

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>
        <Form onSubmit={onSubmitForm}>
          <Modal.Body>
            <h5>{data?.id ? `Update` : `Add`} Admin</h5>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>
                First name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                placeholder="First name"
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>
                Last name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                placeholder="Last name"
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>
                Email <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                required
                disabled={!!data?.id}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>
                Role <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select value={roleId} onChange={handleSelectChange}>
                {userRoles.map((option, index) => {
                  return (
                    <option key={index} value={option.id}>
                      {option.roleName}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-primary text-decoration-none"
              onClick={onHide}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={isLoading}
              className="text-white"
            >
              {isLoading ? `Saving...` : `Save Changes`}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default NewAdminFormModal;
