import "./RelatedCandidates.scss";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import ApplicantsService from "../../../services/http/applicants.service";
import { AxiosResponse } from "axios";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import {
  MAX_REQUEST,
  RELATION_TYPE,
} from "../../../constants/resume-forms-constants";
import HiringManagersService from "../../../services/http/hiring-managers.service";
import OpportunitiesService from "../../../services/http/opportunities.service";
import { confirmDelete } from "../../../services/confirm-delete.service";

const RelatedCandidates = ({
  relatedCandidates,
  setRelatedCandidates,
  id,
  type,
  showSuccessToast,
}) => {
  const history = useHistory();
  const [searchText] = useState("");
  const [singleSelections] = useState([]);
  const [result, setResult] = useState<any[]>([]);

  useEffect(() => {
    const mapData = (data) => {
      return data.map((item) => {
        item.candidatesName = `${item.firstName} ${item.lastName}`;
        return item;
      });
    };
    const getApplicants = (params: any) => {
      ApplicantsService.getApplicantsDetails(params).then(
        (data: AxiosResponse) => {
          if (data && data.data && data.data.data) {
            setResult(mapData(data.data.data));
          }
        }
      );
    };

    getApplicants({
      SearchStr: searchText,
      PageSize: MAX_REQUEST,
      PageNumber: 1,
    });
  }, [searchText]);

  const addSelected = (theSelected: any = []) => {
    if (
      theSelected.length &&
      theSelected[theSelected.length - 1].candidatesName &&
      !theSelected[theSelected.length - 1].customOption
    ) {
      const theSelectedName = theSelected.length
        ? theSelected[theSelected.length - 1].candidatesName
        : ``;

      const trimStack = theSelectedName.trim() || ``;
      if (theSelected) {
        const isAlreadySelected = relatedCandidates.filter(
          (item) => item.contactName === trimStack
        );

        if (!isAlreadySelected.length) {
          const newItem = {
            id: theSelected[0]?.id,
            contactName: theSelected[0]?.candidatesName,
          };
          setRelatedCandidates([...relatedCandidates, newItem]);
          saveChanges(newItem);
        }
      }
    }
  };

  const saveChanges = (data: any) => {
    let theObsrvable: any = "";
    switch (type) {
      case RELATION_TYPE.OPPORTUNITY:
        theObsrvable = OpportunitiesService.createCandidate(id, data);
        break;
      case RELATION_TYPE.HIRING_MANAGER:
        theObsrvable = HiringManagersService.createCandidate(id, data);
        break;
      default:
        break;
    }
    if (showSuccessToast) {
      theObsrvable.then(() => {
        showSuccessToast();
      });
    }
  };

  const removeCandidate = (params: any = {}) => {
    confirmDelete(`related candidate`).then((res) => {
      if (res.isConfirmed) {
        deleteNow(params);
      }
    });
  };

  const deleteNow = (params: any = {}) => {
    let theObsrvable: any = "";
    switch (type) {
      case RELATION_TYPE.OPPORTUNITY:
        theObsrvable = OpportunitiesService.removeCandidate(id, params);
        break;
      case RELATION_TYPE.HIRING_MANAGER:
        theObsrvable = HiringManagersService.removeCandidate(id, params);
        break;
      default:
        break;
    }

    const filtered = relatedCandidates.filter((item) => {
      return item.id !== params.id;
    });
    setRelatedCandidates(filtered);
    if (showSuccessToast) {
      theObsrvable.then(() => {
        showSuccessToast();
      });
    }
  };

  return (
    <>
      <Form autoComplete="false">
        <Form.Group>
          <Form.Label className="text-strong">
            <i className="bi bi-person lead fa fa-fw"></i>
            Related Candidates{" "}
          </Form.Label>
          <div className="text-info mb-2">
            <ul className="list-unstyled my-0">
              {relatedCandidates && relatedCandidates.length
                ? relatedCandidates.map((data, index) => {
                    return (
                      <li className="mb-1" key={index}>
                        <span
                          className="cursor-pointer text-danger"
                          onClick={() => {
                            removeCandidate(data);
                          }}
                          title={`Remove ${data?.contactName}`}
                        >
                          &times;
                        </span>
                        <span
                          className="cursor-pointer"
                          title={`View ${data?.contactName}`}
                          onClick={() => history.push(`/profile/${data.id}`)}
                        >
                          {data?.contactName}
                          {/* {index + 1 < relatedCandidates.length ? `, ` : ``}{" "} */}
                        </span>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>

          <Typeahead
            id="basic-typeahead-candidates"
            labelKey="candidatesName"
            minLength={1}
            onChange={(e) => {
              addSelected(e);
            }}
            className="input-line-typeahead"
            options={result}
            placeholder="Add Related Candidates"
            selected={singleSelections}
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default RelatedCandidates;
