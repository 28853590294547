import { Button } from "react-bootstrap";

import "./HoverActions.scss";

interface HoverActionsProps {
  onEdit: any;
  onDelete: any;
  onAdd: any;
  data: any;
  fieldType: string;
  dataList: any;
  defaultValue: any;
}
const HoverActions = ({
  dataList,
  onDelete,
  onEdit,
  data,
  onAdd,
  defaultValue,
  fieldType,
}: HoverActionsProps) => {
  return (
    <>
      <div className="text-center">
        <Button
          className="mx-1 btn-white-circle"
          variant="light"
          onClick={() => onAdd(defaultValue)}
        >
          <i className="fa fa-plus fa-fw" aria-hidden="true"></i>
        </Button>
        <Button
          className="mx-1 btn-white-circle"
          variant="light"
          onClick={() => onEdit(data)}
        >
          <i className="fa fa-pencil fa-fw" aria-hidden="true"></i>
        </Button>
        <Button
          className="mx-1 btn-white-circle"
          variant="light"
          onClick={() => onDelete(data, fieldType)}
        >
          <i className="fa fa-trash-o fa-fw text-danger" aria-hidden="true"></i>
        </Button>
      </div>
    </>
  );
};

export default HoverActions;
