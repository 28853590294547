import "./RelatedCompanies.scss";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CompaniesService from "../../../services/http/companies.service";
import { AxiosResponse } from "axios";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import {
  MAX_REQUEST,
  RELATION_TYPE,
} from "../../../constants/resume-forms-constants";
import OpportunitiesService from "../../../services/http/opportunities.service";
import HiringManagersService from "../../../services/http/hiring-managers.service";
import { confirmDelete } from "../../../services/confirm-delete.service";
import { USER_TYPE } from "../../../constants/nav-constants";

const RelatedCompanies = ({
  relatedCompanies,
  setRelatedCompanies,
  id,
  type,
  showSuccessToast,
}) => {
  const history = useHistory();
  const [searchText] = useState("");
  const [singleSelections] = useState([]);
  const [result, setResult] = useState<any[]>([]);

  useEffect(() => {
    const getApplicants = (params: any) => {
      CompaniesService.getCompanies(params).then((data: AxiosResponse) => {
        if (data && data.data && data.data.data) {
          setResult(data.data.data);
        }
      });
    };

    getApplicants({
      SearchStr: searchText,
      PageSize: MAX_REQUEST,
      PageNumber: 1,
    });
  }, [searchText]);

  const addSelected = (theSelected: any = []) => {
    if (
      theSelected.length &&
      theSelected[theSelected.length - 1].companyName &&
      !theSelected[theSelected.length - 1].customOption
    ) {
      const theSelectedName = theSelected.length
        ? theSelected[theSelected.length - 1].companyName
        : ``;

      const trimStack = theSelectedName.trim() || ``;
      if (theSelected) {
        const isAlreadySelected = relatedCompanies.filter(
          (item) => item.contactName === trimStack
        );

        if (!isAlreadySelected.length) {
          const newItem = {
            id: theSelected[0]?.id,
            contactName: theSelected[0]?.companyName,
          };
          setRelatedCompanies([...relatedCompanies, newItem]);
          saveChanges(newItem);
        }
      }
    } else {
      newCompany(theSelected[0].companyName);
    }
  };

  const saveChanges = (data: any) => {
    let theObsrvable: any = "";
    switch (type) {
      case RELATION_TYPE.OPPORTUNITY:
        theObsrvable = OpportunitiesService.createCompany(id, data);
        break;
      case RELATION_TYPE.HIRING_MANAGER:
        theObsrvable = HiringManagersService.createCompany(id, data);
        break;
      default:
        break;
    }
    if (showSuccessToast) {
      theObsrvable.then(() => {
        showSuccessToast();
      });
    }
  };

  const newCompany = (companyName: string) => {
    CompaniesService.createCompany({
      companyName,
    }).then(({ data }: AxiosResponse) => {
      const newItem = {
        id: data?.id,
        contactName: companyName,
      };

      setRelatedCompanies([...relatedCompanies, newItem]);
      saveChanges(newItem);
    });
  };

  const removeCompany = (params: any = {}) => {
    confirmDelete(`related company`).then((res) => {
      if (res.isConfirmed) {
        deleteNow(params);
      }
    });
  };

  const deleteNow = (params: any = {}) => {
    let theObsrvable: any = "";
    switch (type) {
      case RELATION_TYPE.OPPORTUNITY:
        theObsrvable = OpportunitiesService.removeCompany(id, params);
        break;
      case RELATION_TYPE.HIRING_MANAGER:
        theObsrvable = HiringManagersService.removeCompany(id, params);
        break;
      default:
        break;
    }

    const filtered = relatedCompanies.filter((item) => {
      return item.id !== params.id;
    });
    setRelatedCompanies(filtered);
    if (showSuccessToast) {
      theObsrvable.then(() => {
        showSuccessToast();
      });
    }
  };

  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  return (
    <>
      <Form autoComplete="false">
        <Form.Group>
          <Form.Label className="text-strong">
            <i className="bi bi-globe lead fa fa-fw"></i>
            Related Companies{" "}
          </Form.Label>
          <div className="text-info mb-2">
            <ul className="list-unstyled my-0">
              {relatedCompanies && relatedCompanies.length
                ? relatedCompanies.map((data, index) => {
                    return (
                      <li className="mb-1" key={index}>
                        {theUser.role !== USER_TYPE.TECHNICAL ? (
                          <span
                            className="cursor-pointer text-danger"
                            onClick={() => {
                              removeCompany(data);
                            }}
                            title={`Remove ${data?.contactName}`}
                          >
                            &times;
                          </span>
                        ) : null}
                        <span
                          className="cursor-pointer"
                          title={`View ${data?.contactName}`}
                          onClick={() => history.push(`/companies/${data.id}`)}
                        >
                          {data?.contactName}
                        </span>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>

          <Typeahead
            id="basic-typeahead-company"
            labelKey="companyName"
            allowNew
            minLength={1}
            disabled={theUser.role === USER_TYPE.TECHNICAL}
            newSelectionPrefix="Add new company: "
            onChange={(e) => {
              addSelected(e);
            }}
            className="input-line-typeahead"
            options={result}
            placeholder="Add Related Companies"
            selected={singleSelections}
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default RelatedCompanies;
