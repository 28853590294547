import { useState, useEffect, Fragment } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import "./ProExperienceFormModal.scss";
import { ProfessionalExperience } from "../../../interface/page-two.interface";
import ApplicantsService from "../../../services/http/applicants.service";
import LookupsService from "../../../services/lookups-service";
import { AsyncTypeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

interface ProExperienceFormModalProps {
  show: boolean;
  onHide: any;
  onSubmit: any;
  data: ProfessionalExperience;
}
const ProExperienceFormModal = ({
  show,
  onHide,
  data,
  onSubmit,
}: ProExperienceFormModalProps) => {
  const [company, setCompany] = useState(``);
  const [isPresent, setIsPresent] = useState(false);
  const [isShowSkillError, setIsShowSkillError] = useState(false);
  const [position, setPosition] = useState(``);
  const [dateStarted, setDateStarted] = useState<any | null>(null);
  const [dateFinished, setDateFinished] = useState<any | null>(null);
  const [jobDescription, setJobDescription] = useState(``);
  const [stackList, setStackList] = useState<any | null>([]);
  const [selectedStack, setSelectedStack] = useState<any | null>([]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    setIsShowSkillError(false);
    if (!selectedStack.length) {
      setIsShowSkillError(true);
      return;
    }
    const formatDateFinished = isPresent ? null : dateFinished;
    const formattedStack = selectedStack.join(", ");
    const form = {
      id: data.id || ApplicantsService.getRandomDecimal(),
      company,
      position,
      dateStarted,
      dateFinished: formatDateFinished,
      jobDescription,
      stackUsed: formattedStack,
    };
    onSubmit(form, `professionalExperience`);
  };

  useEffect(() => {
    const {
      company,
      position,
      dateStarted,
      dateFinished,
      jobDescription,
      stackUsed,
    } = data;
    const dateStartedFormat = dateStarted?.toString()?.substring(0, 10);

    const dateFinishedFormat = !dateFinished
      ? ``
      : dateFinished?.toString()?.substring(0, 10);
    const isPresentValue = typeof dateFinished === "string" ? false : true;
    setCompany(company);
    setPosition(position);
    setDateStarted(dateStartedFormat);
    setDateFinished(dateFinishedFormat);
    setJobDescription(jobDescription);
    setIsPresent(isPresentValue);

    const arrayStack = stackUsed
      ? stackUsed.split(",").map((item) => {
          return item.trim();
        })
      : [];
    setSelectedStack(arrayStack);
  }, [data]);

  const addNewStack = (newStack: any = []) => {
    if (newStack.length && newStack[newStack.length - 1].name) {
      const newStackName = newStack.length
        ? newStack[newStack.length - 1].name
        : ``;

      const trimStack = newStackName.trim() || ``;
      if (newStack) {
        const isAlreadySelected = selectedStack.filter(
          (item) => item === trimStack
        );

        if (!isAlreadySelected.length) {
          const newList = [...selectedStack, trimStack];
          setSelectedStack(newList);
        }
      }
      setIsShowSkillError(false);
    } else {
      setSelectedStack(newStack);
    }
  };

  const setAndFormatDescription = (text: string) => {
    const theSplit = text.split("\n");
    const formattedTextArray = theSplit.map((item) => {
      const matches = item.match(/\b(\w)/g);
      const firstLetter =
        matches && matches.length ? item.indexOf(matches[0]) : 0;
      const toStrip = item.substring(0, firstLetter);

      const newString = item.replace(toStrip, "");
      const stripped = newString.length ? `• ${newString}` : ``;
      return stripped;
    });
    setJobDescription(formattedTextArray.join("\n"));
  };

  // TYPEAHEAD
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (query: string) => {
    setIsLoading(true);

    LookupsService.getSkillsList({ SearchStr: query }).then(
      ({ data }: any = []) => {
        setIsLoading(false);
        setStackList(data);
      }
    );
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>
        <Form onSubmit={onSubmitForm}>
          <Modal.Body>
            <h5>{data?.id ? `Update` : `Add`} Professional Experience</h5>

            <Row>
              <Col lg={6}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Company <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Company name"
                    value={company}
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Position <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Position"
                    value={position}
                    onChange={(e) => {
                      setPosition(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Date started <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    required
                    placeholder="Date started"
                    value={dateStarted}
                    onChange={(e) => {
                      setDateStarted(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Date finished <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    disabled={isPresent}
                    required={!isPresent}
                    placeholder="Date finished"
                    value={dateFinished}
                    onChange={(e) => {
                      setDateFinished(e.target.value);
                    }}
                  />
                  <Form.Check
                    className="mt-1"
                    checked={isPresent}
                    onChange={(e) => {
                      setIsPresent(e.target.checked);
                    }}
                    type="switch"
                    id="custom-switch"
                    label="I am currently working in this role"
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Job description <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    minLength={75}
                    required
                    rows={4}
                    value={jobDescription}
                    onChange={(e) => {
                      setAndFormatDescription(e.target.value);
                    }}
                    placeholder="Type your job description"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Col lg={12}>
              <Form.Group className="mt-3">
                <Form.Label>
                  Stack Used <span className="text-danger">*</span>
                </Form.Label>

                <AsyncTypeahead
                  filterBy={filterBy}
                  id="async-example"
                  isLoading={isLoading}
                  labelKey="name"
                  multiple
                  allowNew
                  selected={selectedStack}
                  minLength={2}
                  onSearch={handleSearch}
                  onChange={(selected: any) => {
                    addNewStack(selected);
                  }}
                  options={stackList}
                  delay={500}
                  useCache={false}
                  newSelectionPrefix="Add custom skill: "
                  placeholder="Eg. Laravel, VueJS, HTML, CSS..."
                  renderMenuItemChildren={(option: any, props) => (
                    <Fragment>
                      <span key={option.name}>{option.name}</span>
                    </Fragment>
                  )}
                />
                {isShowSkillError ? (
                  <Form.Text className="text-danger">
                    Select atleast one stack or add new from dropdown menu.
                  </Form.Text>
                ) : null}
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-primary text-decoration-none"
              onClick={onHide}
            >
              Close
            </Button>
            <Button type="submit" variant="primary" className="text-white">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ProExperienceFormModal;
