import "./Login.scss";
import LazyLoad from "react-lazyload";
import { Col, Row, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import DPLogo from "../../assets/images/pooling_assets/talentpool-logo.png";
import { CredentialsProps, LoginProps } from "../../interface/login.interface";
import LoginService from "../../services/http/login.service";
import { isKeepLoggedIn, saveLoginDetails } from "../../services/login-service";
import { AxiosError, AxiosResponse } from "axios";
import { triggerFunc } from "../../services/form-hook-service";
import { useHistory } from "react-router-dom";
import { Loading } from "../../types/general.types";
import { useState } from "react";
import SubmitButton from "../../components/common/Button/Button";
import { USER_TYPE } from "../../constants/nav-constants";

const submitLoginCreds = ({
  data,
  setValue,
  trigger,
  history,
  setIsLoggedIn,
  setIsLoading,
}: CredentialsProps) => {
  if (data) {
    setIsLoading(true);
    const params = {
      userName: data.email,
      password: data.password,
      keepLoggedIn: isKeepLoggedIn(),
    };
    LoginService.loginUser(params)
      .then((data: AxiosResponse) => {
        if (data && data.data) {
          setIsLoading(false);
          setIsLoggedIn(true);
          saveLoginDetails(data.data);
          setValue("email", null);
          setValue("password", null);
          triggerFunc(trigger);

          if (data.data.role === USER_TYPE.APPLICANT) {
            history.push("/profile");
          } else {
            history.push("/candidates");
          }
        }
      })
      .catch((error: AxiosError) => {
        setValue("email", null);
        setValue("password", null);
        setIsLoading(false);
        triggerFunc(trigger);
      });
  }
};

const Login = ({ setIsLoggedIn }: LoginProps) => {
  const [isLoading, setIsLoading]: Loading = useState<boolean>(false);
  const history = useHistory();
  const { register, handleSubmit, formState, setValue, trigger } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const isFormReady = formState.isValid && !isLoading ? "" : "is-disabled";
  return (
    <>
      <LazyLoad>
        <div className="login-container">
          <div className="content-container">
            <div className="login-panel">
              <p className="text-center">
                <img src={DPLogo} alt="DP_LOGO" />
              </p>
              <h5 className="form-title">Login to your account to continue</h5>
              <form
                onSubmit={handleSubmit((data) =>
                  submitLoginCreds({
                    data,
                    setValue,
                    trigger,
                    history,
                    setIsLoggedIn,
                    setIsLoading,
                  })
                )}
              >
                <div className="form-wrapper">
                  <Form.Group controlId="email" className="text-box">
                    <p className="input-title">Email</p>
                    <Form.Control
                      autoComplete="off"
                      type="email"
                      className="is-valid"
                      {...register("email", {
                        required: true,
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="form-wrapper">
                  <Form.Group controlId="password" className="text-box">
                    <p className="input-title">Password</p>
                    <Form.Control
                      autoComplete="off"
                      type="password"
                      className="is-valid"
                      {...register("password", {
                        required: true,
                      })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Row>
                      <Col>
                        <Form.Check type="checkbox" label="Remember me" />
                      </Col>
                      <Col className="text-end">
                        <Button
                          onClick={() => history.push("/forgot-password")}
                          className="px-0 text-decoration-none"
                          variant="link"
                        >
                          Forgot Password?
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </div>
                <div className="buttons-container">
                  <SubmitButton isFormReady={isFormReady} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  );
};

export default Login;
