import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ApplicantsService from "../../../services/http/applicants.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useState } from "react";
import { USER_TYPE } from "../../../constants/nav-constants";
import LogProfileModal from "../../../pages/Applicants/LogProfileModal/LogProfileModal";
const MySwal = withReactContent(Swal);

const ApplicantTableActions = (props) => {
  const applicant = props.data;
  const theUser = props.theUser;
  const history = useHistory();

  const [showUserLogModal, setShowUserLogModal] = useState(false);
  const [currentUserLog, setCurrentUserLog] = useState(``);

  const handleCloseExpSummaryModal = () => setShowUserLogModal(false);
  const handleShowExpSummaryModal = (userId: string) => {
    setShowUserLogModal(true);
    setCurrentUserLog(userId);
  };

  const userLogs = (userId: string) => {
    handleShowExpSummaryModal(userId);
  };

  const downloadTemplateCV = (userId: string) => {

    ApplicantsService.downloadTemplateCVLink(userId);
    // ApplicantsService.downloadTemplateCV(userId)
    //   .then((response: any) => {
    //     if (response.status === 200) {
    //       let blob = new Blob([response.data], { type: "application/pdf" });
    //       let link = document.createElement("a");
    //       link.href = window.URL.createObjectURL(blob);
    //       link.target = `_blank`;
    //       // link.download = `${userId}template-cv.pdf`;
    //       link.click();
    //     }
    //   })
    //   .catch(() => {
    //     MySwal.fire({
    //       title: "Yikes!",
    //       text: "Something wrong.",
    //       icon: "error",
    //       confirmButtonColor: "#F9A000",
    //     });
    //   });
  };

  const resetPassword = (userId: string) => {
    MySwal.fire({
      title: "Confirm!",
      text: `Are you sure you want to reset password of selected applicant?`,
      icon: "question",
      confirmButtonColor: "#D93123",
      confirmButtonText: `Yes, Reset Password`,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        ApplicantsService.resetApplicantPassword(userId)
          .then((response: any) => {
            if (response.status === 200) {
              MySwal.fire({
                title: "Success!",
                text: `Password reset successfully executed.`,
                icon: "success",
                confirmButtonColor: "#F9A000",
              });
            }
          })
          .catch(() => {});
      }
    });
  };

  const deleteProfile = (userId: string) => {
    MySwal.fire({
      title: "Confirm!",
      text: `Are you sure you want to delete selected applicant?`,
      icon: "question",
      confirmButtonColor: "#D93123",
      confirmButtonText: `Yes, Delete Applicant`,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        ApplicantsService.deleteApplicant(userId)
          .then((response: any) => {
            if (response.status === 200) {
              // setCurrentPage(1);
              MySwal.fire({
                title: "Success!",
                text: `User successfully deleted.`,
                icon: "success",
                confirmButtonColor: "#F9A000",
              });
            }
          })
          .catch(() => {});
      }
    });
  };

  const downloadUploadedCV = (userId: string) => {
    ApplicantsService.downloadUploadedCVLink(userId);
    // ApplicantsService.downloadUploadedCV(userId)
    //   .then((response: any) => {
    //     if (response.status === 200) {
    //       let blob = new Blob([response.data], { type: "application/pdf" });
    //       let link = document.createElement("a");
    //       link.href = window.URL.createObjectURL(blob);
    //       link.target = `_blank`;
    //       // link.download = `${userId}uploaded-cv.pdf`;
    //       link.click();
    //     }
    //   })
    //   .catch(() => {
    //     MySwal.fire({
    //       title: "Yikes!",
    //       text: "No uploaded CV for this user yet.",
    //       icon: "error",
    //       confirmButtonColor: "#F9A000",
    //     });
    //   });
  };

  
  return (
    <>
    
    <LogProfileModal
        userId={currentUserLog}
        show={showUserLogModal}
        isApplicant={true}
        onHide={handleCloseExpSummaryModal}
      ></LogProfileModal>

    <Dropdown align={`end`}>
    <Dropdown.Toggle
      className="py-0 text-primary borderless"
      size="sm"
      variant="outline-light"
      id={`dropdown-basic${applicant.id}`}
    >
      <i
        className="fa fa-ellipsis-h"
        aria-hidden="true"
      ></i>
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item
        onClick={() =>
          history.push(`/profile/${applicant.id}`)
        }
      >
        Edit Profile
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() =>
          downloadTemplateCV(applicant.id)
        }
      >
        Download Template CV
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() =>
          downloadUploadedCV(applicant.id)
        }
      >
        Download Uploaded CV
      </Dropdown.Item>

      {theUser.role === USER_TYPE.SUPER_ADMIN ? (
        <>
          <Dropdown.Item
            onClick={() =>
              deleteProfile(applicant.id)
            }
          >
            Delete Profile
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              resetPassword(applicant.id)
            }
          >
            Reset Password
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => userLogs(applicant.id)}
          >
            Edit Log
          </Dropdown.Item>
        </>
      ) : null}
      {theUser.role === USER_TYPE.CRM ||
      theUser.role === USER_TYPE.TECHNICAL ? (
        <>
          <Dropdown.Item
            onClick={() => userLogs(applicant.id)}
          >
            Edit Log
          </Dropdown.Item>
        </>
      ) : null}
    </Dropdown.Menu>
  </Dropdown>
    </>
  );
};

export default ApplicantTableActions;
