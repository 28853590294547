import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { baseUrlLive } from "./constants/login-constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { ERROR_DEFAULT_MESSAGE, RESPONSE_ERRORS } from "./constants/interceptors-constants";
import { InterceptorErros } from "./interface/interceptor.interface";

const MySwal = withReactContent(Swal);

const axiosInstance = axios.create({
  baseURL: baseUrlLive,
  headers: {
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (!error || !error.response) {
      MySwal.fire({
        title: "Something went wrong!",
        text: "Please contact support.",
        icon: "warning",
        confirmButtonColor: "#F9A000",
      });
      return;
    }
    if (error.response.status === 401) {
      MySwal.fire({
        title: "Session Expired",
        text: "Please login again.",
        icon: "warning",
        confirmButtonColor: "#F9A000",
      }).then(() => {
        localStorage.clear();
        window.location.href = "/";
      });
    } else {
      const errorMessageFilter = RESPONSE_ERRORS.filter(
        (data: InterceptorErros) => error.response?.data?.errorCode === data.ERROR_CODE);
      const errorMessage = errorMessageFilter.length ? errorMessageFilter[0].MESSAGE : ERROR_DEFAULT_MESSAGE;
      MySwal.fire({
        title: "Something went wrong",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#F9A000",
      });
      return Promise.reject(error);
    }
  }
);

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("jwt-token");
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  },
  (error: any) => {
    console.error(`Interceptor Request Error ${error}`);
  }
);

export default axiosInstance;
