import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const TableToolTip2 = (props) => {
  const theLink = (link) => {
    let linkValue = ``;
    switch (link) {
      case `relatedOpportunities`:
        linkValue = `opportunities`; 
        break;
    
      case `relatedHiringManagers`:
        linkValue = `hiring-managers`; 
        break;
    
      case `relatedCompanies`:
        linkValue = `companies`; 
        break;
    
      case `relatedCandidates`:
        linkValue = `profile`; 
        break;
    
      default:
        break;
    }
    return linkValue;
  };

  const data = props.value;
  const link = theLink(props.colDef.field);

  const history = useHistory();

  return (
    <OverlayTrigger
      key={`bottom`}
      placement={`bottom`}
      overlay={
        <Tooltip id={`tooltip-${`bottom`}`}>
          {data && data.length && data.map((item, index) => {
            return (
              <div key={index} className="my-0 py-0">
                &middot; {item?.contactName || item?.statusName || item?.skillName} <br />
              </div>
            );
          })}
        </Tooltip>
      }
    >
      <div>
        {data?.length && link ? (
          <div className="text-info cursor-pointer">
            <div onClick={() => history.push(`/${link}/${data[0].id}`)}>
              {data[0]?.contactName}
            </div>
          </div>
        ) : null}
        {data?.length ? (
          <div>{data[0]?.statusName || data[0].skillName}</div>
        ) : null}
      </div>
    </OverlayTrigger>
  );
};

export default TableToolTip2;
