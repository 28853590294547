import { Dropdown, FormControl } from "react-bootstrap";
import ApplicantsService from "../../../services/http/applicants.service";
import "./DesiredSalary.scss";

const DesiredSalary = ({
  desiredSalaryMin,
  setDesiredSalaryMin,
  desiredSalaryMax,
  setDesiredSalaryMax,
  id,
  showSuccessToast,
}) => {
  const saveChanges = () => {
    ApplicantsService.desiredSalary(id, {
      desiredSalaryMin: parseInt(desiredSalaryMin, 10),
      desiredSalaryMax: parseInt(desiredSalaryMax, 10),
    }).then(() => {
      if (showSuccessToast) showSuccessToast();
    });
  };
  return (
    <>
      <span className="small text-muted-af">Desired Salary</span> <br />
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          className="badge badge-wide rounded-pill bg-primary"
        >
          {desiredSalaryMin && desiredSalaryMax ? (
            <span>
              {desiredSalaryMin} - {desiredSalaryMax}
            </span>
          ) : (
            `Add Desired Salary`
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            type="number"
            placeholder="Min Salary"
            value={desiredSalaryMin}
            onChange={(e) => setDesiredSalaryMin(parseInt(e.target.value, 10))}
          />
          <Dropdown.Header>Up to</Dropdown.Header>
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            type="number"
            placeholder="Max Salary"
            value={desiredSalaryMax}
            onChange={(e) => setDesiredSalaryMax(parseInt(e.target.value, 10))}
          />
          <Dropdown.Item
            className="text-primary cursor-pointer text-end bg-transparent"
            onClick={() => saveChanges()}
          >
            Save
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DesiredSalary;
