import { SelectOptionsDetails, GeneralSettingsOption } from "../interface/select-options.interface";

export const AVAILABILITY: SelectOptionsDetails[] = [
    {
        id: 1,
        name: 'Part Time'
    },
    {
        id: 2,
        name: 'Full Time'
    },
]

export const ASSESSMENT: SelectOptionsDetails[] = [
    {
        id: 0,
        name: 'Select'
    },
    {
        id: 1,
        name: 'Bad'
    },
    {
        id: 2,
        name: 'Good'
    },
    {
        id: 3,
        name: 'Very Good'
    },
];

export const ASSESSMENT_TYPES = {
    COMMUNICATION: `Communication`,
    KNOWLEDGE: `Knowledge`,
    SKILL: `Skill`,
}

export const DATE_FORMATS = [
    {
        id: 1,
        value: `MMM D, yyyy`,
        preview: `Jun 1, 2022`
    },
    {
        id: 1,
        value: `YYYY-MM-DD`,
        preview: `2022-06-01`
    },
    {
        id: 2,
        value: `MMMM Do, YYYY`,
        preview: `June 1st, 2022`
    },
    {
        id: 3,
        value: `MMMM 'YY`,
        preview: `June '22`
    },
    {
        id: 4,
        value: `DD/MM/YYYY`,
        preview: `01/06/2022`
    },
    {
        id: 5,
        value: `MM/DD/YYYY`,
        preview: `06/01/2022`
    },
];

export const TIME_FORMATS = [
    {
        id: 1,
        value: `HH:mm`,
        preview: `14:04`
    },
    {
        id: 2,
        value: `HH:mm:ss`,
        preview: `14:04:03`
    },
    {
        id: 3,
        value: `h:mma`,
        preview: `2:04pm`
    },
    {
        id: 4,
        value: `h:mm A`,
        preview: `2:04 PM`
    },
];


export const SETTING_INDEX = {
    DATE_FORMAT: 0,
    ENABLE_DARK_MODE: 1,
    TIME_FORMAT: 2,
    TIMEZONE: 3,
    ALERTS_REMINDERS: 4,
    ENABLE_ALL_EMAIL_NOTIF: 5,
    NEWS_UPDATES: 6,
    OPT_IN_USER_RESEARCH_FOR_FUTURE_UPDATES: 7,
    PROFILE_UPDATES: 8,
    TIPS_TUTORIALS: 9,
    ENABLE_EDIT_PIPELINE_SETTINGS: 10,
    PREVENT_ADD_NEW_USER: 11,
  };


export const GENERAL_SETTINGS: GeneralSettingsOption[] = [
    {
        settingName: "DATE_FORMAT",
        settingCategory: "GENERAL.APPEARANCE",
        settingValue: "MMM D, yyyy"
    },
    {
        settingName: "ENABLE_DARK_MODE",
        settingCategory: "GENERAL.APPEARANCE",
        settingValue: false
    },
    {
        settingName: "TIME_FORMAT",
        settingCategory: "GENERAL.APPEARANCE",
        settingValue: "h:mm tt"
    },
    {
        settingName: "TIMEZONE",
        settingCategory: "GENERAL.APPEARANCE",
        settingValue: 8.0000
    },
    {
        settingName: "ALERTS_REMINDERS",
        settingCategory: "GENERAL.NOTIFICATIONS",
        settingValue: false
    },
    {
        settingName: "ENABLE_ALL_EMAIL_NOTIF",
        settingCategory: "GENERAL.NOTIFICATIONS",
        settingValue: false
    },
    {
        settingName: "NEWS_UPDATES",
        settingCategory: "GENERAL.NOTIFICATIONS",
        settingValue: false
    },
    {
        settingName: "OPT_IN_USER_RESEARCH_FOR_FUTURE_UPDATES",
        settingCategory: "GENERAL.NOTIFICATIONS",
        settingValue: false
    },
    {
        "settingName": "PROFILE_UPDATES",
        "settingCategory": "GENERAL.NOTIFICATIONS",
        "settingValue": false
    },
    {
        "settingName": "TIPS_TUTORIALS",
        "settingCategory": "GENERAL.NOTIFICATIONS",
        "settingValue": false
    },
    {
        "settingName": "ENABLE_EDIT_PIPELINE_SETTINGS",
        "settingCategory": "GENERAL.PIPELINE",
        "settingValue": false
    },
    {
        "settingName": "PREVENT_ADD_NEW_USER",
        "settingCategory": "GENERAL.PIPELINE",
        "settingValue": false
    }
];