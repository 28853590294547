import { Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { requireLogin } from "./services/LoginRouteService";
import Loading from "../components/Loading/Loading";
import NotFound from "../components/NotFound/NotFound";
import Landing from "../pages/Landing/Landing";
import Login from "../pages/Login/Login";
import Verification from "../pages/Verification/Verification";
import ReferFriend from "../pages/ReferFriend/ReferFriend";
import Companies from "../pages/Companies/Companies";
import CompaniesView from "../pages/CompaniesView/CompaniesView";
import HiringManager from "../pages/HiringManager/HiringManager";
import HiringManagersView from "../pages/HiringManagersView/HiringManagersView";
import Opportunities from "../pages/Opportunities/Opportunities";
import OpportunitiesView from "../pages/OpportunitiesView/OpportunitiesView";
import OpportunitiesNew from "../pages/OpportunitiesNew/OpportunitiesNew";
import { RouterProps } from "../interface/router.interface";
import Applicants from "../pages/Applicants/Applicants";
import Admins from "../pages/Admins/Admins";
import Profile from "../pages/Profile/Profile";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import SendForgotPassword from "../pages/SendForgotPassword/SendForgotPassword";
import SettingsView from "../pages/SettingsView/SettingsView";

const MainRoute = ({ userDetails, setIsLoggedIn, setIsEdit, isEdit }: RouterProps) => (
  <GuardProvider guards={[requireLogin]} loading={Loading} error={NotFound}>
    <Switch>
      <GuardedRoute path="/" exact component={Landing} />
      <GuardedRoute path="/forgot-password" exact component={SendForgotPassword} />
      <GuardedRoute path="/login" render={() => (<Login setIsLoggedIn={setIsLoggedIn} />)} />
      <GuardedRoute path="/verification" component={Verification} />
      <GuardedRoute path="/refer-a-friend" component={ReferFriend} />
      <GuardedRoute path="/change-password" component={ResetPassword} meta={{ auth: true }} />
      <GuardedRoute path="/profile" component={Profile} meta={{ auth: true }} />
      <GuardedRoute path="/candidates" component={Applicants} meta={{ auth: true }} />
      <GuardedRoute path="/companies" exact component={Companies} meta={{ auth: true }} />
      <GuardedRoute path="/companies/:id" component={CompaniesView} meta={{ auth: true }} />
      <GuardedRoute path="/hiring-managers" exact component={HiringManager} meta={{ auth: true }} />
      <GuardedRoute path="/hiring-managers/:id" component={HiringManagersView} meta={{ auth: true }} />
      <GuardedRoute path="/opportunities" exact component={Opportunities} meta={{ auth: true }} />
      <GuardedRoute path="/opportunity/:id" component={OpportunitiesNew} meta={{ auth: true }} />
      <GuardedRoute path="/opportunities/:id" component={OpportunitiesView} meta={{ auth: true }} />
      <GuardedRoute path="/settings/:tab" component={SettingsView} meta={{ auth: true }} />
      <GuardedRoute path="/admins" component={Admins} meta={{ auth: true }} />
      <GuardedRoute path="*" component={NotFound} />
    </Switch>
  </GuardProvider>
);

export default MainRoute;
