import { Button, Card, Col, Container, Row, Form } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "./CompaniesView.scss";
import CompaniesService from "../../services/http/companies.service";

// import { DEFAULT_PAGE_ROWS } from "../../constants/resume-forms-constants";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Redirect, useHistory, useParams } from "react-router-dom";
import RelatedOpportunities from "../../components/shared/RelatedOpportunities/RelatedOpportunities";
import RelatedHiringManagers from "../../components/shared/RelatedHiringManagers/RelatedHiringManagers";
import SuccessToast from "../../components/common/SuccessToast/SuccessToast";
import { USER_TYPE } from "../../constants/nav-constants";

const CompaniesView = () => {
  const history = useHistory();
  const params: any = useParams();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(``);
  const toggleShowToast = (value: boolean = false, message: string = ``) => {
    if (value) {
      setToastMessage(message);
    }
    setShowToast(value);
  };
  // const [isLoading, setIsLoading] = useState(false);
  const [theData, setTheData] = useState<any>({});
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [companyName, setCompanyName] = useState(``);
  const [address, setAddress] = useState(``);
  const [phoneNumber, setPhoneNumber] = useState(``);
  const [website, setWebsite] = useState(``);
  const [relatedHiringManagers, setRelatedHiringManagers] = useState<any>([]);
  const [relatedOpportunities, setRelatedOpportunities] = useState<any>([]);

  const resetButton = () => {
    setIsSaveLoading(false);
  };

  const updateDetails = (stringValue: string = ``) => {
    const theId = parseInt(params?.id, 10);
    const payload = {
      ...theData,
      id: theId || 0,
      companyName,
      phoneNumber,
      website,
      address,
      relatedOpportunities,
      relatedHiringManagers,
      dateModified: new Date()
    };

    if (!stringValue || JSON.stringify(theData) === JSON.stringify(payload)) {
      return;
    }

    setTheData(payload);
    setIsSaveLoading(true);

    if (theId) {
      CompaniesService.updateCompany(payload).then(
        ({ data }: AxiosResponse) => {
          showSuccessToast();
          setIsSaveLoading(false);
        },
        resetButton
      );
    } else {
      CompaniesService.createCompany(payload).then(
        ({ data }: AxiosResponse) => {
          showSuccessToast();
          setIsSaveLoading(false);
          setTimeout(() => {
            history.push(`/companies/${data?.id}`);
          }, 0);
        },
        resetButton
      );
    }
  };

  const showSuccessToast = () => {
    toggleShowToast(true, "Company successfully saved.");
  };

  useEffect(() => {
    const getData = () => {
      CompaniesService.getCompany(params?.id).then(
        ({ data }: AxiosResponse) => {
          if (data) {
            setTheData(data);
            setCompanyName(data?.companyName);
            setAddress(data?.address);
            setPhoneNumber(data?.phoneNumber);
            setWebsite(data?.website);
            setRelatedHiringManagers(data?.relatedHiringManagers || []);
            setRelatedOpportunities(data?.relatedOpportunities || []);
          }
        }
      );
    };
    if (parseInt(params?.id, 10)) {
      getData();
    } else {
      const query = new URLSearchParams(window.location.search);
      const newName = query.get("companyName") || ``;
      setCompanyName(newName);
    }
  }, [params?.id]);

  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  return (
    <>
      {theUser.role === USER_TYPE.APPLICANT ? (
        <Redirect to={`/profile`} />
      ) : (
        ``
      )}

      <SuccessToast
        showToast={showToast}
        toggleShowToast={toggleShowToast}
        toastMessage={toastMessage}
      ></SuccessToast>
      <LazyLoad>
        <Container className="main-container">
          <Row className="mt-5 pt-4 mx-0 px-0">
            <Col sm={3} className="align-self-center">
              {parseInt(params.id, 10) === 0 ? (
                <h5 className="my-1">Add Company</h5>
              ) : (
                <Button
                  className="text-decoration-none text-primary"
                  variant="link"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-angle-left fa-fw" aria-hidden="true"></i>
                  Back to companies list
                </Button>
              )}
            </Col>

            {isSaveLoading ? (
              <Col sm={9}>
                <span className="float-end py-1">
                  <i className="fa fa-fw fa-spinner fa-spin" aria-hidden="true"></i>
                  Saving Changes...
                </span>
              </Col>
            ) : (
              ``
            )}
          </Row>

          <Row>
            <Col>
              <Card className="main-card px-lg-5 mb-4">
                <Card.Body className="px-md-5 py-md-5">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">
                          Company Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Company Name"
                          value={companyName}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => updateDetails(e.target.value)}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">
                          Company Phone
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Company Phone"
                          value={phoneNumber}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => updateDetails(e.target.value)}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">Website</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Website"
                          value={website}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => updateDetails(e.target.value)}
                          onChange={(e) => {
                            setWebsite(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">
                          Company Address
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Company Address"
                          value={address}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => updateDetails(e.target.value)}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={3} className="offset-lg-3">
                      <Form.Group className="mt-3 mb-5">
                        <RelatedOpportunities
                          updateUserDetails={``}
                          relatedOpportunities={relatedOpportunities}
                          setRelatedOpportunities={setRelatedOpportunities}
                          id={params?.id}
                          type={`company`}
                          showSuccessToast={showSuccessToast}
                        ></RelatedOpportunities>
                      </Form.Group>
                      <Form.Group className="mt-3 mb-5">
                        <RelatedHiringManagers
                          updateUserDetails={``}
                          relatedHiringManagers={relatedHiringManagers}
                          setRelatedHiringManagers={setRelatedHiringManagers}
                          id={params?.id}
                          type={`company`}
                          showSuccessToast={showSuccessToast}
                        ></RelatedHiringManagers>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

        </Container>
      </LazyLoad>
    </>
  );
};

export default CompaniesView;
