
import { GuardFunction } from "react-router-guards";
import { USER_TYPE } from "../../constants/nav-constants";

export const requireLogin: GuardFunction = (to, from, next) => {
  const jwtToken = localStorage.getItem("jwt-token");
  if (to.meta.auth) {
    if (jwtToken) {
      next();
    }
    next.redirect("/");
  } else {
    const jwtToken = localStorage.getItem("jwt-token");
    if (jwtToken) {
      const userDetails = localStorage.getItem("userDetails") || "";
      const userDetailsObject = JSON.parse(userDetails);
      if (userDetailsObject.role === USER_TYPE.APPLICANT) {
        next.redirect("/profile");
      } else {
        next.redirect("/candidates");
      }
    }
    next();
  }
};
