import "./ProfileAssignedTo.scss";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import ApplicantsService from "../../../services/http/applicants.service";
import { AxiosResponse } from "axios";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import { MAX_REQUEST } from "../../../constants/resume-forms-constants";

const ProfileAssignedTo = ({
  assignedTo,
  setAssignedTo,
  updateUserDetails,
  id,
  type,
  showSuccessToast,
}) => {
  const history = useHistory();
  const [searchText] = useState("");
  const [singleSelections] = useState([]);
  const [result, setResult] = useState<any[]>([]);

  const saveChanges = (data: any) => {
    let theObsrvable = ApplicantsService.adminAssignedTo(id, data);

    if (showSuccessToast) {
      theObsrvable.then(() => {
        showSuccessToast();
      });
    }
  };

  useEffect(() => {
    const mapData = (data) => {
      return data.map((item) => {
        item.candidatesName = `${item.firstName} ${item.lastName}`;
        return item;
      });
    };
    const getAdmins = (params: any) => {
      ApplicantsService.getAdminsDetails(params).then((data: AxiosResponse) => {
        if (data && data.data && data.data.data) {
          setResult(mapData(data.data.data));
        }
      });
    };

    getAdmins({
      SearchStr: searchText,
      PageSize: MAX_REQUEST,
      PageNumber: 1,
    });
  }, [searchText]);

  const addSelected = (theSelected: any = []) => {
    if (
      theSelected.length &&
      theSelected[theSelected.length - 1].candidatesName &&
      !theSelected[theSelected.length - 1].customOption
    ) {
      const newAssignedTo = {
        id: theSelected[0]?.id,
        contactName: theSelected[0]?.candidatesName,
      };
      setAssignedTo([newAssignedTo]);

      saveChanges(newAssignedTo);
      // setTimeout(() => {
      //   if (updateUserDetails) updateUserDetails();
      // }, 500);
    }
  };

  return (
    <>
      <Form.Group>
        <Form.Label className="text-strong">
          <i className="bi bi-person-rolodex lead fa fa-fw"></i>
          Assigned To{" "}
        </Form.Label>
        <p className="text-info mb-2">
          {assignedTo && assignedTo.length
            ? assignedTo.map((data, index) => {
                return (
                  <span
                    className="cursor-pointer"
                    key={index}
                    onClick={() => history.push(`/profile/${data.id}`)}
                  >
                    {data?.contactName}
                    {index + 1 < assignedTo.length ? `, ` : ``}{" "}
                  </span>
                );
              })
            : null}
        </p>

        <Typeahead
          id="basic-typeahead-candidates"
          labelKey="candidatesName"
          minLength={1}
          onChange={(e) => {
            addSelected(e);
          }}
          className="input-line-typeahead"
          options={result}
          placeholder="Add Related Assigned To"
          selected={singleSelections}
        />
      </Form.Group>
    </>
  );
};

export default ProfileAssignedTo;
