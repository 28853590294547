import { Button, Form } from "react-bootstrap";
import "./Pagination.scss";

import { PAGE_ROWS } from "../../constants/resume-forms-constants";

const Pagination = ({
  tableMeta,
  isLoading,
  rowsPerPage,
  currentPage,
  setCurrentPage,
  setRowsPerPage
}) => {
  const handleSelectChange = (event) => {
    setCurrentPage(1);
    setRowsPerPage(event.target.value);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <div className="p-4 text-muted">
        <div className="d-md-flex justify-content-end">
          <div className="px-2 align-self-center">
            {isLoading ? (
              <i
                className="fa fa-spinner fa-spin text-muted mx-2"
                aria-hidden="true"
              ></i>
            ) : (
              ""
            )}
            Rows Per Page
          </div>

          <div className="px-2 align-self-center">
            <Form.Select
              className="borderless"
              size="sm"
              value={rowsPerPage}
              onChange={handleSelectChange}
            >
              {PAGE_ROWS.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.value}
                  </option>
                );
              })}
            </Form.Select>
          </div>

          <div className="px-2 align-self-center">
            {tableMeta?.firstEntry}-{tableMeta?.lastEntry} of{" "}
            {tableMeta?.totalEntries}
            <Button
              size="sm"
              className="mx-1 borderless"
              variant="outline-primary"
              disabled={
                tableMeta?.totalPages === 1 ||
                tableMeta?.firstEntry === 1 ||
                isLoading
              }
              onClick={previousPage}
            >
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </Button>
            <Button
              size="sm"
              className="mx-1 borderless"
              variant="outline-primary"
              disabled={
                tableMeta?.totalPages === 1 ||
                tableMeta?.lastEntry === tableMeta?.totalEntries ||
                isLoading
              }
              onClick={nextPage}
            >
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </Button>
          </div>
          <div className="align-self-center"></div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
