import "./TagsSettingsActions.scss";
import { Dropdown } from "react-bootstrap";

const TagsSettingsActions = ({ category, editTag, removeTag }) => {
  return (
    <>
      {category.skills.length ? (
        category.skills.map((item, index) => {
          return (
            <Dropdown key={index}>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="custom-badge"
                style={{ backgroundColor: item.tagColor }}
              >
                {item.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => editTag(item, category.id, "name")}
                >
                  Edit name
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => editTag(item, category.id, "color")}
                >
                  Change color
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => editTag(item, category.id, "category")}
                >
                  Change category
                </Dropdown.Item>
                <Dropdown.Item onClick={() => removeTag(item, category.id)}>
                  Delete tag
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        })
      ) : (
        <div className="text-center text-light p-3">Nothing found.</div>
      )}
    </>
  );
};

export default TagsSettingsActions;
