import "./LoggedInMenu.scss";
import { Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../../../services/nav-service";
import { LoggedInProps } from "../../../interface/login.interface";

const LoggedInMenu = ({ setIsLoggedIn }: LoggedInProps) => {
  const history = useHistory();
  return (
    <>
      <Nav>
        <Nav.Link onClick={() => logoutUser({ history, setIsLoggedIn })}>Logout</Nav.Link>
      </Nav>
    </>
  );
};

export default LoggedInMenu;
