import { Button, Col, Row } from "react-bootstrap";
import { Toast, ToastContainer } from "react-bootstrap";
import "./UndoToast.scss";

const UndoToast = ({
  showToast,
  toggleShowToast,
  toastMessage,
  undoAction,
  isUndo,
}) => {
  return (
    <ToastContainer position="bottom-center" className="toast-container p-3">
      <Toast
        bg="secondary"
        show={showToast}
        className="undo-toast"
        onClose={() => toggleShowToast(false)}
        delay={5000}
        autohide={!isUndo}
      >
        <Toast.Body>
          <Row className="align-items-center">
            <Col className="text-white">{toastMessage}</Col>
            <Col className="text-end col-md-auto">
              {!isUndo ? null : (
                <Button
                  className="btn-secondary btn-sm"
                  onClick={() => undoAction()}
                >
                  Undo
                </Button>
              )}
              <Button
              variant="link"
                className="text-decoration-none text-light close mx-1 btn-sm"
                onClick={() => toggleShowToast(false)}
              >
                &times;
              </Button>
            </Col>
          </Row>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default UndoToast;
