export const DEFAULT_USER_SKILLS_LIST =
{
    skillId: 0,
    skillName: '',
    skillRate: 0,
    yearsOfExperience: ''
};

export const SKILLS_RATING_LIST = [
    {
        id: 1,
        name: 'Beginner'
    },
    {
        id: 2,
        name: 'Intermediate'
    },
    {
        id: 3,
        name: 'Advanced'
    }
];