import { AxiosPromise, AxiosResponse } from 'axios';
import http from '../../http-common';
import { RelatedToParams } from '../../interface/form.interface';


const getHiringManagers = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.get('/Admin/hiringManagers', { params });
};

const createHiringManager = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post('/Admin/hiringManagers', params);
};
const updateHiringManager = (params: any = {}): AxiosPromise<AxiosResponse> => {
  return http.post(`/Admin/hiringManagers/${params.id}`, params);
};

const getHiringManager = (id: number): AxiosPromise<AxiosResponse> => {
  return http.get(`/Admin/hiringManagers/${id}`);
};

const deleteHiringManagers = (id: number): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Admin/hiringManagers/${id}`);
};

const createOpportunity = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.post(`/Admin/hiringManagers/${id}/relatedOpportunity`, params);
};

const removeOpportunity = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Admin/hiringManagers/${id}/relatedOpportunity`, { data: params });
};

const createCompany = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.post(`/Admin/hiringManagers/${id}/relatedCompany`, params);
};

const removeCompany = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Admin/hiringManagers/${id}/relatedCompany`, { data: params });
};

const createCandidate = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.post(`/Admin/hiringManagers/${id}/relatedCandidate`, params);
};

const removeCandidate = (id: string, params: RelatedToParams): AxiosPromise<AxiosResponse> => {
  return http.delete(`/Admin/hiringManagers/${id}/relatedCandidate`, { data: params });
};

const HiringManagersService = {
  getHiringManagers,
  createHiringManager,
  updateHiringManager,
  getHiringManager,
  deleteHiringManagers,
  createOpportunity,
  removeOpportunity,
  createCompany,
  removeCompany,
  createCandidate,
  removeCandidate,
};

export default HiringManagersService;