import { FC } from 'react';
import { SelectOptions, SelectOptionsDetails } from '../../../interface/select-options.interface';
import './Options.scss';

const Options: FC<SelectOptions> = ({ optionsList }) => {
    return <>
        <option key={0} value={0}>
            SELECT
        </option>
        {
            optionsList.map((data: SelectOptionsDetails) => (
                <option key={data.id} value={data.id}>
                    {data.name}
                </option>
            ))
        }
    </>;
};

export default Options;