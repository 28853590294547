import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { Card, Form, ListGroup } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import OpportunitiesService from "../../../services/http/opportunities.service";
import "./OpportunitySearch.scss";

const OpportunitySearch = ({
  searchTerm,
  setSearchTerm,
  setSelectedKeyword,
}) => {
  // const data = props.value;
  const elementVariable = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchResults, setSearchResults] = useState<any>({});

  useEffect(() => {
    setIsSearching(true);
    // setShowSearch(true);
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm);
      // Send Axios request here
      if (searchTerm) {
        OpportunitiesService.advancedSearch(searchTerm).then(
          (data: AxiosResponse) => {
            setIsSearching(false);
            setSearchResults({ ...data.data, showAll: true });
            console.log("data", data);
          }
        );
      } else {
        setSelectedKeyword([]);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, setSelectedKeyword]);

  // const history = useHistory();

  const changeFocusSearch = () => {
    setShowSearch(true);
  };

  const selectKeyword = (keyword) => {
    const SEARCH_DELAY = 1001;
    setSelectedKeyword([
      {
        KeywordType: keyword.keywordType,
        KeywordName: keyword.keywordName,
      },
    ]);
    setSearchTerm(keyword.keywordName);
    setTimeout(() => {
      setShowSearch(false);
      // searchOpportunity();
    }, SEARCH_DELAY);
  };

  const toggleShowAll = () => {
    setSearchResults({ ...searchResults, showAll: !searchResults.showAll });
  };
  const showKeywordItems = (index: number) => {
    const newSearchResult = searchResults.keywordTypes.map((item, i) => {
      i === index
        ? (item.showAll = !item.showAll)
        : (item.showAll = !!item.showAll);
      return item;
    });
    setSearchResults({
      ...searchResults,
      keywordTypes: newSearchResult,
    });
  };

  return (
    <div>
      <Card className="mt-3">
        <Card.Body
          ref={elementVariable}
          tabIndex={0}
          onBlur={() => setShowSearch(false)}
          onFocus={() => changeFocusSearch()}
          className={`p-1 card-search ${
            searchTerm && showSearch ? "search-on" : ""
          }`}
        >
          <Form autoComplete={"off"}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control
                type="search"
                size="lg"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Need help? Find what you’re looking for."
              />
            </Form.Group>
          </Form>
          <Card className="card-search-result">
            <ListGroup>
              {isSearching ? (
                <ListGroup.Item>
                  <i className="fas fa-circle-notch fa-fw fa-spin"></i>{" "}
                  Searching...
                </ListGroup.Item>
              ) : null}

              {!isSearching &&
              searchResults?.all &&
              searchResults?.all?.length ? (
                <ListGroup.Item className="li-result">
                  <div className="ms-2 me-auto">
                    <h5
                      className="fw-bold mb-0"
                      onClick={() => toggleShowAll()}
                    >
                      <i
                        className={`fa fa-fw ${
                          searchResults?.showAll
                            ? "fa-caret-down"
                            : "fa-caret-right"
                        }`}
                        aria-hidden="true"
                      ></i>{" "}
                      All
                    </h5>
                    {searchResults?.showAll ? (
                      <ul className="list-unstyled mb-0 list-result">
                        {searchResults.all.map((item, index) => {
                          return (
                            <li key={index} onClick={() => selectKeyword(item)}>
                              {item.keywordName}
                              <span className="small text-muted">
                                {item.keywordTypeName}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </div>
                </ListGroup.Item>
              ) : null}

              {!isSearching &&
              searchResults?.keywordTypes &&
              searchResults?.keywordTypes?.length
                ? searchResults?.keywordTypes.map((item, index) => {
                    return (
                      <ListGroup.Item className="li-result" key={index}>
                        <div className="ms-2 me-auto">
                          <h5
                            className="fw-bold mb-0"
                            onClick={() => showKeywordItems(index)}
                          >
                            <i
                              className={`fa fa-fw ${
                                item?.showAll
                                  ? "fa-caret-down"
                                  : "fa-caret-right"
                              }`}
                              aria-hidden="true"
                            ></i>{" "}
                            {item.keywordTypeName}
                          </h5>
                          {item?.showAll ? (
                            <ul className="list-unstyled mb-0 list-result">
                              {item.keywords.map((item, index) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={() =>
                                      selectKeyword(item)
                                    }
                                  >
                                    {item.keywordName}
                                    <span className="small text-muted">
                                      {item.keywordTypeName}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : null}
                        </div>
                      </ListGroup.Item>
                    );
                  })
                : null}

              {!isSearching &&
              searchResults?.all &&
              !searchResults?.all?.length &&
              searchResults?.keywordTypes &&
              !searchResults?.keywordTypes?.length ? (
                <ListGroup.Item className="text-muted">
                  No results found.
                </ListGroup.Item>
              ) : null}
            </ListGroup>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
};

export default OpportunitySearch;
