import { Col, Card, Form } from "react-bootstrap";
import "./AccountSettingsTab.scss";
import Facemoji from "../../../assets/images/facemoji.png";
import SocialSettingsCard from "../SocialSettingsCard/SocialSettingsCard";
import { useEffect, useState } from "react";
import AdminSettingsService from "../../../services/http/admin-settings.service";
import { AxiosResponse } from "axios";
import { AccountSettingsDetails } from "../../../interface/select-options.interface";
import SuccessToast from "../../../components/common/SuccessToast/SuccessToast";
import ChangePassword from "./ChangePassword/ChangePassword";

const AccountSettingsTab = () => {
  const [settings, setSettings] = useState<AccountSettingsDetails>();
  const [firstName, setFirstName] = useState(``);
  const [lastName, setLastName] = useState(``);
  const [userName, setUserName] = useState(``);
  const [emailAddress, setEmailAddress] = useState(``);
  const [aboutMe, setAboutMe] = useState(``);
  const [phoneNumber, setPhoneNumber] = useState(``);
  const [address, setAddress] = useState(``);
  const [language, setLanguage] = useState(``);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(``);
  const toggleShowToast = (value: boolean = false, message: string = ``) => {
    if (value) {
      setToastMessage(message);
    }
    setShowToast(value);
  };
  const showSuccessToast = () => {
    toggleShowToast(true, "Profile successfully saved.");
  };
  
  useEffect(() => {
    AdminSettingsService.getAccount().then(({ data }: AxiosResponse) => {
      setSettings(data);
      setFirstName(data.firstName || ``);
      setLastName(data.lastName || ``);
      setUserName(data.userName || ``);
      setEmailAddress(data.emailAddress || ``);
      setAboutMe(data.aboutMe || ``);
      setPhoneNumber(data.phoneNumber || ``);
      setAddress(data.address || ``);
      setLanguage(data.language || ``);
      console.log("data", data);
    });
  }, []);

  const updateValue = (e, key, setFunc, isCheckbox = false) => {
    setFunc(e.target.value);
    const copyUpdatedValue = JSON.parse(JSON.stringify(settings));
    copyUpdatedValue[key] = isCheckbox ? e.target.checked : e.target.value;
    setSettings(copyUpdatedValue);
    // copyUpdatedValue[key] = isCheckbox ? e.target.checked : e.target.value;

    console.log("copyUpdatedValue :>> ", copyUpdatedValue);
    console.log("settings :>> ", settings);

    const delayDebounceFn = setTimeout(() => {
      AdminSettingsService.saveAccount(copyUpdatedValue).then(
        (data: AxiosResponse) => {
          showSuccessToast();
          console.log("data", data.data);
        }
      );
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  };

  return (
    <>
          <SuccessToast
        showToast={showToast}
        toggleShowToast={toggleShowToast}
        toastMessage={toastMessage}
      ></SuccessToast>

      <Col md={4} className="col-img-profile">
        <Card className="mb-3">
          <Card.Body>
            <div className="image-holder">
              <img alt="" src={Facemoji}></img>

              <h6 className="my-3">
                Welcome, {firstName} {lastName}
              </h6>
            </div>

            <div className="mb-3 row">
              <label className="col-md-6 col-form-label col-form-label-sm">
                First name
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  name=""
                  className="form-control form-control-sm"
                  value={firstName}
                  onChange={(e) => updateValue(e, `firstName`, setFirstName)}
                ></input>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-6 col-form-label col-form-label-sm">
                Last name
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  name=""
                  className="form-control form-control-sm"
                  value={lastName}
                  onChange={(e) => updateValue(e, `lastName`, setLastName)}
                ></input>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-6 col-form-label col-form-label-sm">
                Username
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  name=""
                  className="form-control form-control-sm"
                  value={userName}
                  onChange={(e) => updateValue(e, `userName`, setUserName)}
                ></input>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-5 col-form-label col-form-label-sm">
                Email address
              </label>
              <div className="col-md-7">
                <input
                  type="text"
                  name=""
                  className="form-control form-control-sm"
                  value={emailAddress}
                  readOnly
                ></input>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-4 col-form-label col-form-label-sm">
                About me
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  name=""
                  className="form-control form-control-sm"
                  value={aboutMe}
                  onChange={(e) => updateValue(e, `aboutMe`, setAboutMe)}
                ></input>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md={5}>
        <Card className="mb-3">
          <Card.Body>
            <ChangePassword></ChangePassword>

            <h6 className="my-3">Personal Information</h6>
            <div className="mb-3 row">
              <label className="col-md-5 col-form-label col-form-label-sm">
                Phone number
              </label>
              <div className="col-md-7">
                <input
                  type="text"
                  name=""
                  className="form-control form-control-sm"
                  value={phoneNumber}
                  onChange={(e) =>
                    updateValue(e, `phoneNumber`, setPhoneNumber)
                  }
                ></input>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-4 col-form-label col-form-label-sm">
                Address
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  name=""
                  className="form-control form-control-sm"
                  value={address}
                  onChange={(e) => updateValue(e, `address`, setAddress)}
                ></input>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-md-5 col-form-label col-form-label-sm">
                Language
              </label>
              <div className="col-md-7">
                <Form.Select size="sm"                   value={language}
                  onChange={(e) => updateValue(e, `language`, setLanguage)}>
                  <option value="English">English</option>
                  <option value="Filipino">Filipino</option>
                </Form.Select>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3}>
        <SocialSettingsCard></SocialSettingsCard>
      </Col>
    </>
  );
};

export default AccountSettingsTab;
