import { ComponentType, FC } from "react";
import { LoggedInProps } from "../../interface/login.interface";
import { NavbarMenuProps } from "../../interface/navbar.interface";

const NavigationBarMenu =
  <P extends object>(
    LoggedInComponent: ComponentType<LoggedInProps>,
    LoggedOutComponent: ComponentType
  ): FC<P & NavbarMenuProps> =>
    ({ isRegister, isLoggedIn, setIsLoggedIn }) => {
      if (isRegister) {
        return <></>;
      } else if (isLoggedIn) {
        return <LoggedInComponent setIsLoggedIn={setIsLoggedIn} />;
      }
      return <LoggedOutComponent />;
    };

export default NavigationBarMenu;
