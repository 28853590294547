import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Col, Card, Table, Form, Dropdown } from "react-bootstrap";
import { USER_TYPE } from "../../../constants/nav-constants";
import { DEFAULT_VALUES } from "../../../constants/page-two-constants";
import { AdminProfile } from "../../../interface/page-two.interface";
import AdminSettingsService from "../../../services/http/admin-settings.service";
import ApplicantsService from "../../../services/http/applicants.service";
import "./AdminSettingsTab.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NewAdminFormModal from "../../Admins/NewAdminFormModal/NewAdminFormModal";
import moment from "moment";
import { SETTING_INDEX } from "../../../constants/select-option-constants";

const MySwal = withReactContent(Swal);

const AdminSettingsTab = () => {
  const theUserSettings = JSON.parse(
    localStorage.getItem("userSettings") || ``
  );
  const [admins, setAdmins] = useState<any>([]);
  const [searchAdmin, setSearchAdmin] = useState<any>(``);

  const [userRoles, setUserRoles] = useState<any[]>([]);

  const getUserRoles = () => {
    ApplicantsService.getUserRoles().then((data: AxiosResponse) => {
      if (data && data.data) {
        setUserRoles(data.data);
      }
    });
  };
  useEffect(getUserRoles, []);

  useEffect(() => {
    AdminSettingsService.getAdmins({ SearchStr: searchAdmin }).then(
      (data: AxiosResponse) => {
        setAdmins(data.data);
        console.log("data", data.data);
      }
    );
  }, [searchAdmin]);

  const setToggleView = (i: number) => {
    const newAdmin = admins.map((item, index) => {
      if (index === i) {
        item.isView = !item.isView;
      }
      return item;
    });
    setAdmins(newAdmin);
  };

  const editProfile = (applicant) => {
    setCurrentAdminForm(applicant);
    handleOpenShowAdminForm();
  };

  const [showAdminForm, setShowAdminForm] = useState(false);
  const handleCloseShowAdminForm = () => setShowAdminForm(false);
  const handleOpenShowAdminForm = () => setShowAdminForm(true);

  const [currentAdminForm, setCurrentAdminForm] = useState<AdminProfile>(
    DEFAULT_VALUES.ADMIN_PROFILE
  );

  const deleteProfile = (userId: string) => {
    MySwal.fire({
      title: "Confirm!",
      text: `Are you sure you want to delete selected admin?`,
      icon: "question",
      confirmButtonColor: "#D93123",
      confirmButtonText: `Yes, Delete Admin`,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        ApplicantsService.deleteAdmin(userId)
          .then((response: any) => {
            if (response.status === 200) {
              // getAdmins();
              MySwal.fire({
                title: "Success!",
                text: `User successfully deleted.`,
                icon: "success",
                confirmButtonColor: "#F9A000",
              });
            }
          })
          .catch(() => {});
      }
    });
  };

  const resetPassword = (userId: string) => {
    MySwal.fire({
      title: "Confirm!",
      text: `Are you sure you want to reset password of selected admin?`,
      icon: "question",
      confirmButtonColor: "#D93123",
      confirmButtonText: `Yes, Reset Password`,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        ApplicantsService.resetAdminPassword(userId)
          .then((response: any) => {
            if (response.status === 200) {
              MySwal.fire({
                title: "Success!",
                text: `Password reset successfully executed.`,
                icon: "success",
                confirmButtonColor: "#F9A000",
              });
            }
          })
          .catch(() => {});
      }
    });
  };

  const onSubmitCommonField = (res) => {
    handleCloseShowAdminForm();
    MySwal.fire({
      title: "Success!",
      text: `Admin user successfully added. Email invite sent.`,
      icon: "success",
      confirmButtonColor: "#F9A000",
    });
    // getAdmins();
  };

  return (
    <>
      <NewAdminFormModal
        data={currentAdminForm}
        show={showAdminForm}
        userRoles={userRoles}
        onHide={handleCloseShowAdminForm}
        onSubmit={onSubmitCommonField}
      ></NewAdminFormModal>
      <Col md={12}>
        <form className="row g-3 mb-3 justify-content-end">
          <div className="col-auto">
            <button
              type="button"
              onClick={() => editProfile(DEFAULT_VALUES.ADMIN_PROFILE)}
              className="btn text-white btn-sm btn-primary mb-3"
            >
              <i className="bi bi-plus"></i>
              Admin
            </button>
          </div>
          <div className="col-auto">
            <input
              type="text"
              className="form-control-sm form-control"
              placeholder="Search for entries"
              value={searchAdmin}
              onChange={(e) => setSearchAdmin(e.target.value)}
            ></input>
          </div>
          <div className="col-auto">
            <Form.Select size="sm">
              <option>Role</option>
              <option value={"Technical"}>Technical</option>
              <option value={"CRM"}>CRM</option>
            </Form.Select>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn text-danger btn-sm mb-3"
              onClick={() => setSearchAdmin(``)}
            >
              Clear
            </button>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn text-white btn-sm btn-primary mb-3"
            >
              Search
            </button>
          </div>
        </form>
      </Col>
      <Col md={12}>
        {admins.map((item, index) => {
          return (
            <div key={index} className="admin-table">
              <Card
                className={
                  item.isView
                    ? "mb-2 collapse-header cursor-pointer"
                    : "mb-3 collapse-header cursor-pointer"
                }
                onClick={() => setToggleView(index)}
              >
                <Card.Body className="background-white">
                  <h6 className="my-2">
                    <i
                      className={
                        item.isView
                          ? "fa-caret-down fa fa-fw"
                          : "fa-caret-right fa fa-fw"
                      }
                      aria-hidden="true"
                    ></i>{" "}
                    {item.permissionName}
                  </h6>
                </Card.Body>
              </Card>
              {item.isView ? (
                <Card className="mb-4">
                  <Card.Body className="p-0">
                    <Table striped hover size="sm" className="m-0">
                      <thead>
                        <tr>
                          <th> Name</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Date Created</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.data.length ? (
                          item.data.map((user, index) => {
                            return (
                              <tr key={index}>
                                <td>{user.fullName}</td>
                                <td>{user.email}</td>
                                <td>{user.permission}</td>
                                <td>
                                  {moment(user?.dateCreated).format(
                                    theUserSettings[SETTING_INDEX.DATE_FORMAT]
                                      .settingValue
                                  )}
                                </td>
                                <td className="text-end">
                                  {user.role !== USER_TYPE.SUPER_ADMIN ? (
                                    <Dropdown dropdown-append-to-body="true">
                                      <Dropdown.Toggle
                                        className="text-primary borderless"
                                        size="sm"
                                        variant="outline-light"
                                        id="dropdown-basic"
                                      >
                                        <i
                                          className="fa fa-ellipsis-h"
                                          aria-hidden="true"
                                        ></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => editProfile(user)}
                                        >
                                          Edit Profile
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => deleteProfile(user.id)}
                                        >
                                          Delete Profile
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => resetPassword(user.id)}
                                        >
                                          Reset Password
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ``
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={5}>Empty.</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              ) : null}
            </div>
          );
        })}
      </Col>
    </>
  );
};

export default AdminSettingsTab;
