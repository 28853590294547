import { Button, Card, Col, Container, Row, Form } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "./OpportunitiesNew.scss";
import OpportunitiesService from "../../services/http/opportunities.service";

import { Fragment, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Redirect, useHistory, useParams } from "react-router-dom";
import RelatedHiringManagers from "../../components/shared/RelatedHiringManagers/RelatedHiringManagers";
import RelatedCompanies from "../../components/shared/RelatedCompanies/RelatedCompanies";
import RelatedCandidates from "../../components/shared/RelatedCandidates/RelatedCandidates";
import SuccessToast from "../../components/common/SuccessToast/SuccessToast";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import LookupsService from "../../services/lookups-service";
import DatePicker from "react-datepicker";
import { USER_TYPE } from "../../constants/nav-constants";

const OpportunitiesNew = () => {
  const history = useHistory();
  const [closingDate, setClosingDate] = useState(new Date());
  const [dateRequested, setDateRequested] = useState(new Date());

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(``);
  const toggleShowToast = (value: boolean = false, message: string = ``) => {
    if (value) {
      setToastMessage(message);
    }
    setShowToast(value);
  };

  const params: any = useParams();
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isClose, setIsClose] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [theData, setTheData] = useState<any>({});
  const [priorities, setPriorities] = useState<any>([]);
  const [requirements, setRequirements] = useState(``);
  const [priorityId, setPriorityId] = useState(``);
  const [salary, setSalary] = useState(``);
  const [remarks, setRemarks] = useState(``);
  const [documentLink, setDocumentLink] = useState(``);
  const [timeZone, setTimeZone] = useState(``);
  const [requiredSkills, setRequiredSkills] = useState(``);
  const [relatedCompanies, setRelatedCompanies] = useState<any>([]);
  const [relatedCandidates, setRelatedCandidates] = useState<any>([]);
  const [relatedHiringManagers, setRelatedHiringManagers] = useState<any>([]);

  const resetButton = () => {
    setIsSaveLoading(false);
  };

  const updateDetails = (
    stringValue: string = ``,
    type: string = ``,
    newPayload: any = null
  ) => {
    const theId = parseInt(params?.id, 10);
    const newPrio =
      type === `prio` ? parseInt(stringValue, 10) : parseInt(priorityId, 10);
    const payload = newPayload || {
      ...theData,
      id: theId || 0,
      requirements,
      priorityId: newPrio || 0,
      salary,
      remarks,
      timeZone,
      requiredSkills,
      documentLink,
      closingDate: isClose ? closingDate : null,
      relatedCompanies,
      relatedCandidates,
      relatedHiringManagers,
    };

    if (!stringValue || JSON.stringify(theData) === JSON.stringify(payload)) {
      return;
    }

    setTheData(payload);
    setIsSaveLoading(true);

    if (theId) {
      OpportunitiesService.updateOpportunity(payload).then(
        ({ data }: AxiosResponse) => {
          showSuccessToast();
          setIsSaveLoading(false);
        },
        resetButton
      );
    } else {
      OpportunitiesService.createOpportunity(payload).then(
        ({ data }: AxiosResponse) => {
          showSuccessToast();
          setIsSaveLoading(false);
          setTimeout(() => {
            history.push(`/opportunity/${data?.id}`);
          }, 0);
        },
        resetButton
      );
    }
  };

  useEffect(() => {
    const getData = () => {
      OpportunitiesService.getOpportunity(params?.id).then(
        ({ data }: AxiosResponse) => {
          if (data) {
            setTheData(data);
            setRequirements(data?.requirements);
            setPriorityId(data?.priorityId);
            if (data?.closingDate) setClosingDate(new Date(data?.closingDate));
            if (data?.dateCreated)
              setDateRequested(new Date(data?.dateCreated));
            setIsClose(!!data?.closingDate);
            setDocumentLink(data?.documentLink);
            setSalary(data?.salary);
            setRemarks(data?.remarks || '');
            setTimeZone(data?.timeZone);
            setRequiredSkills(data?.requiredSkills);
            setRelatedHiringManagers(data?.relatedHiringManagers);
            setRelatedCandidates(data?.relatedCandidates);
            setRelatedCompanies(data?.relatedCompanies);
            const skills = data?.skills
              ? data?.skills.map((item) => {
                  item.name = item.skillName;
                  return item;
                })
              : [];
            setSelectedStack(skills);
          }
        }
      );
    };

    const getPriorities = () => {
      OpportunitiesService.getOpportunityPriorities().then(
        ({ data }: AxiosResponse) => {
          if (data) {
            setPriorities(data);
          }
        }
      );
    };
    if (parseInt(params?.id, 10)) {
      getData();
    } else {
      const query = new URLSearchParams(window.location.search);
      const newRequirement = query.get("requirements") || ``;
      setRequirements(newRequirement);
    }
    getPriorities();
  }, [params, setRequirements]);
  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  const handleSelectChange = (event) => {
    setPriorityId(event.target.value);
    updateDetails(event.target.value, `prio`);
  };

  const showSuccessToast = () => {
    toggleShowToast(true, "Opportunity successfully saved.");
  };

  // TYPEAHEAD
  const [isLoading, setIsLoading] = useState(false);
  const [stackList, setStackList] = useState<any | null>([]);
  const [selectedStack, setSelectedStack] = useState<any | null>([]);

  const handleSearch = (query: string) => {
    setIsLoading(true);

    LookupsService.getSkillsList({ SearchStr: query }).then(
      ({ data }: any = []) => {
        setIsLoading(false);
        setStackList(data);
      }
    );
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const addNewStack = (newStack: any = []) => {
    const theId = parseInt(params?.id, 10);

    if (!theId) return;
    if (selectedStack.length - newStack.length === 1) {
      setSelectedStack(newStack);

      const deletedSkill = selectedStack.filter(
        (i) => !newStack.some((item) => item.id === i.id)
      );
      if (deletedSkill.length) deleteSkill(deletedSkill[0].id);
      return;
    }

    if (newStack.length && newStack[newStack.length - 1].name) {
      const newStackName = newStack.length
        ? newStack[newStack.length - 1].name
        : ``;

      const trimStack = newStackName.trim() || ``;
      if (newStack) {
        const isAlreadySelected = selectedStack.filter(
          (item) => item.name === trimStack || item === trimStack
        );

        if (!isAlreadySelected.length) {
          const newList = [...selectedStack, trimStack];
          setSelectedStack(newList);
          saveSkill({ skillName: trimStack.name || trimStack, id: null }); //  skillName: trimStack.name, id: 0,
        }
      }
    } else {
      setSelectedStack(newStack);
      saveSkill({ skillName: newStack[0].name || newStack[0], id: null }); //  skillName: trimStack.name, id: 0,
    }
  };

  const saveSkill = (skill: any) => {
    const theId = parseInt(params?.id, 10);
    OpportunitiesService.createSkill(theId, skill).then(
      ({ data }: AxiosResponse) => {
        showSuccessToast();
      }
    );
  };

  const deleteSkill = (skillId: number) => {
    const theId = parseInt(params?.id, 10);
    OpportunitiesService.deleteSkill(theId, skillId).then(
      ({ data }: AxiosResponse) => {
        showSuccessToast();
      }
    );
  };

  const closeOpportunity = (e) => {
    setIsClose(e.target.checked);
    updateDetails(`${e.target.checked}`, ``, {
      ...theData,
      closingDate: e.target.checked ? closingDate : null,
    });
  };

  const updateClosingDate = (date) => {
    setClosingDate(date);
    updateDetails(`save`, ``, { ...theData, closingDate: date });
  };

  return (
    <>
      {theUser.role === USER_TYPE.APPLICANT ? (
        <Redirect to={`/profile`} />
      ) : (
        ``
      )}

      <SuccessToast
        showToast={showToast}
        toggleShowToast={toggleShowToast}
        toastMessage={toastMessage}
      ></SuccessToast>
      <LazyLoad>
        <Container className="main-container">
          <Row className="mt-5 pt-4 mx-0 px-0">
            <Col sm={3} className="align-self-center">
              {parseInt(params.id, 10) === 0 ? (
                <h5 className="my-1">Add Opportunity</h5>
              ) : (
                <Button
                  className="text-decoration-none text-primary"
                  variant="link"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-angle-left fa-fw" aria-hidden="true"></i>
                  Back to opportunities list
                </Button>
              )}
            </Col>

            {isSaveLoading ? (
              <Col sm={9}>
                <span className="float-end py-1">
                  <i
                    className="fa fa-fw fa-spinner fa-spin"
                    aria-hidden="true"
                  ></i>
                  Saving Changes...
                </span>
              </Col>
            ) : (
              ``
            )}
          </Row>

          <Row>
            <Col>
              <Card className="main-card px-lg-5 mb-4">
                <Card.Body className="px-md-5 py-md-5">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">
                          Requirement
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Requirement"
                          value={requirements}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => updateDetails(e.target.value)}
                          onChange={(e) => {
                            setRequirements(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">
                          Priority
                        </Form.Label>
                        <Form.Select
                          value={priorityId}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onChange={handleSelectChange}
                        >
                          {priorities.map((option, index) => {
                            return (
                              <option key={index} value={option.id}>
                                {option.priorityName}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">Job Description</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Job Description"
                          value={documentLink}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => updateDetails(e.target.value)}
                          onChange={(e) => {
                            setDocumentLink(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">Salary</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Salary"
                          value={salary}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => updateDetails(e.target.value)}
                          onChange={(e) => {
                            setSalary(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">Remarks</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Remarks"
                          value={remarks}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => updateDetails(e.target.value)}
                          onChange={(e) => {
                            setRemarks(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label className="text-strong">
                          Timezone
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Timezone"
                          value={timeZone}
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          onBlur={(e) => updateDetails(e.target.value)}
                          onChange={(e) => {
                            setTimeZone(e.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="mt-3">
                        <Form.Label className="text-strong">
                          Required Skills
                        </Form.Label>

                        <AsyncTypeahead
                          filterBy={filterBy}
                          id="async-example"
                          isLoading={isLoading}
                          labelKey="name"
                          multiple
                          allowNew
                          selected={selectedStack}
                          minLength={2}
                          onSearch={handleSearch}
                          onChange={(selected: any) => {
                            addNewStack(selected);
                          }}
                          options={stackList}
                          delay={500}
                          useCache={false}
                          newSelectionPrefix="Add custom skill: "
                          placeholder="Eg. Laravel, VueJS, HTML, CSS..."
                          renderMenuItemChildren={(option: any, props) => (
                            <Fragment>
                              <span key={option.name}>{option.name}</span>
                            </Fragment>
                          )}
                        />
                      </Form.Group>
                      <Form.Group className="mt-3">
                        <Form.Label className="text-strong">
                          Close Opportunity
                        </Form.Label>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Close"
                          disabled={theUser.role === USER_TYPE.TECHNICAL}
                          checked={isClose}
                          onChange={(e) => closeOpportunity(e)}
                        />

                        {isClose ? (
                          <div className="row">
                            <DatePicker
                              className="form-control"
                              selected={closingDate}
                              minDate={dateRequested}
                              onChange={(date: Date) => updateClosingDate(date)}
                            />
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={3} className="offset-lg-3">
                      <Form.Group className="mt-3 mb-5">
                        <RelatedCompanies
                          relatedCompanies={relatedCompanies}
                          setRelatedCompanies={setRelatedCompanies}
                          id={params?.id}
                          type={`opportunity`}
                          showSuccessToast={showSuccessToast}
                        ></RelatedCompanies>
                      </Form.Group>
                      <Form.Group className="mt-3 mb-5">
                        <RelatedHiringManagers
                          updateUserDetails={``}
                          relatedHiringManagers={relatedHiringManagers}
                          setRelatedHiringManagers={setRelatedHiringManagers}
                          id={params?.id}
                          type={`opportunity`}
                          showSuccessToast={showSuccessToast}
                        ></RelatedHiringManagers>
                      </Form.Group>
                      <Form.Group className="mt-3 mb-5">
                        <RelatedCandidates
                          relatedCandidates={relatedCandidates}
                          setRelatedCandidates={setRelatedCandidates}
                          id={params?.id}
                          type={`opportunity`}
                          showSuccessToast={showSuccessToast}
                        ></RelatedCandidates>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </LazyLoad>
    </>
  );
};

export default OpportunitiesNew;
