import { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  Form,
  Table,
  Dropdown,
} from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "./Admins.scss";
import { Redirect, useHistory } from "react-router-dom";
import ApplicantsService from "../../services/http/applicants.service";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  DEFAULT_PAGE_ROWS,
  PAGE_ROWS,
} from "../../constants/resume-forms-constants";
import NewAdminFormModal from "./NewAdminFormModal/NewAdminFormModal";
import { AdminProfile } from "../../interface/page-two.interface";
import { DEFAULT_VALUES } from "../../constants/page-two-constants";
import { USER_TYPE } from "../../constants/nav-constants";

const MySwal = withReactContent(Swal);

const Admins = () => {
  const history = useHistory();

  const [applicants, setApplicants] = useState<any[]>([]);
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_ROWS);
  const [tableMeta, setTableMeta] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const searchApplicants = (e) => {
    e.preventDefault();
    getAdmins({ SearchStr: searchText, PageSize: rowsPerPage });
  };

  const handleSelectChange = (event) => {
    setRowsPerPage(event.target.value);
    getAdmins({ SearchStr: searchText, PageSize: rowsPerPage });
  };

  const previousPage = () => {
    const currentPage = Math.ceil(tableMeta?.lastEntry / rowsPerPage);
    getAdmins({
      SearchStr: searchText,
      PageSize: rowsPerPage,
      PageNumber: currentPage - 1,
    });
  };

  const nextPage = () => {
    const currentPage = Math.ceil(tableMeta?.lastEntry / rowsPerPage);
    getAdmins({
      SearchStr: searchText,
      PageSize: rowsPerPage,
      PageNumber: currentPage + 1,
    });
  };

  const getAdmins = (params: any = { PageSize: DEFAULT_PAGE_ROWS }) => {
    setIsLoading(true);
    ApplicantsService.getAdminsDetails(params).then((data: AxiosResponse) => {
      setIsLoading(false);
      if (data && data.data && data.data.data) {
        setTableMeta(data.data.meta);
        setApplicants(data.data.data);
      }
    });
  };

  const getUserRoles = () => {
    ApplicantsService.getUserRoles().then((data: AxiosResponse) => {
      if (data && data.data) {
        setUserRoles(data.data);
      }
    });
  };

  const deleteProfile = (userId: string) => {
    MySwal.fire({
      title: "Confirm!",
      text: `Are you sure you want to delete selected admin?`,
      icon: "question",
      confirmButtonColor: "#D93123",
      confirmButtonText: `Yes, Delete Admin`,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        ApplicantsService.deleteAdmin(userId)
          .then((response: any) => {
            if (response.status === 200) {
              getAdmins();
              MySwal.fire({
                title: "Success!",
                text: `User successfully deleted.`,
                icon: "success",
                confirmButtonColor: "#F9A000",
              });
            }
          })
          .catch(() => {});
      }
    });
  };

  const resetPassword = (userId: string) => {
    MySwal.fire({
      title: "Confirm!",
      text: `Are you sure you want to reset password of selected admin?`,
      icon: "question",
      confirmButtonColor: "#D93123",
      confirmButtonText: `Yes, Reset Password`,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        ApplicantsService.resetAdminPassword(userId)
          .then((response: any) => {
            if (response.status === 200) {
              MySwal.fire({
                title: "Success!",
                text: `Password reset successfully executed.`,
                icon: "success",
                confirmButtonColor: "#F9A000",
              });
            }
          })
          .catch(() => {});
      }
    });
  };

  const editProfile = (applicant) => {
    setCurrentAdminForm(applicant);
    handleOpenShowAdminForm();
  };

  useEffect(getAdmins, []);
  useEffect(getUserRoles, []);
  useEffect(() => {}, []);

  const [showAdminForm, setShowAdminForm] = useState(false);
  const handleCloseShowAdminForm = () => setShowAdminForm(false);
  const handleOpenShowAdminForm = () => setShowAdminForm(true);

  const [currentAdminForm, setCurrentAdminForm] = useState<AdminProfile>(
    DEFAULT_VALUES.ADMIN_PROFILE
  );
  const onSubmitCommonField = (res) => {
    handleCloseShowAdminForm();
    MySwal.fire({
      title: "Success!",
      text: `Admin user successfully added. Email invite sent.`,
      icon: "success",
      confirmButtonColor: "#F9A000",
    });
    getAdmins();
  };

  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  return (
    <>
      {theUser.role.toLowerCase() !== `super-admin` ? (
        <Redirect to={`/profile`} />
      ) : (
        ``
      )}
      <NewAdminFormModal
        data={currentAdminForm}
        show={showAdminForm}
        userRoles={userRoles}
        onHide={handleCloseShowAdminForm}
        onSubmit={onSubmitCommonField}
      ></NewAdminFormModal>
      <LazyLoad>
        <Container className="main-container" fluid>
          <Row>
            <Col>
              <div className="header-container d-lg-flex">
                <div className="align-self-end">
                  <h4>
                    Manage Admins
                    <Button
                      className="mx-2 my-1"
                      variant="outline-primary"
                      onClick={() => history.push(`/candidates`)}
                    >
                      Manage Applicants
                    </Button>
                    <Button
                      className="mx-2 my-1"
                      onClick={() => editProfile(DEFAULT_VALUES.ADMIN_PROFILE)}
                      variant="outline-primary"
                    >
                      <i
                        className="fa fa-user-plus fa-fw"
                        aria-hidden="true"
                      ></i>{" "}
                      Add Admin
                    </Button>
                  </h4>
                </div>
                <div className="flex-fill">
                  <Form onSubmit={searchApplicants}>
                    <div className="d-sm-flex justify-content-end">
                      <div className="px-1">
                        <Form.Group
                          className="my-1"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            value={searchText}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                            }}
                            placeholder="Search admin here..."
                          />
                        </Form.Group>
                      </div>
                      <div className="px-1 align-self-end">
                        <Button
                          className="my-1"
                          variant="outline-secondary"
                          disabled={isLoading}
                          type="submit"
                        >
                          Search Admins
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="main-card">
                <Card.Body className="p-0">
                  <Table responsive hover className="mb-5">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Date Created</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!applicants.length ? (
                        <tr>
                          <td
                            colSpan={5}
                            className="text-center text-muted py-5"
                          >
                            {isLoading ? "Loading..." : "No admin found."}
                          </td>
                        </tr>
                      ) : null}
                      {applicants.map((applicant) => {
                        return (
                          <tr key={applicant.id}>
                            <td className="cursor-pointer">
                              {applicant.firstName} {applicant.lastName}
                            </td>
                            <td className="cursor-pointer">
                              {applicant.email}
                            </td>
                            <td className="cursor-pointer">{applicant.role}</td>
                            <td className="cursor-pointer">
                              {applicant.dateCreated.substr(0, 10)}
                            </td>
                            <td className="text-end">
                              {applicant.role !== USER_TYPE.SUPER_ADMIN ? (
                                <Dropdown dropdown-append-to-body="true">
                                  <Dropdown.Toggle
                                    className="text-primary borderless"
                                    size="sm"
                                    variant="outline-light"
                                    id="dropdown-basic"
                                  >
                                    <i
                                      className="fa fa-ellipsis-h"
                                      aria-hidden="true"
                                    ></i>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => editProfile(applicant)}
                                    >
                                      Edit Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        deleteProfile(applicant.id)
                                      }
                                    >
                                      Delete Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        resetPassword(applicant.id)
                                      }
                                    >
                                      Reset Password
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                ``
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <div className="p-4 text-muted">
                    <div className="d-md-flex justify-content-end">
                      <div className="px-2 align-self-center">
                        {isLoading ? (
                          <i
                            className="fa fa-spinner fa-spin text-muted mx-2"
                            aria-hidden="true"
                          ></i>
                        ) : null}
                        Rows Per Page
                      </div>

                      <div className="px-2 align-self-center">
                        <Form.Select
                          className="borderless"
                          size="sm"
                          value={rowsPerPage}
                          onChange={handleSelectChange}
                        >
                          {PAGE_ROWS.map((option, index) => {
                            return (
                              <option key={index} value={option.value}>
                                {option.value}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>

                      <div className="px-2 align-self-center">
                        {tableMeta?.firstEntry}-{tableMeta?.lastEntry} of{" "}
                        {tableMeta?.totalEntries}
                        <Button
                          size="sm"
                          className="mx-1 borderless"
                          variant="outline-primary"
                          disabled={
                            tableMeta?.totalPages === 1 ||
                            tableMeta?.firstEntry === 1 ||
                            isLoading
                          }
                          onClick={previousPage}
                        >
                          <i
                            className="fa fa-chevron-left"
                            aria-hidden="true"
                          ></i>
                        </Button>
                        <Button
                          size="sm"
                          className="mx-1 borderless"
                          variant="outline-primary"
                          disabled={
                            tableMeta?.totalPages === 1 ||
                            tableMeta?.lastEntry === tableMeta?.totalEntries ||
                            isLoading
                          }
                          onClick={nextPage}
                        >
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      </div>
                      <div className="align-self-center"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </LazyLoad>
    </>
  );
};

export default Admins;
