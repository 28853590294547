import "./CreateAccountForm.scss";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { RegistrationProps } from "../../../interface/account-form.interface";
import { UserRoles } from "../../../types/roles.types";
import { useEffect, useState } from "react";
import { Roles } from "../../../interface/roles.interface";
import { SetUserRoles } from "../../../types/roles.types";
import RegistrationService from "../../../services/http/registration.service";
import { NORMALIZED_NAMES } from "../../../constants/register-constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import * as EmailValidator from "email-validator";
import { FormObject } from "../../../interface/hook-form.interface";
import { AxiosResponse } from "axios";
import { triggerFunc } from "../../../services/form-hook-service";
import { useHistory } from "react-router-dom";
import { Loading } from "../../../types/general.types";
import ReCAPTCHA from "react-google-recaptcha";

// const grecaptcha = grecaptcha;

const MySwal = withReactContent(Swal);

const submitRegistration = ({
  data,
  userRoles,
  setValue,
  trigger,
  history,
  setIsLoading,
  captchaResponse,
}: RegistrationProps): void => {
  if (data) {
    const applicationRoleDetails = userRoles.filter(
      (data: Roles) => data.normalizedName === NORMALIZED_NAMES.APPLICANT
    );
    const params: FormObject = {
      ...data,
      roleId:
        applicationRoleDetails && applicationRoleDetails.length
          ? applicationRoleDetails[0].id
          : "",
      captchaResponse,
    };
    setIsLoading(true);
    RegistrationService.createUser(params).then(
      (data: AxiosResponse) => {
        if (data && data.data) {
          MySwal.fire({
            title: "Success!",
            text: "Registration successful",
            icon: "success",
            confirmButtonColor: "#F9A000",
          }).then(() => {
            setIsLoading(false);
            setValue("email", null);
            setValue("password", null);
            triggerFunc(trigger);
            history.push("/verification");
          });
        }
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
        triggerFunc(trigger);
      }
    );
  }
};

const getUserRoles = (setUserRoles: SetUserRoles): void => {
  RegistrationService.getRoles().then((data: AxiosResponse) => {
    if (data && data.data) {
      if (data.data.length) {
        setUserRoles(data.data);
      }
    }
  });
};

const CreateAccountForm = () => {
  const history = useHistory();
  const defaultUserRoles: Roles[] = [];
  const [userRoles, setUserRoles]: UserRoles =
    useState<Roles[]>(defaultUserRoles);
  const [isLoading, setIsLoading]: Loading = useState<boolean>(false);
  const { register, handleSubmit, formState, setValue, trigger } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const isFormReady = formState.isValid && !isLoading ? "" : "is-disabled";
  useEffect(() => {
    getUserRoles(setUserRoles);
    return () => {
      setUserRoles([]);
    };
  }, []);

  const [captchaToken, setCaptchaToken] = useState(``);
  const onChangeCaptcha = (value) => {
    setCaptchaToken(value);
  };

  return (
    <div className="create-account-form">
      <h5 className="form-title">Create your account to continue</h5>
      <form
        onSubmit={handleSubmit((data) =>
          submitRegistration({
            data,
            userRoles,
            setValue,
            trigger,
            history,
            setIsLoading,
            captchaResponse: captchaToken,
          })
        )}
      >
        <div className="form-wrapper">
          <Form.Group controlId="email" className="text-box">
            <p className="input-title">Email</p>
            <Form.Control
              autoComplete="off"
              type="text"
              className="is-valid"
              {...register("email", {
                required: true,
                validate: (value) => EmailValidator.validate(value),
              })}
            />
          </Form.Group>
        </div>
        <div className="form-wrapper">
          <Form.Group controlId="password" className="text-box">
            <p className="input-title">Password</p>
            <Form.Control
              autoComplete="off"
              type="password"
              className="is-valid"
              {...register("password", {
                required: true,
              })}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <ReCAPTCHA
              sitekey="6LdATiIeAAAAAGL4xbkzT4qpJ4-Z6-U8qAYmahfv"
              onChange={onChangeCaptcha}
            />
            {/* <div className="g-recaptcha" data-sitekey="6LdATiIeAAAAAGL4xbkzT4qpJ4-Z6-U8qAYmahfv"></div> */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Keep me signed in on this device."
            />
          </Form.Group>
        </div>
        <div className="buttons-container">
          <Button
            type="submit"
            disabled={!captchaToken}
            className={`submit-button ${isFormReady}`}
          >
            Register
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateAccountForm;
