import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { Col, Card } from "react-bootstrap";
import AdminSettingsService from "../../../services/http/admin-settings.service";
import "./TagsSettingsTab.scss";
import TagsSettingsSearch from "./TagsSettingsSearch/TagsSettingsSearch";
import { confirmDelete } from "../../../services/confirm-delete.service";
import SuccessToast from "../../../components/common/SuccessToast/SuccessToast";
import TagsSettingsActions from "./TagsSettingsActions/TagsSettingsActions";

const DEFUALT_COLOR = `#F9A000`;
const TagsSettingsTab = () => {
  const [skills, setSkills] = useState<any>([]);
  const [skillsCategories, setSkillsCategories] = useState<any>([]);
  const [searchText, setSearchText] = useState(``);
  const [currentTag, setCurrentTag] = useState<any>({});
  const [tagName, setTagName] = useState(``);
  const [tagColor, setTagColor] = useState(DEFUALT_COLOR);
  const [category, setCategory] = useState<any>(1);

  const inputTagNameRef = useRef<any>(null);
  useEffect(() => {
    AdminSettingsService.getSkillsCategory().then((data: AxiosResponse) => {
      setSkillsCategories(data.data);
      console.log("data", data.data);
    });
  }, []);
  useEffect(() => {
    const mapSkills = (data: any = []) => {
      let allSkills = data.reduce((previousValue, currentValue) => {
        return [...previousValue, ...currentValue.skills];
      }, []);

      return [
        {
          id: 0,
          typeName: "All",
          skills: allSkills,
        },
        ...data,
      ];
    };

    const delayDebounceFn = setTimeout(() => {
      AdminSettingsService.getSkills(searchText).then((data: AxiosResponse) => {
        setSkills(mapSkills(data.data));
        console.log("data", data.data);
      });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const setToggleView = (i: number) => {
    const newAdmin = skills.map((item, index) => {
      if (index === i) {
        item.isView = !item.isView;
      }
      return item;
    });
    setSkills(newAdmin);
  };

  const handleSelectColorChange = (e) => {
    setTagColor(e.target.value);
  };
  const handleSelectCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const addTag = (e) => {
    e.preventDefault();
    const param = {
      SkillTypeId: parseInt(category, 10),
      name: tagName,
      tagColor,
    };

    let theAsync;
    if (currentTag.id) {
      theAsync = AdminSettingsService.updateSkill(currentTag.id, param);
    } else {
      theAsync = AdminSettingsService.addSkill(param);
    }
    theAsync.then((data: AxiosResponse) => {
      console.log("data", data);

      if (!currentTag.id) {
        const newSkills = skills.map((item, index) => {
          if (item.id === param.SkillTypeId || index === 0) {
            item.skills = [...item.skills, data.data];
          }
          return item;
        });
        setSkills(newSkills);
        toggleShowToast(true, "Skill successfully added.");

      } else {
        const newSkills = skills.map((item, index) => {
          if (item.id === param.SkillTypeId || index === 0) {
            item.skills = item.skills.map((skill) => {
              if (skill.id === currentTag.id) {
                skill = data.data;
              }
              return skill;
            });
          }
          return item;
        });
        setSkills(newSkills);
        toggleShowToast(true, "Skill successfully updated.");

      }
      setTagName(``);
      setCurrentTag({});
    });
  };

  const editTag = (item, categoryId, type) => {
    setTagName(item.name);
    setTagColor(item.tagColor || DEFUALT_COLOR);
    setCategory(categoryId);
    setCurrentTag(item);

    console.log("inputTagNameRef :>> ", inputTagNameRef);
    setTimeout(() => {
      inputTagNameRef.current.classList.remove(`form-row`);
    }, 3000);
    inputTagNameRef.current.classList.add(`form-row`);
    window.scrollTo(0, 0);
  };

  const showSuccessToast = () => {
    toggleShowToast(true, "Skill successfully deleted.");
  };

  const deleteNow = (params: any = {}, categoryId) => {
    AdminSettingsService.deleteSkill(params.id).then(() => {
      const newSkills = skills.map((category, index) => {
        if (category.id === categoryId || index === 0) {
          category.skills = category.skills.filter((skill) => {
            return skill.id !== params.id;
          })
        }
        return category;
      });
      setSkills(newSkills);
      showSuccessToast();
    });

  }
  const removeTag = (params: any = {}, categoryId) => {
    confirmDelete(`${params.name} tag`).then((res) => {
      if (res.isConfirmed) {
        deleteNow(params, categoryId);
      }
    });
  };

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(``);
  const toggleShowToast = (value: boolean = false, message: string = ``) => {
    if (value) {
      setToastMessage(message);
    }
    setShowToast(value);
  };

  return (
    <>
      <SuccessToast
        showToast={showToast}
        toggleShowToast={toggleShowToast}
        toastMessage={toastMessage}
      ></SuccessToast>
      <TagsSettingsSearch
        addTag={addTag}
        tagName={tagName}
        setTagName={setTagName}
        tagColor={tagColor}
        handleSelectColorChange={handleSelectColorChange}
        skillsCategories={skillsCategories}
        setSearchText={setSearchText}
        category={category}
        handleSelectCategoryChange={handleSelectCategoryChange}
        searchText={searchText}
        inputTagNameRef={inputTagNameRef}
        currentTag={currentTag}
      ></TagsSettingsSearch>

      <Col md={12}>
        {skills.map((category, index) => {
          return (
            <div key={index} className="admin-table">
              <Card
                className={
                  category.isView
                    ? "mb-2 collapse-header cursor-pointer"
                    : "mb-3 collapse-header cursor-pointer"
                }
                onClick={() => setToggleView(index)}
              >
                <Card.Body className="background-white">
                  <h6 className="my-2">
                    <i
                      className={
                        category.isView
                          ? "fa-caret-down fa fa-fw"
                          : "fa-caret-right fa fa-fw"
                      }
                      aria-hidden="true"
                    ></i>{" "}
                    {category.typeName}
                  </h6>
                </Card.Body>
              </Card>
              {category.isView ? (
                <Card className="mb-4">
                  <Card.Body className="tags-card">
                  <TagsSettingsActions category={category} editTag={editTag} removeTag={removeTag}></TagsSettingsActions>

                  </Card.Body>
                </Card>
              ) : null}
            </div>
          );
        })}
      </Col>
    </>
  );
};

export default TagsSettingsTab;
