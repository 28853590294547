import "./LoggedOutMenu.scss";
import { Nav } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";

const LoggedOutMenu = () => {
  const history = useHistory();
  const loginRoute = () => {
    history.push("/login");
  };
  return (
    <>
      <Nav.Link
        onClick={loginRoute}
      >
        Login
      </Nav.Link>
    </>
  );
};

export default withRouter(LoggedOutMenu);
