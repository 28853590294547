import { Button, Row, Col } from "react-bootstrap";
import { DEFAULT_VALUES } from "../../../constants/page-two-constants";
import HoverActions from "../HoverActions/HoverActions";

import "./KeyAchievementSection.scss";

interface HoverActionsProps {
  userResumeDetails: any;
  handleDeleteField: any;
  handleShowKeyAchievementModal: any;
}
const KeyAchievementSection = ({
  userResumeDetails,
  handleDeleteField,
  handleShowKeyAchievementModal,
}: HoverActionsProps) => {
  return (
    <>
                <Row className="top-46 experience-summary">
                  <Col className="px-0">
                    <h5 className="section-title">Key Achievements</h5>
                    {userResumeDetails?.keyAchievements.length ? (
                      userResumeDetails?.keyAchievements.map(
                        (details: any, index: number) => (
                          <div
                            className="group-detail-hover-holder"
                            key={index}
                          >
                            <p className="details-description mt-0">
                              {details.summary}
                            </p>

                            <div className="detail-actions">
                              <HoverActions
                                dataList={userResumeDetails.keyAchievements}
                                fieldType={"keyAchievements"}
                                onDelete={handleDeleteField}
                                onEdit={handleShowKeyAchievementModal}
                                data={details}
                                onAdd={handleShowKeyAchievementModal}
                                defaultValue={
                                  DEFAULT_VALUES.EXPERIENCE_SUMMRARY
                                }
                              ></HoverActions>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="d-grid gap-2">
                        <Button
                          variant="outline-primary"
                          onClick={() =>
                            handleShowKeyAchievementModal(
                              DEFAULT_VALUES.EXPERIENCE_SUMMRARY
                            )
                          }
                          className="text-decoration-none btn-block"
                        >
                          <i
                            className="fa fa-plus fa-fw mx-1"
                            aria-hidden="true"
                          ></i>
                          Add key achievement
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
    </>
  );
};

export default KeyAchievementSection;
