import "./Landing.scss";
import LazyLoad from "react-lazyload";
import CreateAccountForm from "../../components/LandingJoinContent/CreateAccountForm/CreateAccountForm";
import LandingFooter from "../../components/LandingFooter/LandingFooter";
import { Container, Col, Row } from "react-bootstrap";
import JoinDescription from "../../components/LandingJoinContent/JoinDescription/JoinDescription";

const Landing = () => {
  return (
    <>
      <LazyLoad>
        <div className="landing-container">
          <header>
            <h5>
              JOIN OUR <span>CANDIDATES PORTAL</span>
            </h5>
          </header>
          <section>
            <Container>
              <div className="main-content">
                <div className="main-flex-container">
                  <Row>
                    <Col lg="7">
                      <JoinDescription />
                    </Col>
                    <Col lg="5">
                      <CreateAccountForm />
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </section>
          <footer>
            <Container>
              <LandingFooter />
            </Container>
          </footer>
        </div>
      </LazyLoad>
    </>
  );
};

export default Landing;
