import { Card } from "react-bootstrap";
import "./SocialSettingsCard.scss";

const SocialSettingsCard = () => {
  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <h6 className="mb-3">Socials</h6>
          <p>
            <a
              rel="noreferrer"
              className="text-decoration-none text-dark"
              href="https://www.linkedin.com/company/devpartnerssoftwaresolutions"
              target="_blank"
            >
              <i
                className="fa bi bi-linkedin fa-fw text-linkedin"
                aria-hidden="true"
              ></i>{" "}
              LinkedIn{" "}
            </a>
          </p>
          <p>
            <a
              rel="noreferrer"
              className="text-decoration-none text-dark"
              href="https://www.facebook.com/DevPartnersPH/"
              target="_blank"
            >
              <i
                className="fa bi bi-facebook fa-fw text-facebook"
                aria-hidden="true"
              ></i>{" "}
              Facebook
            </a>
          </p>
          <p>
            <a
              rel="noreferrer"
              className="text-decoration-none text-dark"
              href="https://twitter.com/DevPartnersPH"
              target="_blank"
            >
              <i
                className="fa bi bi-twitter fa-fw text-twitter"
                aria-hidden="true"
              ></i>{" "}
              Twitter{" "}
            </a>
          </p>

          <p>
            <a
              rel="noreferrer"
              className="text-decoration-none text-dark"
              href="https://www.youtube.com/channel/UC504fH6kn-vOztZozrnQwvQ"
              target="_blank"
            >
              <i
                className="fa bi bi-youtube fa-fw text-youtube"
                aria-hidden="true"
              ></i>{" "}
              YouTube{" "}
            </a>
          </p>

          <h6 className="mb-3">Support</h6>
          <p>
          <a
              className="text-decoration-none text-dark"
              href="mailto:info@devpartners.co"
            >
            <i className="fa bi bi-envelope fa-fw" aria-hidden="true"></i>{" "}
            info@devpartners.co            </a>

          </p>

          <p>
            <i className="fa bi bi-telephone fa-fw" aria-hidden="true"></i> 1
            (800) 850-2344
          </p>
          <p>
            <i className="fa bi bi-telephone fa-fw" aria-hidden="true"></i> +1
            415 310 1207
          </p>
        </Card.Body>
      </Card>
    </>
  );
};

export default SocialSettingsCard;
