import { AxiosResponse } from "axios";
import { SETTING_INDEX } from "../constants/select-option-constants";
import { LoginUser } from "../interface/login.interface";
import AdminSettingsService from "./http/admin-settings.service";

export const isKeepLoggedIn = (): boolean => {
    const isKeepLoggedInValue = localStorage.getItem("isKeepLoggedIn");
    if (isKeepLoggedInValue) {
        return isKeepLoggedInValue === 'true';
    }
    return false;
};

export const saveLoginDetails = (loginDetails: LoginUser): void => {
    if (loginDetails) {
        const {
            token,
            email,
            fullName,
            keepLoggedIn,
            userId,
            role
        } = loginDetails;

        const userDetails = {
            email,
            fullName,
            keepLoggedIn,
            userId,
            role
        };
        AdminSettingsService.getSettings().then((value: AxiosResponse) => {
            if (value.data[SETTING_INDEX.ENABLE_DARK_MODE].settingValue) {
                document.body.classList.add("dark");
            }
            if (value.data[SETTING_INDEX.DATE_FORMAT].settingValue === `MMM d, yyyy`) {
                value.data[SETTING_INDEX.DATE_FORMAT].settingValue = `MMM D, yyyy`;
            }
            localStorage.setItem('userSettings',JSON.stringify(value.data) );

        })
        localStorage.setItem('jwt-token', token);
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
    }
};