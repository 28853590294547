import { Button, Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./JoinDescription.scss";

const JoinDescription = () => {
  const history = useHistory();

  return (
    <div className="join-description-container">
      <Carousel controls={false} indicators={true}>
        <Carousel.Item>
          <div className="carousel-item-holder">
            <h4 className="join-description-header">
              Do you have amazing friends who might be a good fit to our jobs?
            </h4>
            <h5 className="application-title">
              Great! Refer them to us and earn P20,000 for every successful
              hire.
              <span></span>
            </h5>
            <div className="message-container">
              <p className="application-message">
              Even better, your friends will get a signing bonus of up to P60,000.

              </p>
              <p className="message-highlight my-0 py-0">

                <Button
                  variant="outline-primary"
                  href="https://dev-partners-philippines.breezy.hr/team/portal"
                  target={`_blank`}
                >
                  REFER A FRIEND NOW
                </Button>
              </p>
              <div className="my-4">
                <p className="application-message">
                  <em>

                  Dev Partners Refer a Friend Program{" "}
                  <span
                    className="text-decoration-none cursor-pointer text-primary"
                    onClick={() => history.push(`/refer-a-friend`)}
                  >
                    Terms and Conditions
                  </span>
                  .
                  </em>
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-item-holder">
            <h4 className="join-description-header">
              Interested in joining Dev Partners, but no current offers match
              your profile?
            </h4>
            <h5 className="application-title">
              Send us an application for our future job postings.{" "}
              <a
                className="text-decoration-none"
                href="https://dev-partners-philippines.breezy.hr/team/portal"
                target={`_blank`}
              >
                Join our Candidates Portal Now!
              </a>
            </h5>
            <div className="message-container mt-3">
              <p className="application-message">
                So even if no current position matches your profile, tell us
                about your career path and the ideal position you desire.
              </p>
              <div className="mb-three">
                <p className="message-highlight">
                  An opening might turn up tomorrow!
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default JoinDescription;
