import "./AssessmentDropdown.scss";
import { Form } from "react-bootstrap";
import {
  ASSESSMENT,
  ASSESSMENT_TYPES,
} from "../../../constants/select-option-constants";
import { USER_TYPE } from "../../../constants/nav-constants";

const AssessmentDropdown = ({ title, selectedValue, handleSelectChange }) => {
  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  return (
    <>
      <strong>{title} </strong>{" "}
      {ASSESSMENT_TYPES.SKILL === title ? <em>(Optional)</em> : ``}
      <Form.Select
        disabled={USER_TYPE.CRM === theUser.role}
        value={selectedValue}
        className="input-line"
        onChange={(e) => handleSelectChange(title, e)}
      >
        {ASSESSMENT.map((option, index) => {
          return (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </Form.Select>
    </>
  );
};

export default AssessmentDropdown;
