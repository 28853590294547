import { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import "./EducationFormModal.scss";
import { Education } from "../../../interface/page-two.interface";
import ApplicantsService from "../../../services/http/applicants.service";

interface EducationFormModalProps {
  show: boolean;
  onHide: any;
  onSubmit: any;
  data: Education;
}
const EducationFormModal = ({
  show,
  onHide,
  data,
  onSubmit,
}: EducationFormModalProps) => {
  const [school, setSchool] = useState(``);
  const [course, setCourse] = useState(``);
  const [dateStarted, setDateStarted] = useState<any | null>(null);
  const [dateGraduated, setDateGraduated] = useState<any | null>(null);
  const onSubmitForm = (e) => {
    e.preventDefault();
    const form = {
      id: data.id || ApplicantsService.getRandomDecimal(),
      school,
      course,
      dateGraduated,
      dateStarted,
    };
    onSubmit(form, `education`);
  };

  useEffect(() => {
    const dateStarted = data?.dateStarted?.toString()?.substring(0, 10);
    const dateGraduated = data?.dateGraduated?.toString()?.substring(0, 10);
    setSchool(data.school);
    setCourse(data.course);
    setDateStarted(dateStarted);
    setDateGraduated(dateGraduated);
  }, [data]);

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>
        <Form onSubmit={onSubmitForm}>
          <Modal.Body>
            <h5>{data?.id ? `Update` : `Add`} Education</h5>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>
                School/University <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={school}
                onChange={(e) => {
                  setSchool(e.target.value);
                }}
                placeholder="School/University"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Degree Course Attained <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={course}
                onChange={(e) => {
                  setCourse(e.target.value);
                }}
                placeholder="Degree Course Attained"
              />
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Date Started <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    required
                    value={dateStarted}
                    onChange={(e) => {
                      setDateStarted(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Date Graduated <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    required
                    value={dateGraduated}
                    onChange={(e) => {
                      setDateGraduated(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-primary text-decoration-none"
              onClick={onHide}
            >
              Close
            </Button>
            <Button type="submit" variant="primary" className="text-white">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EducationFormModal;
