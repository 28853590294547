import { AxiosPromise, AxiosResponse } from 'axios';
import http from '../../http-common';
import { FormObject } from '../../interface/hook-form.interface';

const getRoles = (): AxiosPromise<AxiosResponse> => {
    return http.get('/Users/roles');
};

const createUser = (params: FormObject): AxiosPromise<AxiosResponse>  => {
    const userParams = {
        ...params,
        username: params.email,
        firstName: 'initial',
        lastName: 'sample'
    };
    return http.post('/Users', userParams);
};

const RegistrationService = {
    getRoles,
    createUser
};

export default RegistrationService;