import { Card, Col, Container, Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "./Opportunities.scss";
import OpportunitiesService from "../../services/http/opportunities.service";

import {
  DEFAULT_PAGE_ROWS,
  PRIORITY_OPTIONS,
} from "../../constants/resume-forms-constants";
import { useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { Redirect } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import {
  USER_TYPE,
  DEFAULT_GRID_OPTION,
  DEFAULT_COL_DEF,
} from "../../constants/nav-constants";
import OpportunityListRenderer from "../../components/shared/OpportunityListRenderer/OpportunityListRenderer";
import OpportunitySkillsRenderer from "../../components/shared/OpportunitySkillsRenderer/OpportunitySkillsRenderer";
import RequirementsRenderer from "../../components/shared/RequirementsRenderer/RequirementsRenderer";
import PriorityRenderer from "../../components/shared/PriorityRenderer/PriorityRenderer";
import OpportunitiesTableActions from "../../components/shared/OpportunitiesTableActions/OpportunitiesTableActions";
import OpportunitySearch from "../../components/shared/OpportunitySearch/OpportunitySearch";
import moment from "moment";
import { SETTING_INDEX } from "../../constants/select-option-constants";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const Opportunities = () => {
  const theUser = JSON.parse(localStorage.getItem("userDetails") || ``);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedKeyword, setSelectedKeyword] = useState<any>([]);

  const gridOptions = {
    ...DEFAULT_GRID_OPTION,
    rowClassRules: {
      "sick-days-breach": `data.priorityId === 3`,
    },
  };

  const defaultColDef = useMemo(() => {
    return DEFAULT_COL_DEF;
  }, []);

  const [columnDefs] = useState([
    {
      field: "requirements",
      width: 230,
      cellRenderer: RequirementsRenderer,
      // wrapText: true,
      // autoHeight: true,
      cellClass: "requirement-cell",
      cellClassRules: {
        "not-prio-border": (params) => params.data.priorityId === 0,
        "low-prio-border": (params) => params.data.priorityId === 1,
        "medium-prio-border": (params) => params.data.priorityId === 2,
        "high-prio-border": (params) => params.data.priorityId === 3,
      },
    },
    {
      field: "priorityText",
      headerName: `Priority`,
      width: 130,
      cellRenderer: PriorityRenderer,
    },
    { field: "salaryFormatted", headerName: `Salary`, width: 140 },
    { field: "timeZone" },
    {
      field: "skills",
      headerName: `Required Skills`,
      cellRenderer: OpportunitySkillsRenderer,
      // wrapText: true,
      // autoHeight: true,
    },
    {
      field: "relatedCompanies",
      cellRenderer: OpportunityListRenderer,
      width: 190,
    },
    {
      field: "relatedHiringManagers",
      cellRenderer: OpportunityListRenderer,
      width: 190,
    },
    { field: "remarksFormatted", headerName: `Remarks`, width: 140 },
    {
      field: "relatedCandidates",
      cellRenderer: OpportunityListRenderer,
    },
    {
      field: "dateRequestedFormatted",
      headerName: `Date Requested`,
      width: 150,
    },
    { field: "closingDateFormatted", headerName: `Closing Date`, width: 130 },
    { field: "daysActive", width: 130 },
    {
      field: "id",
      headerName: "",
      cellClass: "actions-button-cell",
      resizable: false,
      filter: false,
      width: 50,
      sortable: false,
      cellRenderer: OpportunitiesTableActions,
      cellRendererParams: { theUser },
    },
  ]);

  const [tableData, setTableData] = useState<any[]>([]);
  const [searchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_ROWS);
  const [tableMeta, setTableMeta] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const formatSalary = (salary: string) => {
    if (!salary) return null;

    const minMax = salary.split("-");
    const min =
      minMax[0] && parseInt(minMax[0].replace(/\D/g, ""), 10).toLocaleString();
    const max =
      minMax[1] && parseInt(minMax[1].replace(/\D/g, ""), 10).toLocaleString();
    const maxString = max ? `-${max}` : ``;
    return `₱${min}${maxString}`;
  };

  useEffect(() => {
    const theUserSettings = JSON.parse(
      localStorage.getItem("userSettings") || ``
    );

    const mapTableData = (tableData: any = []) => {
      return tableData.map((item) => {
        item.dateRequestedFormatted =
          item.dateRequested &&
          moment(item.dateRequested).format(
            theUserSettings[SETTING_INDEX.DATE_FORMAT].settingValue
          );
        item.closingDateFormatted =
          item.closingDate &&
          moment(item.closingDate).format(
            theUserSettings[SETTING_INDEX.DATE_FORMAT].settingValue
          );
        item.salaryFormatted = formatSalary(item.salary);
        item.remarksFormatted = item?.remarks || ``;
        item.priorityText = PRIORITY_OPTIONS[item.priorityId].value;
        return item;
      });
    };

    const getApplicants = (params: any = { PageSize: DEFAULT_PAGE_ROWS }) => {
      setIsLoading(true);
      const requestType = selectedKeyword.length
        ? OpportunitiesService.advancedSearchResult({
            ...params,
            FilterByKeyword: selectedKeyword,
          })
        : OpportunitiesService.getOpportunities(params);
      requestType.then((data: AxiosResponse) => {
        setIsLoading(false);
        if (data && data.data && data.data.data) {
          setTableMeta(data.data.meta);
          setTableData(mapTableData(data.data.data));
        }
      });
    };

    getApplicants({
      SearchStr: searchText,
      PageSize: rowsPerPage,
      PageNumber: currentPage,
    });
  }, [currentPage, rowsPerPage, searchText, selectedKeyword]);

  return (
    <>
      {theUser.role === USER_TYPE.APPLICANT ? (
        <Redirect to={`/profile`} />
      ) : null}

      <LazyLoad>
        <Container className="main-container" fluid>
          <Row>
            <Col>
              <OpportunitySearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setSelectedKeyword={setSelectedKeyword}
              ></OpportunitySearch>
              <Card className="main-card main-card-with-search">
                <Card.Body className="p-0">
                  <div
                    className="ag-theme-alpine responsive table-bordered table-sm table-dp-small"
                    style={{ height: "80vh", width: "100%" }}
                  >
                    <AgGridReact
                      rowData={tableData}
                      gridOptions={gridOptions}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                    ></AgGridReact>
                  </div>

                  <Pagination
                    tableMeta={tableMeta}
                    isLoading={isLoading}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setRowsPerPage={setRowsPerPage}
                  ></Pagination>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </LazyLoad>
    </>
  );
};

export default Opportunities;
