import './LandingFooter.scss';


import "./LandingFooter.scss";
import LazyLoad from "react-lazyload";

const LandingFooter = () => {
  return (
    <>
      <LazyLoad>
        <div className="landing-footer-container">
          <p>
            By creating an account, you agree to Dev Partners Candidates Portal Terms
            of Service, Cookie Policy and Privacy Policy. You consent to
            receiving marketing messages from Indeed and may opt out from
            receiving such messages by following the unsubscribe link in our,
            messages, or as detailed in our terms.
          </p>
        </div>
      </LazyLoad>
    </>
  );
};

export default LandingFooter;
