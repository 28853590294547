import "./CandidateOpportunityStatus.scss";
import { Dropdown } from "react-bootstrap";
import ApplicantsService from "../../../services/http/applicants.service";

const CandidateOpportunityStatus = ({
  statuses,
  relatedOpportunities,
  setRelatedOpportunities,
  userId,
  // statusName,
  data,
  // id,
  showSuccessToast,
}) => {

  const saveChanges = (newStatusId: number) => {
    const newValue = {
      ...data,
      statusId: newStatusId,
      statusName: statuses.find((item) => item.id === newStatusId)?.statusName,
    };

    const newOpp = relatedOpportunities.map((item) => {
      if (item.id === data.id) {
        return newValue;
      }
      return item;
    });
    setRelatedOpportunities(newOpp);

    ApplicantsService.updateOpportunityStatus({
      userId,
      opportunityId: data.id,
      payload: { statusId: newStatusId, StatusId: newStatusId },
    }).then(() => {
      if (showSuccessToast) showSuccessToast();
    });
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          className="badge rounded-pill bg-transparent border-none text-success"
        >
          {data.statusName || `Select Status`}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {statuses &&
            statuses.map((item, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  active={item.id === data.statusId}
                  onClick={() => {
                    saveChanges(item.id);
                  }}
                >
                  {item.statusName}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CandidateOpportunityStatus;
