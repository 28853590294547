import { useEffect, useState } from 'react';
import './App.scss';
import NavigationBar from './components/NavigationBar/NavigationBar';
import MainRoute from './routes/Router';
import { LoggedIn } from './types/login.types';
import { IsEditForm } from './types/resume-form.types';

function App() {
  const [isLoggedIn, setIsLoggedIn]: LoggedIn = useState<boolean>(false);
  const [isEdit, setIsEdit]: IsEditForm = useState<boolean>(false);
  const [userDetails, setUserDetails]: IsEditForm = useState<any>();

  useEffect(() => {
    const userDetailsData = localStorage.getItem('userDetails');
    if (userDetailsData) {
      const data = JSON.parse(userDetailsData);
      setUserDetails(data);
      setIsLoggedIn(true);
    }
  }, []);
  return (
    <div className="App">
      <NavigationBar isLoggedIn={isLoggedIn} isRegister={false} setIsLoggedIn={setIsLoggedIn} />
      <MainRoute userDetails={userDetails} setIsLoggedIn={setIsLoggedIn} setIsEdit={setIsEdit} isEdit={isEdit} />
    </div>
  );
}

export default App;
